import React, { useState, useEffect } from 'react';

import ReactDatatable from '@mkikets/react-datatable';

import axios from 'axios';
import "../../_assets/css/file.css"

import { Card, CardBody } from 'reactstrap';
import { TOKEN } from '../../configs/exports';
import { baseURL, formatCurrency } from '../../configs/exports';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import {  useNavigate } from 'react-router';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'


//get agent fuction

const First_Instalment = () => {

    const [instalment, setInstalment] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")
    const [applicsntdetails, setApplicantDetails] = useState(false)
    const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(moment(new Date()))
    const [datevalue, setDateValue] = useState("This Month")

    const [expected_principal, setEPrincipal] = useState(0)
    const [actual_principal, setAPrincipal] = useState(0)
    const [expected_interest, setEInterest] = useState(0)
    const [actual_interest, setAInterest] = useState(0)
    const [expected_installment, setEInstallment] = useState(0)
    const [actual_installment, setAInstallment] = useState(0)

    const [expected_insurance, setEInsurance] = useState(0)
    const [actual_insurance, setAInsurance] = useState(0)

    const [farmerProps, setFarmerProps] = useState([])

    localStorage.setItem("STARTDATE", start)
    localStorage.setItem("ENDDATE", moment(new Date()))

    useEffect(() => {
        setLoad(true)

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': TOKEN
            },
        };
        let url = baseURL + `loan_installment_report?period=1&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;

        // alert(url)
        axios.all([
            axios.get(url, config)
        ]).then(axios.spread((applicantResponse) => {
            let data = [];
            console.log("total loans", url)

            setEPrincipal(formatCurrency(applicantResponse.data.expected_principal))
            setAPrincipal(formatCurrency(applicantResponse.data.paid_principal))
            setEInterest(formatCurrency(applicantResponse.data.expected_interest))
            setAInterest(formatCurrency(applicantResponse.data.paid_interest))
            setEInstallment(formatCurrency(applicantResponse.data.expected_paid_amount))
            setAInstallment(formatCurrency(applicantResponse.data.paid_paid_amount))
            setEInsurance(formatCurrency(applicantResponse.data.expected_insurance))
            setAInsurance(formatCurrency(applicantResponse.data.paid_insurance))

            if (applicantResponse.data.data.length > 0) {
                // setAgent(agentResponse.data.data)
                // alert(  applicantResponse.data.data[0].application_date)
                setDataTotal(applicantResponse.data.total)
                setLoad(false)
                for (let i = 0; i < applicantResponse.data.data.length; i++) {

                    let expected_amount = {
                        expected_amount:
                            formatCurrency((parseFloat(applicantResponse.data.data[i].total_amount) / 3) +
                                (parseFloat(applicantResponse.data.data[i].insurance_fee) / 3) +
                                (parseFloat(applicantResponse.data.data[i].total_amount) * 0.075))
                    };
                    let mpaid_amount = {
                        mpaid_amount: formatCurrency(
                            ((parseFloat(applicantResponse.data.data[i].total_amount) / 3) - parseFloat(applicantResponse.data.data[i].monthly_instalment))
                            +
                            ((parseFloat(applicantResponse.data.data[i].insurance_fee) / 3) - parseFloat(applicantResponse.data.data[i].insurance_installment))
                            +
                            ((parseFloat(applicantResponse.data.data[i].total_amount) * 0.075) - parseFloat(applicantResponse.data.data[i].interest))
                        )
                    };


                    let expected_insurance = {
                        expected_insurance:
                            formatCurrency(
                                (parseFloat(applicantResponse.data.data[i].insurance_fee) / 3))
                    };
                    let mpaid_insurance = {
                        mpaid_insurance: formatCurrency(
                            ((parseFloat(applicantResponse.data.data[i].insurance_fee) / 3) - parseFloat(applicantResponse.data.data[i].insurance_installment))
                        )
                    };


                    // principal
                    let expected_principal = {
                        expected_principal: formatCurrency((parseFloat(applicantResponse.data.data[i].total_amount) / 3))
                    };
                    let mpaid_principal = {
                        mpaid_principal: formatCurrency(
                            ((parseFloat(applicantResponse.data.data[i].total_amount) / 3) - parseFloat(applicantResponse.data.data[i].monthly_instalment))
                        )
                    };


                    let expected_interest = {
                        expected_interest:
                            formatCurrency(
                                (parseFloat(applicantResponse.data.data[i].total_amount) * 0.075))
                    };
                    let mpaid_interest = {
                        mpaid_interest: formatCurrency(
                            ((parseFloat(applicantResponse.data.data[i].total_amount) * 0.075) - parseFloat(applicantResponse.data.data[i].interest))
                        )
                    };

                    let date = { dates: (moment.utc(applicantResponse.data.data[i].start_date).format('DD/MM/YYYY')) };

                    data.push(Object.assign(date, mpaid_insurance, expected_insurance, expected_interest, mpaid_interest, mpaid_amount, mpaid_principal, expected_principal, expected_amount, applicantResponse.data.data[i]));
                    setLoad(false)
                    setDataTotal(applicantResponse.data.total)





                }
                setInstalment(data)
            } else {
                setLoad(false)
                setDataTotal(0)

                setInstalment(data)
            }
        }))

    }, [startDate, queryString, endDate]);

    // alert(TOKEN)
    const rowClickedHandler = (event, data, rowIndex) => {

        // alert(JSON)
        setFarmerProps(data);
        setApplicantDetails(true)

    };



    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true,
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here...",
            no_data_text: "There was no data this month",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    const applyCallback = (startDate, endDate) => {
        const Sdate1 = moment(startDate).format('DD MMM, YYYY');
        const Edate2 = moment(endDate).format('DD MMM, YYYY');
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)

        setDateValue(Sdate1 + " " + Edate2)
        setStartDate(startDate)
        setEndDate(endDate)
    }


    const ranges = {
        "Today Only": [moment(startDate), moment(endDate)],
        "Yesterday Only": [
            moment(startDate).subtract(1, "days"),
            moment(endDate).subtract(1, "days")
        ],
        "3 Days": [moment(startDate).subtract(3, "days"), moment(endDate)],
        "5 Days": [moment(startDate).subtract(5, "days"), moment(endDate)],
        "1 Week": [moment(startDate).subtract(7, "days"), moment(endDate)],
        "2 Weeks": [moment(startDate).subtract(14, "days"), moment(endDate)],
        "1 Month": [moment(startDate).subtract(1, "months"), moment(endDate)],
        "1 Year": [moment(startDate).subtract(1, "years"), moment(endDate)]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false
    }


    const columns = [

        {
            key: "name",
            text: "Borrower",
            TrOnlyClassName: 'cell',
            className: "cell",
            align: "left"

        },
        {
            key: "msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone",
            className: "cell",
            align: "left"
        },
        {
            key: "expected_amount",
            TrOnlyClassName: 'cell',
            text: "Expected Amount",
            className: "cell",
            align: "left"
        },
        {
            key: "mpaid_amount",
            TrOnlyClassName: 'cell',
            text: "Total Paid Amount",
            className: "cell",
            align: "left"
        },
        {
            key: "expected_principal",
            TrOnlyClassName: 'cell',
            text: "Expected Principal",
            className: "cell",
            align: "left"
        },
        {
            key: "mpaid_principal",
            TrOnlyClassName: 'cell',
            text: "Paid Principal",
            className: "cell",
            align: "left"
        },

        {
            key: "expected_interest",
            TrOnlyClassName: 'cell',
            text: "Expected Interest",
            className: "cell",
            align: "left"
        },
        {
            key: "mpaid_interest",
            TrOnlyClassName: 'cell',
            text: "Paid Interest",
            className: "cell",
            align: "left"
        },
        {
            key: "expected_insurance",
            TrOnlyClassName: 'cell',
            text: "Expected Insurance",
            className: "cell",
            align: "left"
        },
        {
            key: "mpaid_insurance",
            TrOnlyClassName: 'cell',
            text: "Paid Insurance",
            className: "cell",
            align: "left"
        },

        {
            key: "field_officer",
            TrOnlyClassName: 'cell',
            text: "Field Officer",
            className: "cell",
            align: "left"
        },
        {
            key: "branch_name",
            TrOnlyClassName: 'cell',
            text: "Branch",
            className: "cell",
            align: "left"
        },

        {
            key: "start_date",
            TrOnlyClassName: 'cell',
            text: "Loan Start Date",
            className: "cell",
            align: "left"
        },

    ];
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');

        setQueryString(queryString)

        setStartDate(moment(startDate))
        setEndDate(moment(endDate))
    }

    const navigate = useNavigate();
    return (
        <div style={{ marginTop: "-20px" }} >
            < >

                {
                    applicsntdetails === true ?
                        navigate("/farmer_details", { state: farmerProps, replace: true }) : null}

                < Card >
                    <CardBody >
                        <div className="row">
                            <div className="col-md-6">
                                <h4> <b>1st Instalment Analysis Report</b></h4>
                                <br />
                            </div>
                            <div className="col-6">
                                Refresh <button className="float-" title="refresh" style={{ background: "white", border: "0px" }}
                                    onClick={
                                        () => {
                                            setQueryString(require("randomstring").generate({
                                                length: 1,
                                                charset: 'alphanumeric',
                                                capitalization: 'lowercase'
                                            }))
                                        }} >
                                    <RefreshIcon />
                                </button>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-6">
                                            <DateTimeRangeContainer
                                                ranges={ranges}
                                                start={startDate}
                                                end={endDate}
                                                local={local}
                                                applyCallback={applyCallback}>
                                                <FormControl
                                                    id="formControlsTextB"
                                                    type="text"
                                                    value={datevalue}
                                                    label="Text"
                                                    placeholder="Filter by Date"
                                                />
                                            </DateTimeRangeContainer>
                                        </div>
                                        <div className="col-6 float-left">

                                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {

                                                const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));

                                                setDateValue("This Month")
                                                setStartDate(start)
                                                setEndDate(moment(new Date()))

                                            }} >Clear</button>
                                            {/* <input id="input" type="text" readOnly /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {/* <Link to="reports">
                                    <Button className="pull-right btn-sm" outline color="primary">
                                        Back to Repprts
                                    </Button>
                                </Link> */}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <br />

                                <h4> <b>Total Expected  Amount: </b> {expected_installment}</h4>

                                <h4> <b>Total Expected Principal: </b> {expected_principal}</h4>

                                <h4> <b>Total Expected Interest: </b> {expected_interest}</h4>

                                <h4> <b>Total Expected Insurance: </b> {expected_insurance}</h4>


                            </div>
                            <div className="col-6">
                                <br />
                                <h4> <b>Total  Paid Amount: </b> {actual_installment}</h4>

                                <h4> <b>Total Paid Principal: </b> {actual_principal}</h4>

                                <h4> <b>Total Paid Interest: </b> {actual_interest}</h4>

                                <h4> <b>Total Paid Insurance: </b> {actual_insurance}</h4>


                            </div>
                        </div>


                        <br />
                        <div className="panel-body" >

                            <div >
                                Total Applicants: <b>{datatotal}</b>
                                <br /><br />
                            </div>
                            <ReactDatatable
                                config={config}
                                records={instalment}
                                columns={columns}
                                dynamic={true}
                                id="tsc"
                                loading={isLoading}
                                onRowClicked={rowClickedHandler}
                                total_record={datatotal}
                                onChange={tableChangeHandler} />
                        </div>
                    </CardBody>


                </Card>
            </>
        </div>
    )

}
export default First_Instalment;
