import React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Button } from 'reactstrap';
import { baseURL, CONFIG } from '../../configs/exports';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from '../../shared/components/Alert';
import Loader from "react-loader-spinner";
import { Link } from 'react-router-dom';


export class AddDocuments extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    // constructor
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            applicant_details: this.props.farmerProps,
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            total_amount: this.props.farmerProps.value,
            vet_name: this.props.farmerProps.vet_name,
            applicant_fee: 0,
            loan_applicable: 0,
            showError: false,
        }
    }




    async componentDidMount() {
        const [
            livestockResponse] = await Promise.all([
                axios.get(baseURL + 'livestock?loan_status=2&filter_value=' + this.props.farmerProps.tag_id, CONFIG),
            ]);
        console.log("TESTsss", livestockResponse.data.data[0].id)
        this.setState({
            isLoading: false,
            livestock_id: livestockResponse.data.data[0].id,
        })
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true, isOpen: false });
        var data = new FormData();
        data.append("insurance_form", this.state.insurance_form);
        data.append("vet_valuation_form", this.state.vet_valuation_form);
        data.append("vaccine_regime_form", this.state.vaccine_regime_form);
        data.append("amount", this.state.total_amount);
        data.append("id", this.state.livestock_id);
        data.append("vet_name", this.state.vet_name);

        this.setState({ isLoading: true });
        // alert(baseURL + 'loan')
        axios.put(baseURL + 'livestock_document', data, {
            headers: {
                'Content-Type': 'multipart/form-data;',
                'Access-Control-Allow-Origin': '*',
                'Authorization': localStorage.getItem('token')
            },
        }).then((response) => {

            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });

                window.setTimeout(() => {
                    window.location.reload(false)
                }, 2000);

            } else {
                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                window.setTimeout(() => {
                    this.setState({ showError: false })
                }, 2000);
            }

        }).catch((error) => {
            console.log('bayoo', error)
            this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
        })
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true

        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }


    // borrower
    handleInsurance = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            insurance_form: event.target.files[0]
        });
    };
    handleVaccinationForm = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            vaccine_regime_form: event.target.files[0]
        });
    };

    handleVetValuationForm = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            vet_valuation_form: event.target.files[0]
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        // const { showComponent } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >

                <Card>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-6">
                                <h3><b>Add Missing Documents</b></h3>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>

                        {this.state.showError ? <div >

                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>


                        </div> : null}<br></br>

                        {this.state.isShowError ? (
                            <div>

                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>

                            </div>

                        ) : null}<br></br>
                        <form className="form" onSubmit={this.onSubmit}>
                            <br /><br />

                            {this.state.isLoading ?
                                <>
                                    <div className="text-center h-50 col-6 offset-3">
                                        <h4><b>Submitting</b></h4>
                                        <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50} //3 secs
                                        />
                                    </div>
                                </>
                                :
                                <>

                                    <div className="col-md-10 offset-1">

                                        <br />
                                        <h3><b>Livestock Details</b></h3>


                                        <div className="col-md-10 offset-1">
                                            <br />
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Insurance Form (PDF)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input
                                                        // className="csv-input"
                                                        type="file"
                                                        ref={input => {
                                                            this.filesInput = input;
                                                        }}
                                                        name="file"
                                                        required
                                                        customHeight
                                                        placeholder={null}
                                                        onChange={this.handleInsurance}
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-10 offset-1">
                                            <br />
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Vet Valuation Form (pdf)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input
                                                        // className="csv-input"
                                                        type="file"
                                                        ref={input => {
                                                            this.filesInput = input;
                                                        }}
                                                        name="file"
                                                        required
                                                        customHeight
                                                        placeholder={null}
                                                        onChange={this.handleVetValuationForm}
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-10 offset-1">
                                            <br />
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Vaccination Regime Form (pdf)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input
                                                        // className="csv-input"
                                                        type="file"
                                                        ref={input => {
                                                            this.filesInput = input;
                                                        }}
                                                        name="file"
                                                        required
                                                        customHeight
                                                        placeholder={null}
                                                        onChange={this.handleVaccinationForm}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-10 offset-1">
                                            <br />
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Cow Value</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input
                                                        // className="csv-input"
                                                        type="text"

                                                        name="total_amount"
                                                        required
                                                        value={this.state.total_amount}
                                                        customHeight
                                                        placeholder="Modify Cow Value"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-10 offset-1">
                                            <br />
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Vet Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input
                                                        // className="csv-input"
                                                        type="text"

                                                        name="vet_name"
                                                        required
                                                        value={this.state.vet_name}
                                                        customHeight
                                                        placeholder="Modify VetName"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <br />
                                </>
                            }


                            <div className="col-md-8 offset-2"><br /><br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Link to="farmer">
                                                <Button className="pull-right btn-sm" outline color="primary" >
                                                    Cancel
                                                </Button>
                                            </Link>

                                        </div>
                                        <div className="col-md-10">

                                            <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                {this.state.isLoading ? "Please Wait..." : "Submit"}
                                            </Button> &nbsp;&nbsp;&nbsp;

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </CardBody>
                </Card>
            </div >
        )
    }
}
// export default withTranslation('common')(AddCustomer);
export default withTranslation('common')(AddDocuments);
