import React, { useState, useEffect } from 'react';

import axios from 'axios';
import "../../_assets/css/file.css"

import { Card, CardBody } from 'reactstrap';
import { TOKEN } from '../../configs/exports';
import { baseURL, formatCurrency } from '../../configs/exports';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'


//rollover fuction

const AmountPaid = () => {

    const [queryString, setQueryString] = useState("")
    const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(moment(new Date()))
    const [datevalue, setDateValue] = useState("This Month")

    const [expected_value, setValues] = useState({
        count: 0,
        principal: 0,
        interest: 0,
        insurance_fee: 0,
        rollover_fee: 0,
        total: 0,
        debt_collection: 0
    })

    localStorage.setItem("STARTDATE", start)
    localStorage.setItem("ENDDATE", moment(new Date()))

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': TOKEN
            },
        };
        let url = baseURL + `amount_paid?period-0&startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}&${queryString}`;
        axios.all([
            axios.get(url, config)
        ]).then(axios.spread((applicantResponse) => {
            setValues({
                count: applicantResponse.data.total,
                principal: applicantResponse.data.principal,
                interest: applicantResponse.data.interest,
                insurance_fee: applicantResponse.data.insurance,
                rollover_fee: applicantResponse.data.rollover_fee,

                total: applicantResponse.data.total,
                debt_collection: applicantResponse.data.debt_collection
            })
            console.log("total loans", url)
        }))

    }, [startDate, queryString, endDate]);


    const applyCallback = (startDate, endDate) => {
        const Sdate1 = moment(startDate).format('DD MMM, YYYY');
        const Edate2 = moment(endDate).format('DD MMM, YYYY');
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)

        setDateValue(Sdate1 + " " + Edate2)
        setStartDate(startDate)
        setEndDate(endDate)
    }


    const ranges = {
        "Today Only": [moment(startDate), moment(endDate)],
        "Yesterday Only": [
            moment(startDate).subtract(1, "days"),
            moment(endDate).subtract(1, "days")
        ],
        "3 Days": [moment(startDate).subtract(3, "days"), moment(endDate)],
        "5 Days": [moment(startDate).subtract(5, "days"), moment(endDate)],
        "1 Week": [moment(startDate).subtract(7, "days"), moment(endDate)],
        "2 Weeks": [moment(startDate).subtract(14, "days"), moment(endDate)],
        "1 Month": [moment(startDate).subtract(1, "months"), moment(endDate)],
        "1 Year": [moment(startDate).subtract(1, "years"), moment(endDate)]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false
    }

    return (
        <div style={{ marginTop: "-20px" }} >
            < >

                < Card >
                    <CardBody >
                        <div className="row">
                            <div className="col-md-6">
                                <h4> <b>Amount Collected Based on Time</b></h4>
                                <br />
                            </div>
                            <div className="col-6">
                                Refresh <button className="float-" title="refresh" style={{ background: "white", border: "0px" }}
                                    onClick={
                                        () => {
                                            setQueryString(require("randomstring").generate({
                                                length: 1,
                                                charset: 'alphanumeric',
                                                capitalization: 'lowercase'
                                            }))
                                        }} >
                                    <RefreshIcon />
                                </button>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-6">
                                            <DateTimeRangeContainer
                                                ranges={ranges}
                                                start={startDate}
                                                end={endDate}
                                                local={local}
                                                applyCallback={applyCallback}>
                                                <FormControl
                                                    id="formControlsTextB"
                                                    type="text"
                                                    value={datevalue}
                                                    label="Text"
                                                    placeholder="Filter by Date"
                                                />
                                            </DateTimeRangeContainer>
                                        </div>
                                        <div className="col-6 float-left">

                                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {

                                                const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));

                                                setDateValue("This Month")
                                                setStartDate(start)
                                                setEndDate(moment(new Date()))

                                            }} >Clear</button>
                                            {/* <input id="input" type="text" readOnly /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <br />
                                <br />
                                <h4> <b>Total  Principal: </b> {formatCurrency(expected_value.principal)}</h4><br />
                                {/* <h4> <b>Total  Principal: </b> {formatCurrency(expected_value.total)}</h4><br /> */}
                                <h4> <b>Total  Insurance Fee: </b> {formatCurrency(expected_value.insurance_fee)}</h4> <br />
                                <h4> <b>Total  Interest: </b> {formatCurrency(expected_value.interest)}</h4> <br />
                                <h4> <b>Total  Rollover: </b> {formatCurrency(expected_value.rollover_fee)}</h4> <br />
                                <h4> <b>Total  Debt Collection AMount: </b> {formatCurrency(expected_value.debt_collection)}</h4><br/><br/>


                                <h4> <b>Total  Collection: </b> {formatCurrency(expected_value.principal + expected_value.interest + expected_value.insurance_fee + expected_value.rollover_fee + expected_value.debt_collection)}</h4><br />

                            </div>
                        </div>



                    </CardBody>


                </Card>
            </>
        </div>
    )
}
export default AmountPaid;
