import React, { useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, CONFIG, formatCurrency, ROLE, USER_ID } from '../../configs/exports';
import Loader from "react-loader-spinner";
import "../../_assets/css/csv.css";
import * as moment from 'moment';
import {  useNavigate } from 'react-router-dom';
import {
    Button,
    Card, CardBody
} from 'reactstrap';
// import EditApplicant from './EditApplicant';



const Detail_Loan_Report = (props) => {
    const [isLoading, setLoad] = useState(true)
    const [smsLog, setSMSLog] = useState([])


    const [home, setLoanHome] = useState(false)

    const [farmerProps, setFarmerProps] = useState([])


    // const [avatar, setAvatar] = useState("") // set profile picture

    useEffect(() => {
        // consuming API based on active tab
        // alert(moment(props.farmer.due_date).format('YYYY-MM-DD'))
        //    alert(new Date(props.farmer.dates)) 


        // alert(props.farmer.mdays)
        // alert(moment(props.farmer.due_date).subtract(props.farmer.mdays, 'days').format('YYYY-MM-DD'))
        let sms_url = baseURL + `report_details?filter_value="${props.farmer.branch_name}"&period=${props.farmer.mperiod}&lock_period=${props.farmer.mlock_period}&days=${props.farmer.mdays}&user_id=${USER_ID}&&role_id=${ROLE}&startDate="${moment(props.farmer.due_date).subtract(props.farmer.mdays, 'days').format('YYYY-MM-DD')}"&endDate="${moment(props.farmer.due_date).subtract(props.farmer.mdays, 'days').format('YYYY-MM-DD')}"`;

        // alert(sms_url)
        // console.log("base_URL", sms_url)
        // = baseURL + "report_details?reference=" + due_date;
        // setLoad(true)



        // tab 1 API call...
        axios.all([
            axios.get(sms_url, CONFIG),
        ]).then(axios.spread((auctionResponse) => {
            let data = [];

            // alert(JSON.stringify(auctionResponse.data.data))
            if (auctionResponse.data.data.length > 0) {
                setLoad(false)
                let m_expected, m_collected, m_uncollected,
                    minstalment_balance, m_percentage;



                // alert('here')
                for (let i = 0; i < auctionResponse.data.data.length; i++) {



                    // alert(props.farmer.mperiod)

                    if (props.farmer.mperiod === 2) {

                        // alert(auctionResponse.data.data[i].first_month)
                        m_expected = { m_expected: formatCurrency(auctionResponse.data.data[i].first_month) }
                        m_percentage = { m_percentage: ((auctionResponse.data.data[i].collected / auctionResponse.data.data[i].first_month) * 100).toFixed(2) }

                        minstalment_balance = { minstalment_balance: formatCurrency((auctionResponse.data.data[i].first_month) - (auctionResponse.data.data[i].collected)) }

                    } else if (props.farmer.mperiod === 4) {
                        m_percentage = { m_percentage: ((auctionResponse.data.data[i].collected / auctionResponse.data.data[i].second_month) * 100).toFixed(2) }

                        minstalment_balance = { minstalment_balance: formatCurrency((auctionResponse.data.data[i].second_month) - (auctionResponse.data.data[i].collected)) }

                        m_expected = { m_expected: formatCurrency(auctionResponse.data.data[i].second_month) }
                        // minstalment_balance = { minstalment_balance: formatCurrency((auctionResponse.data.data[i]["1_inst"]) + (auctionResponse.data.data[i]["1_inst"])) }
                    } else {
                        m_percentage = { m_percentage: ((auctionResponse.data.data[i].collected / auctionResponse.data.data[i].expected) * 100).toFixed(2) }

                        m_expected = { m_expected: formatCurrency(auctionResponse.data.data[i].expected) }
                        // alert(props.farmer.mperiod)

                        // minstalment_balance = { minstalment_balance: formatCurrency((auctionResponse.data.data[i].second_month) - (auctionResponse.data.data[i].collected)) }

                        minstalment_balance = { minstalment_balance: formatCurrency(auctionResponse.data.data[i].due_collected) }
                    }

                    m_collected = { m_collected: formatCurrency(auctionResponse.data.data[i].collected) }
                    m_uncollected = { m_uncollected: formatCurrency(auctionResponse.data.data[i].due_collected) }
                    // alert(JSON.stringify(data))
                    // m_percentage = { m_percentage: ((summary_report[i].collected / summary_report[i].first_month) * 100).toFixed(2) }


                    // minstalment_balance = { minstalment_balance: formatCurrency(auctionResponse.data.data[i]["1_inst"]) }

                    let date_start = { dates_start: (moment.utc(auctionResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };
                    let date = { dates: (moment.utc(auctionResponse.data.data[i].due_date).subtract(60, 'days').format('DD/MM/YYYY HH:mm:ss')) };

                    data.push(Object.assign(date, date_start, m_percentage, m_collected, minstalment_balance, m_expected, m_uncollected, auctionResponse.data.data[i]));
                    setLoad(false)
                }

                setSMSLog(data)
            } else {
                setLoad(false)
                setSMSLog(data)
            }
        })).catch(error => {
            // alert(JSON.stringify(error.response))
        })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const config = {
        key_column: "tsc",
        length_menu: [20, 50, 100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 20,
        button: {
            csv: true,
            excel: true,
            print: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Filter here..",
            no_data_text: "There was no message logs found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }


    //sms columns list

    const columns_sms = [
        // {
        //     key: "row",
        //     TrOnlyClassName: 'cell',
        //     text: "#",
        //     className: "cell",
        //     align: "left"
        // },
        {
            key: "name",
            TrOnlyClassName: 'cell',
            text: "Name",
            className: "cell",
            align: "left"
        },
        {
            key: "msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone",
            className: "cell",
            align: "left"
        },
        {
            key: "m_expected",
            TrOnlyClassName: 'cell',
            text: "Total Expected",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_collected",
            TrOnlyClassName: 'cell',
            text: "Total Collected",
            className: "tsc",
            align: "left"
        },
        {
            key: "minstalment_balance",
            TrOnlyClassName: 'cell',
            text: "Current Instalment Balance",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_uncollected",
            TrOnlyClassName: 'cell',
            text: "Total Due Collected",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_percentage",
            TrOnlyClassName: 'cell',
            text: "% Collected vs Expected",
            className: "tsc",
            align: "left"
        },


        {
            key: "dates",
            TrOnlyClassName: 'cell',
            text: "Due Date",
            className: "cell",
            align: "left"
        },
        {
            key: "action",
            text: "Actions",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <React.Fragment className="center" >



                        <button className="btn btn-primary btn-sm"
                            title="View pics Details"
                            style={
                                { marginRight: '10px' }}

                            onClick={() => { loanHome(record) }}>
                            More <i className='fa fa-plus'></i>
                        </button>

                    </React.Fragment >
                );
            }
        },

    ];




    // go home
    const loanHome = (record) => {
        // alert(JSON.stringify(record))

        console.log("record", record)
        setLoanHome(true)
        setFarmerProps(record);


    }



    const backHome = () => {
        window.location.reload();
    }

    const navigate = useNavigate();
    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >

            {home === true ?

                navigate('/farmer_logs', {
                    state: {
                        farmer_name: farmerProps.name,
                        farmer_id: farmerProps.farmer_id,
                        latitude: farmerProps.latitude,
                        longitude: farmerProps.longitude,
                        passport_photo: farmerProps.passport_photo,
                        id_no: farmerProps.id_no,
                    }, replace: true
                }) : null}

            <Card>
                <CardBody className="">
                    <br />
                    <div className="tabs tabs--bordered-top" >
                        <div className="tabs__wrap">

                            {isLoading === false ?
                                <>
                                    <div className="row">
                                        <div className="col-6" >
                                            <h4><b>Due for collection, uncollected, collected, overdue loans per day</b> </h4>
                                            <br />
                                        </div>
                                        <div className="col-6">

                                            {/* <Link to="loan_report"> */}
                                            <Button className="pull-right btn-sm" onClick={backHome} outline color="primary">
                                                Swtich back
                                            </Button>
                                            {/* </Link> */}
                                        </div>

                                    </div>

                                    <ReactDatatable
                                        config={config}
                                        records={smsLog}
                                        columns={columns_sms}
                                        id="tsc"
                                        loading={isLoading}
                                    />

                                </>

                                :
                                <div className="text-center h-50">
                                    <Loader
                                        type="Puff"
                                        color="#00BFFF"
                                        height={50}
                                        width={50} //3 secs
                                    />
                                </div>
                            }


                        </div>
                    </div>
                </CardBody>

            </Card>


        </div>
    );
}

export default Detail_Loan_Report;
