import * as React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import $ from 'jquery'
import { baseURL, NAME, TOKEN } from '../../configs/exports';
import { Link, Navigate } from 'react-router-dom';
window.$ = window.jQuery = require('jquery')
// require("http://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js")
require('../../_assets/jss/sms_counter.js')

export class SubmitLoanAuctioning extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
            selected: [],
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            alert_color: '',
            isLoading: false,
            isShowError: false,
            loan_reference: props.location.state.loan_reference,
            name: '',
            succesStatus: false,
            statusMessage: '',


            showError: false,
            terms_channel: [{
                label: 'Accepted',
                value: 'Accepted',
            },
            {
                label: 'Rejected',
                value: "Rejected"
            }
            ]
        }
    }
    componentDidMount() {
        $('#message').countSms('#sms-counter');
    }

    handleChangeChannel = value => {
        if (value != null) {
            this.setState({
                channel: value.value,
            });
        } else {
            this.setState({
                channel: "",
            });

        }
    }
    onSubmit(e) {
        e.preventDefault();

        let formData = {
            "loan_reference": this.state.loan_reference,
            "created_by": NAME,
            "amount": this.state.amount,
            "description": this.state.message.replaceAll("’", "'"),
        }


        console.log(JSON.stringify(formData))

        if (this.state.amount > 0) {

            // alert("Please enter amount")
            this.setState({ isLoading: true, succesStatus: false });

            axios.post(baseURL + 'auction', formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': TOKEN
                },
            }).then((response) => {
                this.setState({
                    statusMessage: "Success!",
                    isShowError: true, succesStatus: false,
                    alert_color: "alert alert-success", isLoading: false
                });
                setTimeout(() => this.setState({ succesStatus: true }), 2000);

            }, error => {
                // alert(JSON.stringify(error.response.data.message))
                this.setState({ statusMessage: error.response.data.message, isShowError: true, succesStatus: false, alert_color: "alert alert-danger", isLoading: false });
            });

        } else {

            alert("check your amount")
        }



    }



    Constituency() {
        // eslint-disable-next-line
        return (this.state.type && (this.state.type.length == 0 || this.state.type.length > 0) &&
            this.state.type.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.id })))
    }

    onSelectChangeConstitueny = value => {
        this.setState({ constituency_id: value.value.toString() });
    };

    onSelectChange = value => {

        this.setState({ entity_id: value.value.toString() });
    };


    handleChangeGender = (event) => {
        this.setState({ selectedGender: event.target.value });
        //alert(event.target.value)
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    _onButtonClick() { }
    changeStatus() {
        this.setState({
            isShowError: false
        });
    }

    changeList(setSelected) {
        this.setState({ setSelected });
    }

    render() {
        const { hideComponent, succesStatus } = this.state;



        return (
            <div className="pcoded-main-container" >


                {
                    succesStatus === true ?
                        <Navigate to="/laon_action" />
                        :
                        <> {!hideComponent && (
                            <>

                                <Col className="col-md-10 offset-md-1" >


                                    <Card >
                                        <CardBody >
                                            <br />
                                            <div className="row">
                                                <div className="col-6">
                                                    <h4 className="form-label" > Actioning Ref# <b>{this.state.loan_reference}</b> </h4>
                                                </div>
                                                <div className="col-6">

                                                    <Link to="/livestock">
                                                        <Button className="pull-right btn-sm" outline color="primary">
                                                            Swtich back
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>

                                            <form onSubmit={this.onSubmit} >

                                                <br />

                                                <br />
                                                <div className="col-md-8" >

                                                    <input type="number" placeholder="Enter Amount" name="amount" className="form-control" value={this.state.amount} onChange={this.handleChange} />

                                                    <br />
                                                </div>
                                                <br />
                                                <h4 > Description </h4> {
                                                    this.state.isShowError ? < div className={this.state.alert_color}
                                                        style={
                                                            { fontSize: '15px' }} > {this.state.statusMessage} </div> : null}

                                                <br />
                                                <div className="form-group" >
                                                    <div className="row" >
                                                        <div className="col-md-8" >
                                                            <textarea name="message"
                                                                style={
                                                                    { paddingTop: '20px' }}
                                                                onChange={this.handleChange}
                                                                className="col-md-12"
                                                                placeholder="type your reason here"
                                                                id="message"
                                                                cols="col-md-1"
                                                                rows="20" > </textarea>
                                                        </div>
                                                        <div className="col-md-4" >

                                                            <ul id="sms-counter" >
                                                                <li > Length: <span class="length" > </span></li >
                                                                <li > Remaining: < span class="remaining" > </span></li >
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />

                                                <div className="col-md-12" >
                                                    <Button type="submit"
                                                        color="primary"
                                                        outline className="btn-paypal btn-lg pull-right" > {this.state.isLoading ?
                                                            "Please Wait..." : "Submit "}
                                                        <i className="fa fa-refresh" > </i></Button> &nbsp; &nbsp; &nbsp;
                                                </div>
                                            </form>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </>

                        )
                        } </>
                }

            </div>
        )

    }


}