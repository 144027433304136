import React, { useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, CONFIG } from '../../configs/exports';
import Loader from "react-loader-spinner";
import "../../_assets/css/csv.css";
import * as moment from 'moment';
import {
    Button,
    Card, CardBody
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
// import EditApplicant from './EditApplicant';



const ViewCallLogs = (props) => {
    const [isLoading, setLoad] = useState(true)
    const [smsLog, setSMSLog] = useState([])

    const location = useLocation();


    // const [avatar, setAvatar] = useState("") // set profile picture

    useEffect(() => {
        // consuming API based on active tab
        let sms_url = baseURL + "call_logs?reference=" + location.state.reference;
        // setLoad(true)



        // tab 1 API call...
        axios.all([
            axios.get(sms_url, CONFIG),
        ]).then(axios.spread((auctionResponse) => {
            let data = [];
            if (auctionResponse.data.data.length > 0) {
                setLoad(false)
                for (let i = 0; i < auctionResponse.data.data.length; i++) {

                    // format amount
                    let amount = { m_amount: "Message was submitted on " + auctionResponse.data.data[i].duration + " day" };
                    let date = { dates: (moment.utc(auctionResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(date, amount, auctionResponse.data.data[i]));
                    setLoad(false)
                }
                setSMSLog(data)
            } else {
                setLoad(false)
                setSMSLog(data)
            }
        })).catch(error => {
            // alert(JSON.stringify(error.response))
        })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const config = {
        key_column: "tsc",
        length_menu: [20, 50, 100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 20,
        button: {
            csv: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Filter here..",
            no_data_text: "There was no message logs found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }


    //sms columns list

    const columns_sms = [
        {
            key: "loan_reference",
            TrOnlyClassName: 'cell',
            text: "Loan No#",
            className: "tsc",
            align: "left"
        },

        {
            key: "description",
            TrOnlyClassName: 'cell',
            text: "Message",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_amount",
            TrOnlyClassName: 'cell',
            text: "Description",
            className: "tsc",
            align: "left"
        },

        {
            key: "created_by",
            TrOnlyClassName: 'cell',
            text: "Created by",
            className: "tsc",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'cell', text: "Date Created",
            className: "tsc",
            align: "left"
        }
    ];





    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >

            <Card>
                <CardBody className="">
                    <br />
                    <div className="tabs tabs--bordered-top" >
                        <div className="tabs__wrap">

                            {isLoading === false ?
                                <>
                                    <div className="row">
                                        <div className="col-6" >
                                            <b>Messages History</b> for {location.state.name}
                                            <br />
                                            <h3>On <b>{location.state.mdays}</b> days</h3>
                                            <br />
                                            <br />
                                        </div>
                                        <div className="col-3" >
                                            <Link
                                                to={{
                                                    pathname: '/call_logs',
                                                    state: {
                                                        farmer: location.state,
                                                    }
                                                }}>
                                                <Button className="pull-right btn-sm" outline color="primary">
                                                    Record New Message
                                                </Button>
                                            </Link>
                                        </div>
                                        <div className="col-3">

                                            <Link to="/unmatured">
                                                <Button className="pull-right btn-sm" outline color="primary">
                                                    Swtich back
                                                </Button>
                                            </Link>
                                        </div>

                                    </div>

                                    <ReactDatatable
                                        config={config}
                                        records={smsLog}
                                        columns={columns_sms}
                                        id="tsc"
                                        loading={isLoading}
                                    />

                                </>

                                :
                                <div className="text-center h-50">
                                    <Loader
                                        type="Puff"
                                        color="#00BFFF"
                                        height={50}
                                        width={50} //3 secs
                                    />
                                </div>
                            }


                        </div>
                    </div>
                </CardBody>

            </Card>


        </div>
    );
}

export default ViewCallLogs;
