import React, { useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { Card, CardBody, Table } from 'reactstrap';
import { baseURL, CONFIG, errorToast, formatCurrency, successToast, ToastTable, useStyles } from '../../configs/exports';
import * as moment from 'moment';
import { Autorenew } from "@material-ui/icons";
import clsx from "clsx";
import Modal from 'react-modal';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { MDBCloseIcon } from "mdbreact";
import 'react-toastify/dist/ReactToastify.css';



//farmer hooks

const SuspendAccount = () => {

    const [farmer, setFarmer] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")

    const [open, isOpen] = React.useState(false);

    const [spin, setSpin] = useState(false);


    const [id_no, setIDNo] = useState("")
    const [transaction_id, setTransactionID] = useState("")
    const [msisdn, setMSISDN] = useState("")
    const [amount, setAmount] = useState("")
    const [name, setName] = useState("")

    const [id, setID] = useState("")


    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };
    const classes = useStyles();

    useEffect(() => {
        setLoad(true)

        let url = baseURL + `suspend_account?` + queryString;
        // alert(url)
        axios.all([
            axios.get(url, CONFIG)
        ]).then(axios.spread((farmerResponse) => {
            let data = [];
            if (farmerResponse.data.data.length > 0) {
                setDataTotal(farmerResponse.data.data.length)
                setLoad(false)
                for (let i = 0; i < farmerResponse.data.data.length; i++) {

                    let fpaid_amount = { fpaid_amount: formatCurrency(parseFloat(farmerResponse.data.data[i].amount).toFixed(2)) };

                    let date = { dates: (moment.utc(farmerResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(date, fpaid_amount, farmerResponse.data.data[i]));
                    setLoad(false)
                }
                setDataTotal(farmerResponse.data.total)
                setFarmer(data)
            } else {
                setLoad(false)
                setFarmer(data)
            }
        }))

    }, [queryString]);


    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 20,
        button: {
            csv: true,
            excel: true,
            print: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }



    const columns = [
        {
            key: "name",
            TrOnlyClassName: 'cell',
            text: "Name",
            className: "tsc",
            align: "left"
        },

        {
            key: "user_id_no",
            TrOnlyClassName: 'cell',
            text: "ID NO.",
            className: "tsc",
            align: "left"
        },

        {
            key: "fpaid_amount",
            TrOnlyClassName: 'cell',
            text: "Amount",
            className: "tsc",
            align: "left"
        },
        {
            key: "transaction_id",
            TrOnlyClassName: 'cell',
            text: "Transaction ID",
            className: "tsc",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'cell', text: "Date",
            className: "tsc",
            align: "left"
        }
        ,
        {
            key: "action",
            text: "Actions",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <React.Fragment className="center" >
                        <button className="btn btn-primary btn-sm"
                            title="View pics Details"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { openModal(record) }}
                        >
                            Reconcile
                        </button>

                    </React.Fragment >
                );
            }
        },
    ];

    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }


    const closeModal = e => {
        isOpen(false);
    };
    const openModal = e => {
        isOpen(true)

        setIDNo(e.user_id_no)
        setTransactionID(e.transaction_id)
        setMSISDN(e.msisdn)
        setAmount(e.amount)
        setName(e.name)
        setID(e.id)
    };



    // handle id no change
    const handleChange = event => {
        setIDNo(event.target.value);
    };


    // submit reconciliation
    const onSubmit = e => {
        e.preventDefault();
        setLoad(true);
        let formData =
        {
            "TransID": transaction_id,
            "FirstName": name,
            "MiddleName": "",
            "LastName": "",
            "MSISDN": msisdn,
            "BillRefNumber": id_no,
            "TransAmount": amount
        }


        let formData_id =
        {
            "id": id
        }
        axios.post(baseURL + 'confirmation_mobi', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }).then((response) => {

            axios.post(baseURL + 'update_suspend', formData_id, CONFIG).then((response) => { }).catch(error => { });


            setQueryString(require("randomstring").generate({
                length: 1,
                charset: 'alphanumeric',
                capitalization: 'lowercase'
            }))
            successToast("Successful")
            setLoad(false)
            isOpen(false);



        }).catch(error => {
            setLoad(false)
            isOpen(false);
            errorToast("Error")
            setQueryString(require("randomstring").generate({
                length: 1,
                charset: 'alphanumeric',
                capitalization: 'lowercase'
            }))

        });
    }



    return (
        <div style={{ marginTop: "-20px" }} >
            < >
                < Card >

                    {ToastTable()}

                    <Modal
                        isOpen={open}
                        onRequestClose={e => {
                            closeModal(e);
                        }}
                        contentLabel="My dialog"
                        className="mymodal"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <MDBCloseIcon onClick={closeModal} />
                        <h4><b>Confirm ID Number</b></h4>
                        <>
                            <Form className="form login-form" onSubmit={onSubmit}>
                                {/*n  <h5><b>Get Agent Number</b></h5> */}
                                <div className="form__form-group">
                                    <br></br>

                                    <span className="form__form-group-label">ID No.</span>
                                    <div className="form__form-group-field">
                                        <Form.Control
                                            autoFocus
                                            type="text"
                                            name="id_no"
                                            style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                                            placeholder="Enter ID No."
                                            className="input-without-border-radius"
                                            value={id_no}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <br />
                                </div>
                                <div className="account__btns col-8 offset-2">
                                    <Button className="account__btn" type='submit' color="success"> {
                                        isLoading ? "Please wait..." : "Proceed"
                                    }</Button>
                                </div>

                            </Form>
                        </>
                    </Modal>

                    <CardBody >
                        <Table responsive hover>
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4> <b>Suspense Account</b></h4>
                                    </div>
                                    <div className="col-6">
                                        <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}

                                            onClick={
                                                () => {
                                                    refreshCanvas()
                                                    setQueryString(require("randomstring").generate({
                                                        length: 1,
                                                        charset: 'alphanumeric',
                                                        capitalization: 'lowercase'
                                                    }))
                                                }}
                                        >
                                            Refresh
                                            <Autorenew
                                                className={clsx({
                                                    [classes.refresh]: true,
                                                    spin: spin
                                                })}
                                                onClick={
                                                    () => {
                                                        refreshCanvas()
                                                        setQueryString(require("randomstring").generate({
                                                            length: 1,
                                                            charset: 'alphanumeric',
                                                            capitalization: 'lowercase'
                                                        }))
                                                    }}
                                                spin={360}
                                            />

                                        </button>
                                    </div>
                                </div>
                                <br />
                                <div >
                                    Total Deposits: <b>{datatotal}</b>
                                    <br /><br />
                                </div>
                                <ReactDatatable
                                    config={config}
                                    records={farmer}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={datatotal}
                                    onChange={tableChangeHandler} />
                            </div>
                        </Table>
                    </CardBody>


                </Card>

            </>
        </div>
    )

}
export default SuspendAccount;