import React, { useState, Fragment, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, CONFIG, formatCurrency, ROLE, USER_ID } from '../../configs/exports';

import { useNavigate } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "../../_assets/css/csv.css";
import * as moment from 'moment';
import {
    Card, CardBody,
    Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'

// import EditApplicant from './EditApplicant';



const UnmatureLoans = () => {
    const navigate = useNavigate();

    const [instalment, setInstalment] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")
    const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(moment(new Date()))
    const [datevalue, setDateValue] = useState("This Month")


    const [expected_amount, setExpectedAmount] = useState(0);
    const [paid_amount, setPaidAmount] = useState(0);
    const [balance_amount, setBalanceAmount] = useState(0);
    const [previous_amount, setPrevious] = useState(0);


    // eslint-disable-next-line    
    const [home, setLoanHome] = useState(false)

    // eslint-disable-next-line
    const [farmerProps, setFarmerProps] = useState([])

    localStorage.setItem("STARTDATE", start)
    localStorage.setItem("ENDDATE", moment(new Date()))

    // eslint-disable-next-line
    const [activeTab, setActiveTab] = useState("1")

    // const [avatar, setAvatar] = useState("") // set profile picture
    useEffect(() => {
        let statment_url;
        if (activeTab === "1") {
            statment_url = baseURL + `loan_stages?period=1&lock_period=0&user_id=${USER_ID}&&role_id=${ROLE}&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;
        }
        // 30 days
        if (activeTab === "2") {
            statment_url = baseURL + `loan_stages?period=2&user_id=${USER_ID}&&role_id=${ROLE}&lock_period=2&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;
        }
        else if (activeTab === "3") {
            statment_url = baseURL + `loan_stages?period=3&lock_period=4&user_id=${USER_ID}&&role_id=${ROLE}&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;
        }

        axios.all([
            axios.get(statment_url, CONFIG),
        ]).then(axios.spread((unmaturedResponse) => {
            let data = [];

            if (unmaturedResponse.data.data.length > 0) {
                setLoad(false)

                /**
                * @description If list is
                */
                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))

                let p = 0;
                if (id > 1) {
                    p = (((id - 1) * parseInt(params.get("page_size"))))
                }

                let valueExpected = 0
                let countExpected = 0


                let valuePaid = 0
                let countPaid = 0

                let valueBalance = 0
                let countBalance = 0

                let valuePrevious = 0
                let countPrevious = 0


                for (let i = 0; i < unmaturedResponse.data.data.length; i++) {
                    p++;
                    let unrow = { row: p }
                    let date = { dates: (moment.utc(unmaturedResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };


                    let mexpected_amount, mpaid_amount, balance_amount, previous_instalment_balance, total_balance, mdays, due_days;
                    if (activeTab === "1") {



                        mdays = { mdays: 30 - (unmaturedResponse.data.data[i].days - 60) }  // period for first installment


                        due_days = {due_days: (unmaturedResponse.data.data[i].days - 60)}  // due days for first installment
                        mexpected_amount = {
                            mexpected_amount:
                                formatCurrency(
                                    (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                )
                        };

                        countExpected = (
                            (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                            +
                            parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075)
                            +
                            parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                        )
                        valueExpected += countExpected


                        mpaid_amount = {
                            mpaid_amount: formatCurrency(
                                ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_installment))
                                +
                                (parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075) - parseFloat(unmaturedResponse.data.data[i].interest))

                                +
                                ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment))
                            )
                        };


                        countPaid = (
                            ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_installment))
                            +
                            (parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075) - parseFloat(unmaturedResponse.data.data[i].interest))

                            +
                            ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment))
                        )
                        valuePaid += countPaid


                        balance_amount = {
                            balance_amount: formatCurrency(
                                parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                            )
                        };

                        countBalance = (
                            parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                            +
                            parseFloat(unmaturedResponse.data.data[i].interest)
                            +
                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                        )
                        valueBalance += countBalance


                        previous_instalment_balance = {
                            previous_instalment_balance:
                                formatCurrency(0)
                        };

                        valuePrevious = 0
                        total_balance = {
                            total_balance:
                                formatCurrency(
                                    (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                    )
                                )
                        };


                    }
                    else if (activeTab === "2") {
                        mdays = { mdays: 30 - (unmaturedResponse.data.data[i].days - 30) }
                        // mdays = { mdays: unmaturedResponse.data.data[i].days - 30 }


                        due_days = {due_days: (unmaturedResponse.data.data[i].days - 30)}  // due days for second installment
                        mexpected_amount = {
                            mexpected_amount:
                                formatCurrency(
                                    (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                    +
                                    parseFloat(((unmaturedResponse.data.data[i].expected_interest_second)))
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                )
                        };
                        countExpected = (
                            (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                            +
                            parseFloat(((unmaturedResponse.data.data[i].expected_interest_second)))
                            +
                            parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                        )
                        valueExpected += countExpected


                        mpaid_amount = {
                            mpaid_amount: formatCurrency(
                                ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second))
                                +
                                (
                                    parseFloat(((unmaturedResponse.data.data[i].expected_interest_second))) - parseFloat(unmaturedResponse.data.data[i].interest_second)
                                )
                                +
                                ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second))
                            )
                        };
                        countPaid = (
                            ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second))
                            +
                            (
                                parseFloat(((unmaturedResponse.data.data[i].expected_interest_second))) - parseFloat(unmaturedResponse.data.data[i].interest_second)
                            )
                            +
                            ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second))
                        )
                        valuePaid += countPaid


                        balance_amount = {
                            balance_amount: formatCurrency(
                                parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest_second)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                            )
                        };

                        countBalance = (
                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                            +
                            parseFloat(unmaturedResponse.data.data[i].interest_second)
                            +
                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                        )
                        valueBalance += countBalance


                        previous_instalment_balance = {
                            previous_instalment_balance:
                                formatCurrency(
                                    (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                    ))
                        };

                        countPrevious = (
                            (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                            ))
                        valuePrevious += countPrevious

                        total_balance = {
                            total_balance:
                                formatCurrency(
                                    (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                    )
                                    + (
                                        parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest_second)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                    )
                                )
                        };

                        // let balance_amount = {
                        //     balance_amount: formatCurrency(
                        //         parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                        //         +
                        //         parseFloat(unmaturedResponse.data.data[i].interest)
                        //         +
                        //         parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                        //     )
                        // };


                    }
                    else if (activeTab === "3") {
                        mdays = { mdays: 30 - (unmaturedResponse.data.data[i].days - 0) }


                        due_days = {due_days: (unmaturedResponse.data.data[i].days - 0)}  // due days for third installment
                        // mdays = { mdays: unmaturedResponse.data.data[i].days - 0 }
                        mexpected_amount = {
                            mexpected_amount:
                                formatCurrency(
                                    (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                    +
                                    parseFloat(((unmaturedResponse.data.data[i].expected_interest_third)))

                                    +
                                    parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                )
                        };

                        countExpected = (
                            (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                            +
                            parseFloat(((unmaturedResponse.data.data[i].expected_interest_third)))
                            +
                            parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                        )
                        valueExpected += countExpected




                        mpaid_amount = {
                            mpaid_amount: formatCurrency(
                                ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third))
                                +
                                (parseFloat(((unmaturedResponse.data.data[i].expected_interest_third))) - parseFloat(unmaturedResponse.data.data[i].interest_third))
                                +
                                ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third))
                            )
                        };

                        countPaid = (
                            ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third))
                            +
                            (parseFloat(((unmaturedResponse.data.data[i].expected_interest_third))) - parseFloat(unmaturedResponse.data.data[i].interest_third))
                            +
                            ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third))
                        )
                        valuePaid += countPaid


                        balance_amount = {
                            balance_amount: formatCurrency(
                                parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest_third)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                            )
                        };

                        countBalance = (
                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                            +
                            parseFloat(unmaturedResponse.data.data[i].interest_third)
                            +
                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                        )
                        valueBalance += countBalance

                        previous_instalment_balance = {
                            previous_instalment_balance:
                                formatCurrency(
                                    (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                    )
                                    + (
                                        parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest_second)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                    ))
                        };

                        countPrevious = (
                            (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                            )
                            + (
                                parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest_second)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                            ))
                        valuePrevious += countPrevious
                        total_balance = {
                            total_balance:
                                formatCurrency(
                                    (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                    )
                                    + (
                                        parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest_second)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                    )
                                    +
                                    (
                                        parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest_third)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                                    )
                                )
                        };

                    }


                    data.push(Object.assign(date,
                        date,
                        mdays,
                        due_days,
                        mexpected_amount,
                        mpaid_amount,
                        balance_amount,
                        previous_instalment_balance,
                        total_balance,
                        unrow,
                        unmaturedResponse.data.data[i]));
                    setLoad(false)
                }

                setExpectedAmount(valueExpected)
                setPaidAmount(valuePaid)
                setBalanceAmount(valueBalance)
                setPrevious(valuePrevious)

                // eslint-disable-next-line
                setInstalment(data);
                setDataTotal(unmaturedResponse.data.total);

            } else {
                setLoad(false)
                setInstalment(data)
            }
        })).catch(error => {
            // alert(JSON.stringify(error.response))
        })

    }, [startDate, queryString, endDate, activeTab]);

    // const locale = 'en-US';
    // const formatCurrency = amount => {
    //     return new Intl.NumberFormat(locale, {
    //         style: 'currency',
    //         currency: "ABS",
    //         minimumFractionDigits: 2,
    //         maximumFractionDigits: 2
    //     }).format(amount).replaceAll('ABS', "");
    // };



    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        filename: "Unmatured",
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true,
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here...",
            no_data_text: "There was no data this month",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    const applyCallback = (startDate, endDate) => {
        const Sdate1 = moment(startDate).format('DD MMM, YYYY');
        const Edate2 = moment(endDate).format('DD MMM, YYYY');
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)
        setDateValue(Sdate1 + " " + Edate2)
        setStartDate(startDate)
        setEndDate(endDate)
    }


    const ranges = {
        "Today Only": [moment(startDate), moment(endDate)],
        "Yesterday Only": [
            moment(startDate).subtract(1, "days"),
            moment(endDate).subtract(1, "days")
        ],
        "3 Days": [moment(startDate).subtract(3, "days"), moment(endDate)],
        "5 Days": [moment(startDate).subtract(5, "days"), moment(endDate)],
        "1 Week": [moment(startDate).subtract(7, "days"), moment(endDate)],
        "2 Weeks": [moment(startDate).subtract(14, "days"), moment(endDate)],
        "1 Month": [moment(startDate).subtract(1, "months"), moment(endDate)],
        "1 Year": [moment(startDate).subtract(1, "years"), moment(endDate)]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false
    }

    const columns = [
        // {
        //     key: "row",
        //     TrOnlyClassName: 'cell',
        //     text: "#",
        //     className: "cell",
        //     align: "left"
        // },
        {
            key: "name",
            text: "Borrower",
            TrOnlyClassName: 'cell',
            className: "cell",
        },

        {
            key: "msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone",
            className: "cell",
            align: "left"
        },

        {
            key: "action",
            text: "Period",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    // eslint-disable-next-line
                    <Fragment className="center" >
                        {
                            <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                {record.mdays} days
                            </span>
                        }
                    </Fragment>
                );
            }
        },

        {
            key: "action",
            text: "Due Days",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    // eslint-disable-next-line
                    <Fragment className="center" >
                        {
                            <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                {record.due_days} days
                            </span>
                        }
                    </Fragment>
                );
            }
        },

        {
            key: "mexpected_amount",
            TrOnlyClassName: 'cell',
            text: "Total Inst. Amount",
            className: "cell",
            align: "left"
        },
        {
            key: "mpaid_amount",
            TrOnlyClassName: 'cell',
            text: "Total Paid Amount",
            className: "cell",
            align: "left"
        },

        {
            key: "balance_amount",
            TrOnlyClassName: 'cell',
            text: "Current Inst. Balance",
            className: "cell",
            align: "left"
        },
        {
            key: "previous_instalment_balance",
            TrOnlyClassName: 'cell',
            text: "Previous Inst. Balance",
            className: "cell",
            align: "left"
        },
        {
            key: "total_balance",
            TrOnlyClassName: 'cell',
            text: "Total Balance",
            className: "cell",
            align: "left"
        },

        {
            key: "field_officer",
            TrOnlyClassName: 'cell',
            text: "Field Officer",
            className: "cell",
            align: "left"
        },
        {
            key: "branch_name",
            TrOnlyClassName: 'cell',
            text: "Branch",
            className: "cell",
            align: "left"
        },

        {
            key: "start_date",
            TrOnlyClassName: 'cell',
            text: "Loan Start Date",
            className: "cell",
            align: "left"
        },
        // {
        //     key: "action",
        //     text: "Actions",
        //     TrOnlyClassName: 'cell',
        //     className: "cell",
        //     width: 250,
        //     sortable: false,
        //     cell: record => {
        //         return (
        //             <Fragment className="center" >



        //                 <button className="btn btn-primary btn-sm"
        //                     title="View pics Details"
        //                     style={
        //                         { marginRight: '10px' }}

        //                     onClick={() => { loanHome(record) }}>
        //                     Record Call
        //                 </button>

        //             </Fragment >
        //         );
        //     }
        // },

    ];
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');

        setQueryString(queryString)

        setStartDate(moment(startDate))
        setEndDate(moment(endDate))
    }

    const toggle = (activeTab) => {

        setActiveTab(activeTab)

    }


    // const rowClickedHandler = (data) => {

    //     // alert(JSON)
    //     setFarmerProps(data);
    //     setApplicantDetails(true)

    // };


    // go home
    // const loanHome = (record) => {
    //     // alert(JSON.stringify(record))
    //     setLoanHome(true)
    //     setFarmerProps(record);

    // }

    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >
            {home === true ?

                navigate('/view_call_logs', { state: farmerProps, replace: true }) : null}


            {/* 
            {
                applicsntdetails === true ? <Navigate to={{
                    pathname: '/farmer_details',
                    state: {
                        farmer: farmerProps
                    }
                }} /> : null} */}


            <Card>
                <CardBody >
                    <h4><b>Unmatured Loans</b></h4>
                    <br />


                    <div className="tabs tabs--bordered-top" >
                        <div className="tabs__wrap">

                            {isLoading === false ?
                                <>
                                    <div>
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => {
                                                        toggle('1');
                                                    }}
                                                >
                                                    1st Instalment
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '2' })}
                                                    onClick={() => {
                                                        toggle('2');
                                                    }}
                                                >
                                                    2nd Instalment
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '3' })}
                                                    onClick={() => {
                                                        toggle('3');
                                                    }}
                                                >
                                                    3rd Instalment
                                                </NavLink>
                                            </NavItem>

                                        </Nav>

                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="col-md-8">

                                                    <br />
                                                    <div className="row">

                                                        <div className="col-6">

                                                            <DateTimeRangeContainer
                                                                ranges={ranges}
                                                                start={startDate}
                                                                end={endDate}
                                                                local={local}
                                                                applyCallback={applyCallback}>
                                                                <FormControl
                                                                    id="formControlsTextB"
                                                                    type="text"
                                                                    value={datevalue}
                                                                    label="Text"
                                                                    placeholder="Filter by Date"
                                                                />
                                                            </DateTimeRangeContainer>
                                                        </div>
                                                        <div className="col-6 float-left">

                                                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {

                                                                const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));

                                                                setDateValue("This Month")
                                                                setStartDate(start)
                                                                setEndDate(moment(new Date()))

                                                            }} >Clear</button>
                                                            {/* <input id="input" type="text" readOnly /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                {/* <Link to="reports">
                                    <Button className="pull-right btn-sm" outline color="primary">
                                        Back to Repprts
                                    </Button>
                                </Link> */}

                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <br />

                                                <h4> <b>Total Expected  Amount: </b> {formatCurrency(expected_amount)}</h4>

                                                <h4> <b>Total Paid Amount: </b> {formatCurrency(paid_amount)}</h4>



                                            </div>
                                            <div className="col-6">
                                                <br />
                                                <h4> <b>Total Previous Instalment: </b> {formatCurrency(previous_amount)}</h4>

                                                <h4> <b>Total Balance: </b><i style={{ color: "red" }}> {formatCurrency(balance_amount)}</i></h4>


                                            </div>
                                        </div>

                                        <br />



                                        <ReactDatatable
                                            config={config}
                                            records={instalment}
                                            columns={columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={isLoading}
                                            total_record={datatotal}
                                            onChange={tableChangeHandler} />
                                    </div>

                                </>

                                :
                                <div className="text-center h-50">
                                    <Loader
                                        type="Puff"
                                        color="#00BFFF"
                                        height={50}
                                        width={50} //3 secs
                                    />
                                </div>
                            }


                        </div>
                    </div>
                </CardBody>

            </Card>


        </div>
    );
}

export default UnmatureLoans;
