import React, { useState, useEffect } from 'react';

import ReactDatatable from '@mkikets/react-datatable';

import axios from 'axios';
import "../../_assets/css/file.css"

import { Card, CardBody } from 'reactstrap';
import { errorToast, ToastTable, TOKEN } from '../../configs/exports';
import { baseURL, formatCurrency } from '../../configs/exports';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import { useNavigate } from 'react-router';


//rollover fuction

const NetCashFlow = () => {
    const navigate = useNavigate();

    const [instalment, setInstalment] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [queryString, setQueryString] = useState("")
    const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(moment(new Date()))
    const [datevalue, setDateValue] = useState("This Month")


    const [commission, setGoCommission] = useState(false)

    const [commission_value, setCommissionValue] = useState(0)


    localStorage.setItem("STARTDATE", start)
    localStorage.setItem("ENDDATE", moment(new Date()))

    useEffect(() => {
        setLoad(true)

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': TOKEN
            },
        };
        let url = baseURL + `netcashflow_report?startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;

        axios.all([
            axios.get(url, config)
        ]).then(axios.spread((applicantResponse) => {
            let data = [];
            console.log("total loans", url)

            if (applicantResponse.data.data.length > 0) {
                // setAgent(agentResponse.data.data)
                // alert(  applicantResponse.data.data[0].application_date)
                setLoad(false)
                if (applicantResponse.data.data.length > 0) {
                    for (let i = 0; i < applicantResponse.data.data.length; i++) {

                        let date = { dates: (moment.utc(applicantResponse.data.data[i].start_date).format('DD/MM/YYYY')) };

                        let t_net;

                        let a_net;
                        let f_name;

                        let f_b_name;


                        let f_msisdn;

                        let f_label;

                        if (i === (applicantResponse.data.data.length - 1)) {
                            f_name = { f_name: "TOTAL" };
                            f_b_name = { f_b_name: "_" };


                            f_msisdn = { f_msisdn: "_" };
                            f_label = { f_label: "Commissions" };
                        } else {
                            f_name = { f_name: (applicantResponse.data.data[i].name) };
                            f_b_name = { f_b_name: (applicantResponse.data.data[i].branch_name) };
                            f_label = { f_label: "" };

                            f_msisdn = { f_msisdn: (applicantResponse.data.data[i].msisdn) };
                        }
                        t_net = { t_net: (formatCurrency(applicantResponse.data.data[i].total_net)) };
                        a_net = { a_net: (formatCurrency(applicantResponse.data.data[i].actual_netcahflow)) };



                        let t_p = { t_p: (formatCurrency(applicantResponse.data.data[i].principal)) };
                        let a_m = { a_m: (formatCurrency(applicantResponse.data.data[i].amount_paid)) };


                        let insurance = { insurance: (formatCurrency(applicantResponse.data.data[i].insurance_fee)) };

                        let outstand_balance = { outstand_balance: (formatCurrency(applicantResponse.data.data[i].actual_collection)) };

                        let gross = { gross: (formatCurrency(applicantResponse.data.data[i].gross_net)) };

                        data.push(Object.assign(date, f_label, f_name, gross, t_p, a_m, insurance, outstand_balance, f_msisdn, f_b_name, t_net, a_net, applicantResponse.data.data[i]));

                        setLoad(false)
                    }
                }
                setInstalment(data)
            } else {
                setLoad(false)

                setInstalment(data)
            }
        }))

    }, [startDate, queryString, endDate]);




    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true,
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "SearcH BRANCH here...",
            no_data_text: "There was no data this month",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    const applyCallback = (startDate, endDate) => {
        const Sdate1 = moment(startDate).format('DD MMM, YYYY');
        const Edate2 = moment(endDate).format('DD MMM, YYYY');
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)

        setDateValue(Sdate1 + " " + Edate2)
        setStartDate(startDate)
        setEndDate(endDate)
    }


    const ranges = {
        "Today Only": [moment(startDate), moment(endDate)],
        "Yesterday Only": [
            moment(startDate).subtract(1, "days"),
            moment(endDate).subtract(1, "days")
        ],
        "3 Days": [moment(startDate).subtract(3, "days"), moment(endDate)],
        "5 Days": [moment(startDate).subtract(5, "days"), moment(endDate)],
        "1 Week": [moment(startDate).subtract(7, "days"), moment(endDate)],
        "2 Weeks": [moment(startDate).subtract(14, "days"), moment(endDate)],
        "1 Month": [moment(startDate).subtract(1, "months"), moment(endDate)],
        "1 Year": [moment(startDate).subtract(1, "years"), moment(endDate)]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false
    }


    const columns = [
        {
            key: "f_name",
            text: "Borrower",
            TrOnlyClassName: 'cell',
            className: "cell",
            align: "left"
        },
        {
            key: "f_msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone",
            className: "cell",
            align: "left"
        },
        {
            key: "f_b_name",
            TrOnlyClassName: 'cell',
            text: "Branch Name",
            className: "cell",
            align: "left"
        },

        {
            key: "t_p",
            TrOnlyClassName: 'cell',
            text: "Loan Principal",
            className: "cell",
            align: "left"
        },
        {
            key: "a_m",
            TrOnlyClassName: 'cell',
            text: "Total Amount Paid",
            className: "cell",
            align: "left"
        },

        {
            key: "gross",
            TrOnlyClassName: 'cell',
            text: "Gross NetCashFlow",
            className: "cell",
            align: "left"
        },
        {
            key: "insurance_fee",
            TrOnlyClassName: 'cell',
            text: "Insurance Charge",
            className: "cell",
            align: "left"
        },

        {
            key: "a_net",
            TrOnlyClassName: 'cell',
            text: "NetCashflow",
            className: "cell",
            align: "left"
        },

        {
            key: "p_perfomance",
            TrOnlyClassName: 'cell',
            text: "% Performance",
            className: "cell",
            align: "left"
        },
        {
            key: "outstand_balance",
            TrOnlyClassName: 'cell',
            text: "OutStanding Balance",
            className: "cell",
            align: "left"
        },

        {
            key: "start_date",
            TrOnlyClassName: 'cell',
            text: "Loan Start Date",
            className: "cell",
            align: "left"
        },
        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <React.Fragment className="center" >
                        <button className="btn btn-primary btn-sm"
                            title="EditCategory"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { goCommission(record) }} >
                            {record.f_label}
                        </button>
                    </React.Fragment>
                );
            }
        }


    ];


    const goCommission = (record) => {

        if (record.f_label === "Commissions") {
            if (parseFloat(record.a_net) > 0) {
                setCommissionValue({
                    net_v: record.a_net,
                    branch_name: record.branch_name,
                    actual_netcahflow: record.actual_netcahflow,
                    period: datevalue
                })
                setGoCommission(true)

            } else {
                errorToast("No Commission! NetCashflow is less than zero")
            }
        }
        else {
            errorToast("Please click the right button")
        }
    }
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');

        setQueryString(queryString)

        setStartDate(moment(startDate))
        setEndDate(moment(endDate))
    }

    return (
        <div style={{ marginTop: "-20px" }} >
            < >

                {ToastTable()}
                < Card >
                    <CardBody >
                        {commission ?
                            navigate('/staff_disbursement', { state: commission_value, replace: true })
                            : null}
                        <div className="row">
                            <div className="col-md-6">
                                <h4> <b>Total NetCashFlow</b></h4>
                                <br />
                            </div>
                            <div className="col-6">
                                Refresh <button className="float-" title="refresh" style={{ background: "white", border: "0px" }}
                                    onClick={
                                        () => {
                                            setQueryString(require("randomstring").generate({
                                                length: 1,
                                                charset: 'alphanumeric',
                                                capitalization: 'lowercase'
                                            }))
                                        }} >
                                    <RefreshIcon />
                                </button>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-6">
                                            <DateTimeRangeContainer
                                                ranges={ranges}
                                                start={startDate}
                                                end={endDate}
                                                local={local}
                                                applyCallback={applyCallback}>
                                                <FormControl
                                                    id="formControlsTextB"
                                                    type="text"
                                                    value={datevalue}
                                                    label="Text"
                                                    placeholder="Filter by Date"
                                                />
                                            </DateTimeRangeContainer>
                                        </div>
                                        <div className="col-6 float-left">

                                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {

                                                const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));

                                                setDateValue("This Month")
                                                setStartDate(start)
                                                setEndDate(moment(new Date()))

                                            }} >Clear</button>
                                            {/* <input id="input" type="text" readOnly /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {/* <Link to="reports">
                                    <Button className="pull-right btn-sm" outline color="primary">
                                        Back to Repprts
                                    </Button>
                                </Link> */}

                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="panel-body" >

                            <ReactDatatable
                                config={config}
                                records={instalment}
                                columns={columns}
                                dynamic={true}
                                id="tsc"
                                loading={isLoading}
                                onChange={tableChangeHandler} />
                        </div>
                    </CardBody>


                </Card>
            </>
        </div>
    )

}
export default NetCashFlow;
