import React, { useState, Fragment, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, CONFIG, formatCurrency, ROLE, USER_ID } from '../../configs/exports';
import { useNavigate } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "../../_assets/css/csv.css";
import * as moment from 'moment';
import {
    Card, CardBody,
    Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'

// import EditApplicant from './EditApplicant';



const OverdueLoans = () => {

    const navigate = useNavigate()
    const [instalment, setInstalment] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")
    // const [applicsntdetails, setApplicantDetails] = useState(false)
    const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(moment(new Date()))
    const [datevalue, setDateValue] = useState("This Month")

    const [home, setLoanHome] = useState(false)

    const [expected_amount, setExpectedAmount] = useState(0);
    const [paid_amount, setPaidAmount] = useState(0);
    const [balance_amount, setBalanceAmount] = useState(0);


    const [farmerProps, setFarmerProps] = useState([])

    localStorage.setItem("STARTDATE", start)
    localStorage.setItem("ENDDATE", moment(new Date()))

    // eslint-disable-next-line
    const [activeTab, setActiveTab] = useState("1")

    // const [avatar, setAvatar] = useState("") // set profile picture
    useEffect(() => {
        let statment_url;
        if (activeTab === "1") {
            statment_url = baseURL + `loan_stages?period=2&lock_period=2&user_id=${USER_ID}&&role_id=${ROLE}&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;
        }
        // 30 days
        if (activeTab === "2") {
            statment_url = baseURL + `loan_stages?period=3&lock_period=4&user_id=${USER_ID}&&role_id=${ROLE}&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;
        }
        else if (activeTab === "3") {
            statment_url = baseURL + `loan_stages?period=4&lock_period=6&user_id=${USER_ID}&&role_id=${ROLE}&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;
        }
        else if (activeTab === "4") {
            statment_url = baseURL + `loan_stages?period=5&lock_period=8&user_id=${USER_ID}&&role_id=${ROLE}&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;
        }

        axios.all([
            axios.get(statment_url, CONFIG),
        ]).then(axios.spread((unmaturedResponse) => {
            let data = [];

            if (unmaturedResponse.data.data.length > 0) {
                setLoad(false)

                /**
                * @description If list is
                */
                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))

                let p = 0;
                if (id > 1) {
                    p = (((id - 1) * parseInt(params.get("page_size"))))
                }


                let valueExpected = 0
                let countExpected = 0


                let valuePaid = 0
                let countPaid = 0

                let valueBalance = 0
                let countBalance = 0

                for (let i = 0; i < unmaturedResponse.data.data.length; i++) {
                    p++;
                    let unrow = { row: p }
                    let date = { dates: (moment.utc(unmaturedResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };


                    let mexpected_amount, mpaid_amount, balance_amount, previous_instalment_balance, total_balance, mdays;
                    if (activeTab === "1") {

                        if ((parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                            +
                            parseFloat(unmaturedResponse.data.data[i].interest)
                            +
                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment)) > 0) {

                            mdays = { mdays: 60 - unmaturedResponse.data.data[i].days }

                            mexpected_amount = {
                                mexpected_amount:
                                    formatCurrency(
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                    )
                            };

                            countExpected = (
                                (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                +
                                parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075)
                                +
                                parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                            )
                            valueExpected += countExpected

                            mpaid_amount = {
                                mpaid_amount: formatCurrency(
                                    ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_installment))
                                    +
                                    (parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075) - parseFloat(unmaturedResponse.data.data[i].interest))

                                    +
                                    ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment))
                                )
                            };


                            countPaid = (
                                ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_installment))
                                +
                                (parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075) - parseFloat(unmaturedResponse.data.data[i].interest))

                                +
                                ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment))
                            )
                            valuePaid += countPaid


                            balance_amount = {
                                balance_amount: formatCurrency(
                                    parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].interest)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                )
                            };

                            countBalance = (
                                parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                            )
                            valueBalance += countBalance

                            total_balance = {
                                total_balance:
                                    formatCurrency(
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                        )
                                        + (
                                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                        )
                                    )
                            };

                        }


                    }
                    else if (activeTab === "2") {

                        if ((parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                            +
                            parseFloat(unmaturedResponse.data.data[i].interest_second)
                            +
                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                        ) > 0) {
                            mdays = { mdays: (90 - (unmaturedResponse.data.data[i].days)) - 60 }

                            mexpected_amount = {
                                mexpected_amount:
                                    formatCurrency(
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                        +
                                        parseFloat(((unmaturedResponse.data.data[i].expected_interest_second)))
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                        +
                                        (
                                            (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                        )
                                    )
                            };

                            countExpected = (
                                (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                +
                                parseFloat(((unmaturedResponse.data.data[i].expected_interest_second)))
                                +
                                parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                +
                                (
                                    (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                )
                            )
                            valueExpected += countExpected


                            mpaid_amount = {
                                mpaid_amount: formatCurrency(
                                    ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second))
                                    +
                                    (parseFloat(((unmaturedResponse.data.data[i].expected_interest_second))) - parseFloat(unmaturedResponse.data.data[i].interest_second))
                                    +
                                    ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second))
                                    +
                                    (
                                        ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_installment))
                                        +
                                        (parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075) - parseFloat(unmaturedResponse.data.data[i].interest))

                                        +
                                        ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment))
                                    )
                                )
                            };


                            countPaid = (
                                ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second))
                                +
                                (parseFloat(((unmaturedResponse.data.data[i].expected_interest_second))) - parseFloat(unmaturedResponse.data.data[i].interest_second))
                                +
                                ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second))
                                +
                                (
                                    ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_installment))
                                    +
                                    (parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075) - parseFloat(unmaturedResponse.data.data[i].interest))

                                    +
                                    ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment))
                                )
                            )
                            valuePaid += countPaid


                            balance_amount = {
                                balance_amount: formatCurrency(
                                    parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].interest_second)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                    +
                                    (
                                        parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                    )
                                )
                            };


                            countBalance = (
                                parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest_second)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                +
                                (
                                    parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].interest)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                )
                            )
                            valueBalance += countBalance



                            total_balance = {
                                total_balance:
                                    formatCurrency(
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                        )
                                        + (
                                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                        )
                                        +
                                        (
                                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest_third)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                                        )
                                    )
                            };
                        }
                    }

                    else if (activeTab === "3") {

                        mdays = { mdays: (90 - (unmaturedResponse.data.data[i].days)) - 90 }


                        if ((parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                            +
                            parseFloat(unmaturedResponse.data.data[i].interest_third)
                            +
                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third) > 0)) {
                            mdays = { mdays: unmaturedResponse.data.data[i].days }

                            mexpected_amount = {
                                mexpected_amount:
                                    formatCurrency(
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                        +
                                        (parseFloat(((unmaturedResponse.data.data[i].expected_interest_third))))
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                        +
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                        +
                                        parseFloat(((unmaturedResponse.data.data[i].expected_interest_second)))
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                        +
                                        (
                                            (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                        )
                                        + parseFloat(unmaturedResponse.data.data[i].expected_rollover)

                                    )
                            };
                            countExpected = (
                                (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                +
                                (parseFloat(((unmaturedResponse.data.data[i].expected_interest_third))))
                                +
                                parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                +
                                (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                +
                                parseFloat(((unmaturedResponse.data.data[i].expected_interest_second)))
                                +
                                parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                +
                                (
                                    (parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].total_amount) / 3
                                )
                                + parseFloat(unmaturedResponse.data.data[i].expected_rollover)


                            )
                            valueExpected += countExpected



                            mpaid_amount = {
                                mpaid_amount: formatCurrency(
                                    ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third))
                                    +
                                    (parseFloat(unmaturedResponse.data.data[i].expected_interest_third) - parseFloat(unmaturedResponse.data.data[i].interest_third))
                                    +
                                    ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third))
                                    +
                                    (
                                        ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second))
                                        +
                                        (parseFloat(((unmaturedResponse.data.data[i].expected_interest_second))) - parseFloat(unmaturedResponse.data.data[i].interest_second))
                                        +
                                        ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second))
                                        +
                                        (
                                            ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_installment))
                                            +
                                            (parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075) - parseFloat(unmaturedResponse.data.data[i].interest))

                                            +
                                            ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment))
                                        )
                                    )
                                    + (parseFloat(unmaturedResponse.data.data[i].expected_rollover) -
                                        parseFloat(unmaturedResponse.data.data[i].rollover_fee)
                                    )

                                )
                            };

                            countPaid = (
                                ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third))
                                +
                                (parseFloat(unmaturedResponse.data.data[i].expected_interest_third) - parseFloat(unmaturedResponse.data.data[i].interest_third))
                                +
                                ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third))
                                +
                                (
                                    ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second))
                                    +
                                    (parseFloat(((unmaturedResponse.data.data[i].expected_interest_second))) - parseFloat(unmaturedResponse.data.data[i].interest_second))
                                    +
                                    ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second))
                                    +
                                    (
                                        ((parseFloat(unmaturedResponse.data.data[i].insurance_fee) / 3) - parseFloat(unmaturedResponse.data.data[i].insurance_installment))
                                        +
                                        (parseFloat(unmaturedResponse.data.data[i].total_amount * 0.075) - parseFloat(unmaturedResponse.data.data[i].interest))

                                        +
                                        ((parseFloat(unmaturedResponse.data.data[i].total_amount) / 3) - parseFloat(unmaturedResponse.data.data[i].monthly_instalment))
                                    )

                                    + (parseFloat(unmaturedResponse.data.data[i].expected_rollover) -
                                        parseFloat(unmaturedResponse.data.data[i].rollover_fee)
                                    )
                                )
                            )


                            balance_amount = {
                                balance_amount: formatCurrency(
                                    parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].interest_third)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                                    +
                                    (
                                        parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest_second)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                        +
                                        (
                                            parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                        )
                                        + parseFloat(unmaturedResponse.data.data[i].rollover_fee)

                                    )
                                )
                            };


                            countBalance = (
                                parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest_third)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                                +
                                (
                                    parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].interest_second)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                    +
                                    (
                                        parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                    )
                                    + parseFloat(unmaturedResponse.data.data[i].rollover_fee)

                                )
                            )
                            valueBalance += countBalance

                            previous_instalment_balance = {
                                previous_instalment_balance:
                                    formatCurrency(
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                        )
                                        + (
                                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)

                                            + parseFloat(unmaturedResponse.data.data[i].rollover_fee)

                                        )

                                    )


                            };

                            total_balance = {
                                total_balance:
                                    formatCurrency(
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                        )
                                        + (
                                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                        )
                                        +
                                        (
                                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest_third)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].rollover_fee)

                                        )

                                    )
                            };
                        }

                    }


                    else if (activeTab === "4") {

                        if (parseFloat(unmaturedResponse.data.data[i].rollover_fee) > 0) {

                            mdays = { mdays: (unmaturedResponse.data.data[i].days) }

                            // mdays = { mdays: unmaturedResponse.data.data[i].days }

                            mexpected_amount = {
                                mexpected_amount:
                                    formatCurrency(
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_fee))
                                        +
                                        (parseFloat(unmaturedResponse.data.data[i].expected_interest_first))

                                        +
                                        (parseFloat(unmaturedResponse.data.data[i].expected_interest_third))

                                        +
                                        (parseFloat(unmaturedResponse.data.data[i].expected_interest_second))

                                        +
                                        parseFloat(unmaturedResponse.data.data[i].total_amount)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].expected_rollover)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].expected_debt_collection)
                                    )
                            };
                            countExpected = (
                                (parseFloat(unmaturedResponse.data.data[i].insurance_fee))
                                +
                                (parseFloat(unmaturedResponse.data.data[i].expected_interest_first))

                                +
                                (parseFloat(unmaturedResponse.data.data[i].expected_interest_third))

                                +
                                (parseFloat(unmaturedResponse.data.data[i].expected_interest_second))

                                +
                                parseFloat(unmaturedResponse.data.data[i].total_amount)
                                +
                                parseFloat(unmaturedResponse.data.data[i].expected_rollover)
                                +
                                parseFloat(unmaturedResponse.data.data[i].expected_debt_collection)
                            )
                            valueExpected += countExpected

                            mpaid_amount = {
                                mpaid_amount: formatCurrency(
                                    (
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_fee))
                                        +

                                        (parseFloat(unmaturedResponse.data.data[i].expected_interest_first))

                                        +
                                        (parseFloat(unmaturedResponse.data.data[i].expected_interest_third))

                                        +
                                        (parseFloat(unmaturedResponse.data.data[i].expected_interest_second))
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].total_amount)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].expected_rollover)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].expected_debt_collection)
                                    )
                                    -
                                    (
                                        parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest_third)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest_second)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].interest)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].rollover_fee)
                                        +
                                        parseFloat(unmaturedResponse.data.data[i].debt_collection)
                                    )
                                )
                            };


                            countPaid = (
                                (
                                    (parseFloat(unmaturedResponse.data.data[i].insurance_fee))
                                    +
                                    (parseFloat(unmaturedResponse.data.data[i].expected_interest_first))

                                    +
                                    (parseFloat(unmaturedResponse.data.data[i].expected_interest_third))

                                    +
                                    (parseFloat(unmaturedResponse.data.data[i].expected_interest_second))
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].total_amount)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].expected_rollover)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].expected_debt_collection)
                                )
                                -
                                (
                                    parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].interest_third)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].interest_second)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].interest)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].rollover_fee)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].debt_collection)
                                )
                            )
                            valuePaid += countPaid

                            balance_amount = {
                                balance_amount: formatCurrency(
                                    parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].interest_third)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].interest_second)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].interest)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].rollover_fee)
                                    +
                                    parseFloat(unmaturedResponse.data.data[i].debt_collection)
                                )
                            };


                            countBalance = (
                                parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest_third)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                                +
                                parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest_second)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                +
                                parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                +
                                parseFloat(unmaturedResponse.data.data[i].interest)
                                +
                                parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                +
                                parseFloat(unmaturedResponse.data.data[i].rollover_fee)
                                +
                                parseFloat(unmaturedResponse.data.data[i].debt_collection)
                            )
                            valueBalance += countBalance

                            previous_instalment_balance = {
                                previous_instalment_balance:
                                    formatCurrency(
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                        )
                                        +
                                        (
                                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                        )
                                        +
                                        (
                                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                                        )
                                    )
                            };

                            total_balance = {
                                total_balance:
                                    formatCurrency(
                                        (parseFloat(unmaturedResponse.data.data[i].insurance_installment)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment)
                                        )
                                        + (
                                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest_second)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_second)
                                        )
                                        +
                                        (
                                            parseFloat(unmaturedResponse.data.data[i].insurance_instalment_third)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].interest_third)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].monthly_instalment_third)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].rollover_fee)
                                            +
                                            parseFloat(unmaturedResponse.data.data[i].expected_debt_collection)

                                        )
                                    )
                            };
                        }
                    }




                    data.push(Object.assign(date,
                        date,
                        mdays,
                        mexpected_amount,
                        mpaid_amount,
                        balance_amount,
                        previous_instalment_balance,
                        total_balance,
                        unrow,
                        unmaturedResponse.data.data[i]));
                    setLoad(false)
                }

                // eslint-disable-next-line


                console.log("Check null", data);

                setInstalment((data.filter(person => person.mdays !== undefined)).filter(person => parseFloat(person.balance_amount) > 0));
                setDataTotal(unmaturedResponse.data.total);

                setExpectedAmount(valueExpected)
                setPaidAmount(valuePaid)
                setBalanceAmount(valueBalance)

            } else {
                setLoad(false)
                setInstalment(data)
            }
        })).catch(error => {
            // alert(JSON.stringify(error.response))
        })

    }, [startDate, queryString, endDate, activeTab]);

    // const locale = 'en-US';
    // const formatCurrency = amount => {
    //     return new Intl.NumberFormat(locale, {
    //         style: 'currency',
    //         currency: "ABS",
    //         minimumFractionDigits: 2,
    //         maximumFractionDigits: 2
    //     }).format(amount).replaceAll('ABS', "");
    // };




    // go home
    const loanHome = (record) => {
        // alert(JSON.stringify(record))
        setLoanHome(true)
        setFarmerProps(record);

    }
    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true,
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here",
            no_data_text: "There was no data this month",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    const applyCallback = (startDate, endDate) => {
        const Sdate1 = moment(startDate).format('DD MMM, YYYY');
        const Edate2 = moment(endDate).format('DD MMM, YYYY');
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)
        setDateValue(Sdate1 + " " + Edate2)
        setStartDate(startDate)
        setEndDate(endDate)
    }


    const ranges = {
        "Today Only": [moment(startDate), moment(endDate)],
        "Yesterday Only": [
            moment(startDate).subtract(1, "days"),
            moment(endDate).subtract(1, "days")
        ],
        "3 Days": [moment(startDate).subtract(3, "days"), moment(endDate)],
        "5 Days": [moment(startDate).subtract(5, "days"), moment(endDate)],
        "1 Week": [moment(startDate).subtract(7, "days"), moment(endDate)],
        "2 Weeks": [moment(startDate).subtract(14, "days"), moment(endDate)],
        "1 Month": [moment(startDate).subtract(1, "months"), moment(endDate)],
        "1 Year": [moment(startDate).subtract(1, "years"), moment(endDate)]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false
    }

    const columns = [
        // {
        //     key: "row",
        //     TrOnlyClassName: 'cell',
        //     text: "#",
        //     className: "cell",
        //     align: "left"
        // },
        {
            key: "name",
            TrOnlyClassName: 'cell',
            text: "Name",
            className: "cell",
            align: "left"
        },
        {
            key: "msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone",
            className: "cell",
            align: "left"
        },

        {
            key: "action",
            text: "Period",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    // eslint-disable-next-line
                    <Fragment className="center" >
                        {
                            <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                Overdue by {Math.abs(record.mdays)} days
                            </span>
                        }
                    </Fragment>
                );
            }
        },

        {
            key: "mexpected_amount",
            TrOnlyClassName: 'cell',
            text: "Total Amount",
            className: "cell",
            align: "left"
        },
        {
            key: "mpaid_amount",
            TrOnlyClassName: 'cell',
            text: "Total Paid Amount",
            className: "cell",
            align: "left"
        },
        {
            key: "action",
            text: "Current Instalment Balance",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    // eslint-disable-next-line
                    <Fragment className="center" >
                        {
                            <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                {record.balance_amount}
                            </span>
                        }
                    </Fragment>
                );
            }
        },

        {
            key: "field_officer",
            TrOnlyClassName: 'cell',
            text: "Field Officer",
            className: "cell",
            align: "left"
        },
        {
            key: "branch_name",
            TrOnlyClassName: 'cell',
            text: "Branch",
            className: "cell",
            align: "left"
        },

        {
            key: "start_date",
            TrOnlyClassName: 'cell',
            text: "Loan Start Date",
            className: "cell",
            align: "left"
        },
        {
            key: "action",
            text: "Actions",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >



                        <button className="btn btn-primary btn-sm"
                            title="View pics Details"
                            style={
                                { marginRight: '10px' }}

                            onClick={() => { loanHome(record) }}>
                            Record Call
                        </button>

                    </Fragment >
                );
            }
        },

    ];
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');

        setQueryString(queryString)

        setStartDate(moment(startDate))
        setEndDate(moment(endDate))
    }

    const toggle = (activeTab) => {

        setActiveTab(activeTab)

    }


    // const rowClickedHandler = (data) => {

    //     // alert(JSON)
    //     setFarmerProps(data);
    //     setApplicantDetails(true)

    // };

    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >

            {home === true ?
                navigate('/view_call_logs', { state: farmerProps, replace: true }) : null}



            {/* {
                applicsntdetails === true ? <Navigate to={{
                    pathname: '/farmer_details',
                    state: {
                        farmer: farmerProps
                    }
                }} /> : null} */}


            <Card>
                <CardBody >
                    <div className="tabs tabs--bordered-top" >
                        <div className="tabs__wrap">
                            <h4> <b>Overdue Loans</b></h4>
                            <br />

                            {isLoading === false ?
                                <>
                                    <div>
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => {
                                                        toggle('1');
                                                    }}
                                                >
                                                    In 2nd Instalment
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '2' })}
                                                    onClick={() => {
                                                        toggle('2');
                                                    }}
                                                >
                                                    In 3rd Instalment
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '3' })}
                                                    onClick={() => {
                                                        toggle('3');
                                                    }}
                                                >
                                                    In Rollover
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '4' })}
                                                    onClick={() => {
                                                        toggle('4');
                                                    }}
                                                >
                                                    In Debt Collection
                                                </NavLink>
                                            </NavItem>

                                        </Nav>

                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="col-md-8">

                                                    <br />
                                                    <div className="row">

                                                        <div className="col-6">

                                                            <DateTimeRangeContainer
                                                                ranges={ranges}
                                                                start={startDate}
                                                                end={endDate}
                                                                local={local}
                                                                applyCallback={applyCallback}>
                                                                <FormControl
                                                                    id="formControlsTextB"
                                                                    type="text"
                                                                    value={datevalue}
                                                                    label="Text"
                                                                    placeholder="Filter by Date"
                                                                />
                                                            </DateTimeRangeContainer>
                                                        </div>
                                                        <div className="col-6 float-left">

                                                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {

                                                                const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));

                                                                setDateValue("This Month")
                                                                setStartDate(start)
                                                                setEndDate(moment(new Date()))

                                                            }} >Clear</button>
                                                            {/* <input id="input" type="text" readOnly /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                {/* <Link to="reports">
                                    <Button className="pull-right btn-sm" outline color="primary">
                                        Back to Repprts
                                    </Button>
                                </Link> */}

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <br />

                                                <h4> <b>Total Expected  Amount: </b> {formatCurrency(expected_amount)}</h4>
                                                <h4>Total Paid Amount: <b style={{ color: "green" }}>{formatCurrency(Math.abs(paid_amount))}</b></h4>

                                                <h4>Total Overdue Amount: <b style={{ color: "red" }}>{formatCurrency(Math.abs(balance_amount))}</b></h4>


                                            </div>
                                            <div className="col-6">
                                                <br />


                                            </div>
                                        </div>

                                        <br />



                                        <ReactDatatable
                                            config={config}
                                            records={instalment}
                                            columns={columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={isLoading}
                                            total_record={datatotal}
                                            onChange={tableChangeHandler} />
                                    </div>

                                </>

                                :
                                <div className="text-center h-50">
                                    <Loader
                                        type="Puff"
                                        color="#00BFFF"
                                        height={50}
                                        width={50} //3 secs
                                    />
                                </div>
                            }


                        </div>
                    </div>
                </CardBody>

            </Card>


        </div>
    );
}

export default OverdueLoans;
