import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Farmers from '../../../Farmers/Farmer';
import FarmerDetails from '../../../Farmers/FarmerDetails';
import Loan from '../../../Loan/Loan';

import AddCustomer from '../../../Loan/addLoan';
import UpdateLoan from '../../../Loan/updateLoan';
import AddMoreCows from '../../../Loan/AddMoreCows';

// import Applicants from '../../../Applicants/Applicant';
// import ApplicantDetails from '../../../Applicants/ApplicantDetails';
// import ApplicantPay from '../../../Applicants/ApplicantPay';
// import ApplicantSwitch from '../../../Applicants/ApplicantSwitch';
import Layout from '../../../Layout/index';
import Livestock from '../../../Livestock/Livestock';
import SMSLogs from '../../../SMSLogs/SMSlogs';

import Charges from '../../../SMSLogs/Charges.jsx';

import WithdrawRequests from '../../../Farmers/WithdrawRequests';
import WithdrawArchive from '../../../Farmers/WithdrawArchive';
import Statement from '../../../Loan/Statement';
import DepositArchives from '../../../Farmers/Deposits';
import SubmitLoanStatus from '../../../Loan/SubmitLoanStatus';
import Default from '../../../Dashboards/Default';
import { UserSearch } from '../../../Farmers/usersearch';
import { SubmitLoanAuctioning } from '../../../Loan/Actioning';
import LoanAuction from '../../../Loan/LoanAuctioning';
import UsersStaffs from '../../../Users/UsersStaffs';
import addOtherDocuments from '../../../Farmers/addOtherDocuments';
import FirstInstalment from '../../../Reports/1st_Instalment';
import SecondInstalment from '../../../Reports/2nd_Instalment';
import ThirdInstalment from '../../../Reports/3rd_instalment';
import Rollover from '../../../Reports/Rollover';
import DebtCollection from '../../../Reports/DebtCollection';
import GlobalAnalysis from '../../../Reports/GlobalAnalysis';
import ImageView from '../../../../shared/components/catalog/imageView';
import UnmatureLoans from '../../../Reports/UnmaturedLoans';
import OverdueLoans from '../../../Reports/OverdueLoans';
import FarmerLogs from '../../../Farmers/FarmerLogs';
import FarmerDeposits from '../../../Farmers/FarmerDeposit';
import FarmerWithdrawals from '../../../Farmers/FarmerWithdrawal';
import { CallLogs } from '../../../Reports/CallLogs';
import ViewCallLogs from '../../../Reports/ViewCallLogs';

import FileUploadDefault from '../../../CSV/components/FileUploadDefault';
import SuspendAccount from '../../../Reports/SuspendAccount';
import RejectedLoans from '../../../Reports/RejectedLoans';
import SummaryReport from '../../../Reports/SummaryReport';
import LoanReport from '../../../Reports/LoanReport';

import InterestReport from '../../../Reports/InterestInsurance';


import DetailLoanReport from '../../../Reports/DetailLoanReport';
import NetCashFlow from '../../../Reports/NetCashFlow';
import DetailNetCashFlow from '../../../Reports/DetailNetCashFlow';
import Disbursement from '../../../Loan/Disbursement';
import AmountPaid from '../../../Reports/AmountPaid';
import Commission from '../../../Reports/Commissions';
import VetReports from '../../../Reports/VetReports';
import AddCharges from '../../../Loan/AddCharges';
import PredictionDue from '../../../Reports/PredictionDue';
/// import EditApplicant from '../../../Applicants/EditApplicant';


export default () => (
  <div>
    <Layout />
    <div className="container__wrap">

      <Routes>
        <Route path="/find_farmer" element={<UserSearch />} />

        <Route path="/farmer" element={<Farmers />} />

        <Route path="/addloanmorecows" element={<AddMoreCows />} />
        <Route path="/farmer_details" element={<FarmerDetails />} />

        <Route path="/loan" element={<Loan />} />

        <Route path="/livestock" element={<Livestock />} />

        <Route path="/sms_log" element={<SMSLogs />} />

        <Route path="/withdraw_request" element={<WithdrawRequests />} />

        <Route path="/withdraw_archive" element={<WithdrawArchive />} />


        <Route path="/staff_disbursement" element={< Disbursement />} />

        <Route path="/amount_paid" element={<AmountPaid />} />

        <Route path="/commission" element={<Commission />} />

        <Route path="/loan_issued" element={<VetReports />} />




        <Route path="/statement" element={<Statement />} />

        <Route path="/deposits" element={<DepositArchives />} />

        <Route path="/loan_status" element={<SubmitLoanStatus />} />

        <Route path="/dashboard" element={<Default />} />


        <Route path="/laon_action" element={<LoanAuction />} />

        <Route path="/actioning" element={<SubmitLoanAuctioning />} />

        <Route path="/charges" element={<Charges />} />


        <Route path="/reconciliation_uploads" element={<FileUploadDefault />} />
        <Route path="/staffs" element={<UsersStaffs />} />

        <Route path="/add_loan" element={<AddCustomer />} />

        <Route path="/add_document" element={<addOtherDocuments />} />

        <Route path="/first_instalment" element={<FirstInstalment />} />

        <Route path="/second_instalment" element={<SecondInstalment />} />

        <Route path="/third_instalment" element={<ThirdInstalment />} />

        <Route path="/rollover" element={<Rollover />} />

        <Route path="/debt_collection" element={<DebtCollection />} />

        <Route path="/global_analysis" element={<GlobalAnalysis />} />

        <Route path="/photo_view" element={<ImageView />} />

        <Route path="/unmatured" element={<UnmatureLoans />} />

        <Route path="/overdueloan" element={<OverdueLoans />} />

        <Route path="/farmer_logs" element={<FarmerLogs />} />
        <Route path="/call_logs" element={<CallLogs />} />


        <Route path="/farmer_deposit" element={<FarmerDeposits />} />
        <Route path="/farmer_withdrawal" element={<FarmerWithdrawals />} />

        <Route path="/view_call_logs" element={<ViewCallLogs />} />

        <Route path="/suspend_account" element={<SuspendAccount />} />


        <Route path="/rejected_loans" element={<RejectedLoans />} />

        <Route path="/amend_loan" element={<UpdateLoan />} />


        <Route path="/summary_report" element={<SummaryReport />} />
        <Route path="/loan_report" element={<LoanReport />} />
        <Route path="/detail_loan_report" element={<DetailLoanReport />} />


        <Route path="/interest_report" element={<InterestReport />} />
        <Route path="/netcashflow" element={<NetCashFlow />} />

        <Route path="/detail_net" element={<DetailNetCashFlow />} />

        <Route path="/add_charge" element={<AddCharges />} />

        <Route path="/prediction_due" element={<PredictionDue />}/>

      </Routes>

      {/* <Route path="/edit_applicant" element={<EditApplicant/>} /> */}


      {/* <Route path="/applicant_details" element={<ApplicantDetails/>} />

     
      <Route path="/applicant" element={<Applicants/>} />

      
      <Route path="/applicant_records" element={<ApplicantPay/>} />

      <Route path="/applicant_switch" element={<ApplicantSwitch/>} />

      <Route path="/add_applicant" element={<AddApplicant/>} />

     
      <Route path="/edit_profile" element={<EditApplicant/>} />

      
 */}


    </div>
  </div>
);
