import React, { useState, Fragment, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';

import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, CONFIG, ROLE, formatCurrency, USER_ID } from '../../configs/exports';
import { useNavigate } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "../../_assets/css/csv.css";
import ProductItems from '../../shared/components/catalog/ProductItems';
import * as moment from 'moment';
import {
    Card, CardBody, Row, Col, Button,
    Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import classnames from 'classnames';

// import EditApplicant from './EditApplicant';



const Livestock = () => {
    const navigate = useNavigate();

    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")
    const [livestock, setLiveStock] = useState([])
    // eslint-disable-next-line
    const [activeTab, setActiveTab] = useState("1")
    const [livestockDetail, setLivestockDetail] = useState(false)
    const [oneLiveStock, setLivestockOne] = useState()
    // eslint-disable-next-line
    const [mloan_issued, setLoanIssued] = useState(0)
    const [loanhome, setLoanHome] = useState(false)
    const [goloan, setGoHome] = useState([])
    const [loan_limit, setLoanLimit] = useState(0)

    // eslint-disable-next-line
    const [farmerProps, setFarmerProps] = useState([])
    // eslint-disable-next-line
    const [applicsntdetails, setApplicantDetails] = useState(false)

    const [total_disbursed, setDisbursed] = useState(0)
    const [total_issured, setTotalIssured] = useState(0)

    const [total_count, setTotalCount] = useState(0)

    // const [avatar, setAvatar] = useState("") // set profile picture
    useEffect(() => {
        // consuming API based on active tab

        let livestock_url = baseURL + "livestock_loans?loan_status=" + activeTab + `&user_id=${USER_ID}&&role_id=${ROLE}&` + queryString;


        // alert(livestock_url)
        // var val = Math.floor(1000 + Math.random() * 9000);
        // tab 1 API call...
        axios.all([
            axios.get(livestock_url, CONFIG),
        ]).then(axios.spread((livestockResponse) => {
            let data = [];

            if (livestockResponse.data.data.length > 0) {
                setLoad(false)
                // let valueAddedAmount = 0
                // let countAmount = 0

                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))
                let p = livestockResponse.data.total + 1;
                if (id > 1) {
                    p = (parseInt(livestockResponse.data.total) - ((id - 1) * parseInt(params.get("page_size")))) + 1
                }
                let valueExpected = 0
                let countExpected = 0

                let valueCollected = 0
                let countCollected = 0

                let valueCount = 0
                let countCount = 0


                for (let i = 0; i < livestockResponse.data.data.length; i++) {
                    const diffInDays = moment(livestockResponse.data.data[i].due_date).diff(moment(Date.now()), 'days');
                    p--;
                    let unrow = { row: p }
                    let unrow_loan = { row_loan: "LN " + p }


                    countCount = 1
                    valueCount += countCount //count loans issued


                    countExpected = (livestockResponse.data.data[i].amount_disbursed)
                    valueExpected += countExpected //expected

                    countCollected = ((livestockResponse.data.data[i].amount_disbursed) + 1133)
                    valueCollected += countCollected //collected

                    // format amount
                    let date = { dates: (moment.utc(livestockResponse.data.data[i].start_date).format('DD/MM/YYYY HH:mm:ss')) };
                    let f_amo;
                    f_amo = { f_mo: formatCurrency(parseFloat(livestockResponse.data.data[i].amount_disbursed).toFixed(2)) };  // loan amount amount sent

                    let period = { period: Number(diffInDays) };
                    let mloan_approved = { mloan_approved: formatCurrency(parseFloat(livestockResponse.data.data[i].amount_disbursed) + 1133) };
                    data.push(Object.assign(date,
                        period, f_amo,
                        date,
                        unrow_loan,
                        unrow,
                        mloan_approved,
                        livestockResponse.data.data[i]));
                    setLoad(false)
                }

                setTotalCount(valueCount)
                setDisbursed(formatCurrency(valueExpected))

                setTotalIssured(formatCurrency(valueCollected))
                setLoanIssued(formatCurrency(livestockResponse.data.loan_issued))
                // setApproved(formatCurrency(livestockResponse.data.approved_loan))
                setDataTotal(livestockResponse.data.total)
                setLoanLimit(livestockResponse.data.loan_applicable)

                // eslint-disable-next-line
                setLiveStock((data.filter(person => person.status != "Rejected")).filter(person => person.status != "Variete"));

            } else {
                setLoad(false)
                setLiveStock(data)
            }
        })).catch(error => {
            // alert(JSON.stringify(error.response))
        })

    }, [activeTab, queryString]);


    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }


    //livestock list

    const columns_livestock = [

        // {
        //     key: "row",
        //     TrOnlyClassName: 'cell',
        //     text: "#",
        //     className: "cell",
        //     align: "left"
        // },
        // {
        //     key: "action",
        //     text: "Borrower",
        //     TrOnlyClassName: 'cell',
        //     className: "cell",
        //     width: 250,
        //     sortable: false,
        //     cell: record => {
        //         return (
        //             // eslint-disable-next-line
        //             <Fragment className="center" >
        //                 <p href="#"
        //                     onClick={() => { rowClickedHandler(record) }}
        //                     style={{ color: "blue", marginRight: '10px' }}>
        //                     {record.name}
        //                 </p>

        //             </Fragment>
        //         );
        //     }
        // }
        {
            key: "name",
            TrOnlyClassName: 'cell',
            text: "Borrower",
            className: "cell",
            align: "left"
        },

        {
            key: "row_loan",
            TrOnlyClassName: 'cell',
            text: "Loan ID",
            className: "cell",
            align: "left"
        },



        {
            key: "reference",
            TrOnlyClassName: 'cell',
            text: "Ref",
            className: "cell",
            align: "left"
        },
        {
            key: "vet_name",
            TrOnlyClassName: 'cell',
            text: "Vet Name",
            className: "cell",
            align: "left"
        },

        {
            key: "branch_name",
            TrOnlyClassName: 'cell',
            text: "Branch Name",
            className: "cell",
            align: "left"
        },


        {
            key: "action",
            text: "Loan Status",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >
                        {record.completion_status === 2 ?
                            <div>
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Fully Paid
                                </span>
                            </div>
                            :
                            <>
                                {record.loan_status === 0 ?
                                    <div>
                                        <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Not Applied
                                        </span>
                                    </div>
                                    : null}
                                {record.loan_status === 5 ?
                                    <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                        Waiting Admin Approval
                                    </span>
                                    : null
                                }
                                {record.loan_status === 6 ?
                                    <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                        Waiting Final Admin Approval
                                    </span>
                                    : null
                                }
                                {
                                    record.loan_status === 3 ?
                                        <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Waiting Loan Officer Approval
                                        </span>
                                        : null
                                }

                                {
                                    record.loan_status === 1 ?
                                        <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Waiting Agent Approval
                                        </span>
                                        : null
                                }

                                {
                                    record.loan_status === 11 ?
                                        <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Waiting Customer Approval
                                        </span>
                                        : null
                                }

                                {record.loan_status === 9 ?
                                    <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                        Waiting Money Disbursement
                                    </span>
                                    : null
                                }


                                {
                                    record.loan_status === 2 ?
                                        <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Approved
                                        </span>
                                        : null
                                }
                            </>
                        }
                    </Fragment >
                );
            }
        },

        {
            key: "f_mo",
            TrOnlyClassName: 'cell',
            text: "Loan Issued",
            className: "tsc",
            align: "left"
        },
        {
            key: "mloan_approved",
            TrOnlyClassName: 'cell',
            text: "Loan Approved",
            className: "tsc",
            align: "left"
        },
        {
            key: "dates",
            TrOnlyClassName: 'tsc',
            text: "Start Date",
            className: "cell",
            align: "left"
        },
        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >

                        <button className="btn btn-primary btn-sm"
                            title="View pics Details"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { livestockMap(record) }} >
                            Location
                        </button>
                        <button className="btn btn-primary btn-sm"
                            title="View pics Details"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { livestockDetails(record) }} >
                            View Pictures
                        </button>
                        {record.complete === 1 && (record.loan_status === 1 || record.loan_status === 2 || record.loan_status === 9 || record.loan_status === 3 || record.loan_status === 5 || record.loan_status === 11 || record.loan_status === 6) ?
                            <button className="btn btn-success btn-sm"
                                title="View profile"
                                style={
                                    { marginRight: '10px' }}
                                onClick={() => { loanHome(record) }} >
                                Loan Profile
                            </button>
                            :
                            null
                        }

                    </Fragment>
                );
            }
        }
    ];


    // alert(TOKEN)
    // const rowClickedHandler = (data) => {

    //     // alert(JSON)
    //     setFarmerProps(data);
    //     setApplicantDetails(true)

    // };
    // handles paginations
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column")
                    + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }


    const map = () => {
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${oneLiveStock.latitude}%2C${oneLiveStock.longitude}`, '_blank');
        // setHome(true)
    }


    const toggle = (activeTab) => {
        setActiveTab(activeTab)
    }

    const livestockDetails = (record) => {
        setLivestockDetail(true)
        setLivestockOne(record)

    }

    const loanHome = (record) => {
        // alert(JSON.stringify(record))
        setLoanHome(true)
        setGoHome(record)

    }


    const livestockMap = (record) => {
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${record.latitude}%2C${record.longitude}`, '_blank');
    }

    const backLivestock = () => {
        setLivestockDetail(false)
    }

    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >


            {loanhome === true ?

                navigate('/loan', {
                    state: {
                        livestock_id: goloan.id,
                        applicant_name: goloan.name,
                        applicant_id_no: goloan.id_no,
                        applicant_tag_id: goloan.tag_id,
                        applicant_msisdn: goloan.msisdn,
                        passport_photo: goloan.passport_photo,
                        mloan_status: goloan.loan_status,
                        next_to_cow: goloan.next_to_cow,

                        id_front_photo: goloan.id_front_photo,
                        applicant_loan_applicable: loan_limit,

                        applicant_ngomnbe_value: goloan.value,
                        array_2: goloan

                    }, replace: true
                })
                : null}

            {
                applicsntdetails === true ?


                    navigate('/farmer_details', {
                        state: {
                            id: farmerProps.farmer_id,
                            passport_photo: farmerProps.passport_photo,
                            name: farmerProps.name,
                            id_no: farmerProps.id_no,
                            latitude: farmerProps.latitude,
                            longitude: farmerProps.longitude,
                            next_to_cow: farmerProps.next_to_cow
                        }, replace: true
                    })
                    : null}

            <Card>
                <CardBody >
                    <br />
                    <div className="tabs tabs--bordered-top" >
                        <div className="tabs__wrap">

                            {isLoading === false ?
                                <>


                                    {livestockDetail ?
                                        <>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h4><b>Ng'ombe Photos</b></h4>

                                                    <div className="profile__information">
                                                        <div className="profile__avatar">
                                                            <img src={oneLiveStock.passport_photo} alt="Passport" />
                                                        </div>
                                                        <div className="profile__data">
                                                            <br />
                                                            <p className="profile__name" style={{ marginBottom: "10px" }}>Farmer Name: {oneLiveStock.name}</p>
                                                            <p className="profile__name" style={{ marginBottom: "10px" }}><br />
                                                                <Row>
                                                                    <Button className="pull- btn-sm" onClick={map} outline color="primary">

                                                                        Find in Map
                                                                    </Button>

                                                                    {livestockDetail ?
                                                                        <>
                                                                            <a href={oneLiveStock.insurance_url}>
                                                                                <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                                    Insurance Form
                                                                                </Button>
                                                                            </a>&nbsp;&nbsp;

                                                                            <a href={oneLiveStock.vet_valuation_form} >
                                                                                <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                                    Vet Valuation Form
                                                                                </Button>
                                                                            </a>&nbsp;&nbsp;

                                                                            <a href={oneLiveStock.vacination_regime_report}>
                                                                                <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                                    Vacination Regime
                                                                                </Button>
                                                                            </a>
                                                                        </>
                                                                        : null}
                                                                </Row>

                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <Button className="pull-right btn-sm" onClick={backLivestock} outline color="primary">
                                                        Swtich back to livestock
                                                    </Button>
                                                </div>
                                            </div>

                                            <Row>
                                                <Col md={12} lg={12}>
                                                    <ProductItems items={
                                                        [
                                                            {
                                                                id: 1,
                                                                src: `${oneLiveStock.logo_url}`,
                                                                title: 'Photo 1',
                                                                colors: ['#00b3c6', '#50e3c2', '#fa4a86'],
                                                            },
                                                            {
                                                                id: 2,
                                                                src: `${oneLiveStock.logo_url_2}`,
                                                                title: 'Photo 2',
                                                                colors: ['#d4d4d4', '#5ff4d3', '#f7a9c4'],
                                                            },
                                                            {
                                                                id: 3,
                                                                src: `${oneLiveStock.logo_url_3}`,
                                                                title: 'Photo 3',
                                                                colors: ['#fa93bd'],
                                                            }
                                                        ]
                                                    } />
                                                </Col>
                                            </Row>

                                        </>
                                        :
                                        <div>
                                            <Nav tabs>
                                                {/* <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '0' })}
                                                        onClick={() => {
                                                            toggle('0');
                                                        }}
                                                    >
                                                        Not Applied
                                                    </NavLink>
                                                </NavItem> */}

                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '1' })}
                                                        onClick={() => {
                                                            toggle('1');
                                                        }}
                                                    >
                                                        Agent Approvals
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '3' })}
                                                        onClick={() => {
                                                            toggle('3');
                                                        }}
                                                    >
                                                        Loan Officer Approvals
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '5' })}
                                                        onClick={() => {
                                                            toggle('5');
                                                        }}
                                                    >
                                                        Admin Approvals
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '6' })}
                                                        onClick={() => {
                                                            toggle('6');
                                                        }}
                                                    >
                                                        Final Admin Approvals
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '11' })}
                                                        onClick={() => {
                                                            toggle('11');
                                                        }}
                                                    >
                                                        Customer Approval
                                                    </NavLink>
                                                </NavItem>

                                                {/* <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '9' })}
                                                        onClick={() => {
                                                            toggle('9');
                                                        }}
                                                    >
                                                        Money Disbursement
                                                    </NavLink>
                                                </NavItem> */}
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '2' })}
                                                        onClick={() => {
                                                            toggle('2');
                                                        }}
                                                    >
                                                        Processed
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '22' })}
                                                        onClick={() => {
                                                            toggle('22');
                                                        }}
                                                    >
                                                        Fully Repaid
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>

                                            <TabContent activeTab={activeTab}>
                                                {/* <TabPane tabId="1">
                                                    </TabPane>
                                                    <TabPane tabId="2">
                                                    </TabPane>
                                                    <TabPane tabId="3">
                                                    </TabPane> */}
                                                <TabPane tabId="2">
                                                    <div >
                                                        <h4>Total Loans<b>{total_count}</b></h4>

                                                        <h4>Total Loan Issued <b>{total_disbursed}</b></h4>
                                                        <h4>Total Loan Approved<b>{total_issured}</b></h4>

                                                    </div>


                                                </TabPane>
                                                <TabPane tabId="22">
                                                    <div >
                                                        <h4>Total Loan Issued <b>{total_disbursed}</b></h4>
                                                        <h4>Total Loan Approved<b>{total_issured}</b></h4>

                                                    </div>


                                                </TabPane>

                                            </TabContent>


                                            <br />

                                            <ReactDatatable
                                                config={config}
                                                records={livestock}
                                                columns={columns_livestock}
                                                dynamic={true}
                                                id="tsc"
                                                loading={isLoading}
                                                total_record={datatotal}
                                                onChange={tableChangeHandler} />
                                        </div>
                                    }
                                    vvvv
                                </>

                                :
                                <div className="text-center h-50">
                                    <Loader
                                        type="Puff"
                                        color="#00BFFF"
                                        height={50}
                                        width={50} //3 secs
                                    />
                                </div>
                            }


                        </div>
                    </div>
                </CardBody>

            </Card>


        </div>
    );
}

export default Livestock;
