import React from 'react';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import "../../../_assets/css/csv.css";
import axios from 'axios';
import { baseURL } from '../../../configs/exports';

import xls from 'xlsx';
import { withTranslation } from 'react-i18next';

// const handleChangeCSV = event => {
//   console.log("FETCHER", event.target.files);
//   this.setState({
//     csvfile: event.target.files[0]
//   });
// };


class FileUploadDefault extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      showError: false,
      isShowError: false,

    };
  }
  importCSV = event => {
    event.preventDefault();
    this.setState({ isLoading1: true, isLoading: true });
    var reader = new FileReader();
    reader.onload = this.excelToJson.bind(this, reader);
    reader.readAsBinaryString(this.state.csvfile);
  }



  excelToJson(reader) {
    var fileData = reader.result;
    var wb = xls.read(fileData, { type: 'binary' });
    var dataS = {};
    var rowString;
    var rowObj;
    wb.SheetNames.forEach(function (sheetName) {
      rowObj = xls.utils.sheet_to_row_object_array(wb.Sheets[sheetName]);
      rowString = JSON.stringify(rowObj);
      dataS[sheetName] = rowString;
    });
    // const datas = result.data;
    let data = [];
    // let mclient_id;
    let maccount_no;

    let mreceipt_no;

    let mmsisdn;

    let mname;
    let mamount;
    for (let i = 0; i < rowObj.length; i++) {
      // mclient_id = { client_id: USER_ID };

      maccount_no = { maccount_no: `${rowObj[i]["__EMPTY_10"]}` }

      mreceipt_no = { mreceipt_no: `${rowObj[i]["Account Holder:"]}` }

      mamount = { mamount: `${rowObj[i]["__EMPTY_3"]}` }

      mname = { mname: `${rowObj[i]["__EMPTY_8"]}` }

      mmsisdn = { mmsisdn: `${rowObj[i]["__EMPTY_8"]}` }
     
      data.push(Object.assign(maccount_no, mname, mreceipt_no, mmsisdn, mamount, rowObj[i]));
      this.setState({
        data: data
      })
    }


    console.log("DATA", JSON.stringify(data))
    console.log("brian", data)
    axios.post(baseURL + 'reconciliation', JSON.stringify(data),{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        })
      .then((response) => {
        this.setState({ statusMessage: "Success!!", isShowError: true, showError: false, isLoading: false }, function () {
          window.setTimeout(() => {
            // this.closeModalUpload();
            // this.getData();
            window.location.reload();
            this.setState({ isShowError: false })
          }, 2000);
        });
      }).catch(error => {
        this.setState({ statusMessage: "Success!!", isShowError: true, showError: false, isLoading: false }, function () {
          window.setTimeout(() => {
            // this.closeModalUpload();
            // this.getData();
            window.location.reload();
            this.setState({ isShowError: false })
          }, 2000);
        });

        // this.setState({ statusMessage: "Success!!", isShowError: true, showError: false, isLoading: false }, function () {
        // this.setState({ statusMessage: "Unable to import mpesa file", showError: true, isShowError: false, isLoading: false });
        // console.error('Error during service worker registration:', error);
      });
    // this.setState({ excelData: data });
  }



  handleChangeCSV = event => {
    console.log("FETCHER", event.target.files);
    this.setState({
      csvfile: event.target.files[0]
    });
  };

  render() {
    return (
      <Col md={12} lg={12}>
        <Card className="card--not-full-height">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">File Upload for Reconciliation</h5>
            </div>

            {this.state.showError ? 
            <div style={{ color: 'red' }}>
              {this.state.statusMessage}

            </div> : null}<br></br>


            {this.state.isShowError ? (
              <div
                color="success"
                style={{ fontSize: "13px", color: "green" }}>
                {this.state.statusMessage}
              </div>

            ) : null}<br></br>
            <div className="wrapper text-center">
              <div className="section1">
                <div className="contain">
                  <br /><br />
                  <input
                    className="csv-input"
                    type="file"
                    required
                    ref={input => {
                      this.filesInput = input;
                    }}
                    name="file"
                    customHeight
                    placeholder={null}
                    onChange={this.handleChangeCSV}
                  />
                  <p />
                  <Button color="primary" outline className="btn-paypal btn-lg " onClick={this.importCSV} >
                    {this.state.isLoading ? "Uploading..." : "Import now!"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                  <br /><b />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

FileUploadDefault.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'file_upload_default', // a unique identifier for this form
})(withTranslation('common')(FileUploadDefault));





