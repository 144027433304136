import React, { useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, CONFIG, formatCurrency } from '../../configs/exports';
import Loader from "react-loader-spinner";
import "../../_assets/css/csv.css";
import * as moment from 'moment';
import {
    Card, CardBody
} from 'reactstrap';
// import EditApplicant from './EditApplicant';



const InstallmentStatementThird = (props) => {
    const [isLoading, setLoad] = useState(true)
    const [queryString, setQueryString] = useState("")
    // eslint-disable-next-line
    const [activeTab, setActiveTab] = useState(props.loan_ref.activeTab) // tab layout

    const [statement, setStatment] = useState([])

    // tab 1 API call...
    useEffect(() => {

        let statment_url;
        // 30 days
        if (activeTab === "31") {
            statment_url = `${baseURL}statement?filter_value=${props.loan_ref.id}&&period=1`;
        }
        else if (activeTab === "61") {
            statment_url = `${baseURL}statement?filter_value=${props.loan_ref.id}&&period=2`;
            // alert("cjf")
        }
        else if (activeTab === "91") {
            statment_url = `${baseURL}statement?filter_value=${props.loan_ref.id}&&period=3`;
        }

        axios.all([
            axios.get(statment_url, CONFIG),
        ]).then(axios.spread((statementResponse) => {
            let data = [];
            if (statementResponse.data.data.length > 0) {
                setLoad(false)
                // let insurance_fee_original = state.location.state.insurance_fee;
                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))
                let p = 0;
                if (id > 1) {
                    p = (((id - 1) * parseInt(params.get("page_size"))))
                }
                for (let i = 0; i < statementResponse.data.data.length; i++) {
                    // format amount
                    let m_credit = { m_credit: formatCurrency(parseFloat(statementResponse.data.data[i].credit).toFixed(2)) };
                    let m_credit_sum = { m_credit_sum: formatCurrency(parseFloat(statementResponse.data.credit_sum).toFixed(2)) };

                    let s_insurance_fee = {
                        s_insurance_fee: formatCurrency(
                            parseFloat(statementResponse.data.data[i].insurance_fee)
                            + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                            + parseFloat(statementResponse.data.data[i].insurance_instalment_third)
                        )
                    };

                    let minstalmet_balance;
                    let m_insurance_fee;

                    let m_balance_instalment;

                    let m_interest;
                    let m_interest_two;
                    let m_interest_three;
                    let m_rollover;
                    let m_debt_collection;

                    let m_principal_2
                    let m_principal_3;

                    let m_insurance_fee_2;
                    let m_insurance_fee_3;



                

                    let minstalmet_principal;
                    // alert("text")
                    let unrow;
                    if (activeTab === "31") {
                        // alert(mloan_amount)
                        p++;
                        unrow = { row: p }

                        minstalmet_balance = { month_installment: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment))) }
                        minstalmet_principal = { month_principal: formatCurrency((parseFloat(statementResponse.data.data[i].debit) / 3)) }
                        m_insurance_fee = { m_insurance_fee: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_fee).toFixed(2)) };

                        m_insurance_fee_2 = { m_insurance_fee_2: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_second).toFixed(2)) };

                        m_insurance_fee_3 = { m_insurance_fee_3: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_third).toFixed(2)) };

                        m_interest = { m_interest: formatCurrency(parseFloat(statementResponse.data.data[i].interest).toFixed(2)) };
                        m_interest_two = { m_interest_two: formatCurrency(parseFloat(statementResponse.data.data[i].interest_second).toFixed(2)) };

                        m_interest_three = { m_interest_three: formatCurrency(parseFloat(statementResponse.data.data[i].interest_third).toFixed(2)) };

                        m_debt_collection = { m_debt_collection: formatCurrency(parseFloat(statementResponse.data.data[i].debt_collection).toFixed(2)) };


                        m_rollover = { m_rollover: formatCurrency(parseFloat(statementResponse.data.data[i].rollover_fee).toFixed(2)) };


                        m_principal_2 = { m_principal_2: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_second))) }

                        m_principal_3 = { m_principal_3: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_third))) }

                        m_balance_instalment = {
                            m_balance_instalment: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                                + parseFloat(statementResponse.data.data[i].interest)
                                + parseFloat(statementResponse.data.data[i].insurance_fee))
                        };


                    }
                    if (activeTab === "61") {
                        p++;
                        unrow = { row: p }

                        minstalmet_balance = { month_installment: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment))) }
                        m_insurance_fee = { m_insurance_fee: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_fee).toFixed(2)) };
                        m_interest = { m_interest: formatCurrency(parseFloat(statementResponse.data.data[i].interest).toFixed(2)) };
                        m_interest_two = { m_interest_two: formatCurrency(parseFloat(statementResponse.data.data[i].interest_second).toFixed(2)) };

                        m_insurance_fee_2 = { m_insurance_fee_2: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_second).toFixed(2)) };

                        m_insurance_fee_3 = { m_insurance_fee_3: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_third).toFixed(2)) };

                        m_interest_three = { m_interest_three: formatCurrency(parseFloat(statementResponse.data.data[i].interest_third).toFixed(2)) };
                        m_debt_collection = { m_debt_collection: formatCurrency(parseFloat(statementResponse.data.data[i].debt_collection).toFixed(2)) };
                        m_rollover = { m_rollover: formatCurrency(parseFloat(statementResponse.data.data[i].rollover_fee).toFixed(2)) };
                        m_principal_2 = { m_principal_2: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_second))) }

                        m_principal_3 = { m_principal_3: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_third))) }


                        m_balance_instalment = {
                            m_balance_instalment: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                                + parseFloat(statementResponse.data.data[i].interest)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_second)
                                + parseFloat(statementResponse.data.data[i].interest_second)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                                + parseFloat(statementResponse.data.data[i].insurance_fee))
                        };

                        // alert("cjf")
                    }
                    if (activeTab === "91") {
                        p++;
                        unrow = { row: p }

                        minstalmet_balance = { month_installment: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment))) }
                        m_insurance_fee = { m_insurance_fee: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_fee).toFixed(2)) };
                        m_interest = { m_interest: formatCurrency(parseFloat(statementResponse.data.data[i].interest).toFixed(2)) };
                        m_interest_two = { m_interest_two: formatCurrency(parseFloat(statementResponse.data.data[i].interest_second).toFixed(2)) };

                        m_insurance_fee_2 = { m_insurance_fee_2: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_second).toFixed(2)) };

                        m_insurance_fee_3 = { m_insurance_fee_3: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_third).toFixed(2)) };

                        m_interest_three = { m_interest_three: formatCurrency(parseFloat(statementResponse.data.data[i].interest_third).toFixed(2)) };
                        m_debt_collection = { m_debt_collection: formatCurrency(parseFloat(statementResponse.data.data[i].debt_collection).toFixed(2)) };
                        m_rollover = { m_rollover: formatCurrency(parseFloat(statementResponse.data.data[i].rollover_fee).toFixed(2)) };
                        m_principal_2 = { m_principal_2: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_second))) }

                        m_principal_3 = { m_principal_3: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_third))) }


                        m_balance_instalment = {
                            m_balance_instalment: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_second)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_third)
                                + parseFloat(statementResponse.data.data[i].interest)
                                + parseFloat(statementResponse.data.data[i].interest_second)
                                + parseFloat(statementResponse.data.data[i].interest_third)
                                + parseFloat(statementResponse.data.data[i].insurance_fee)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_third))
                        };
                    }



                    // alert(JSON.stringify(statementResponse.data.data))
                    let m_debit = { m_debit: formatCurrency((parseFloat(statementResponse.data.data[i].debit))) };

                    let m_balance = {
                        m_balance: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                            + parseFloat(statementResponse.data.data[i].monthly_instalment_second)
                            + parseFloat(statementResponse.data.data[i].monthly_instalment_third)
                            + parseFloat(statementResponse.data.data[i].interest)
                            + parseFloat(statementResponse.data.data[i].interest_second)
                            + parseFloat(statementResponse.data.data[i].interest_third)
                            + parseFloat(statementResponse.data.data[i].insurance_fee)
                            + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                            + parseFloat(statementResponse.data.data[i].insurance_instalment_third)
                            + (parseFloat(statementResponse.data.data[i].rollover_fee)
                                + parseFloat(statementResponse.data.data[i].debt_collection)))
                    };

                    // alert("url")
                    let date = { dates: (moment(statementResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(date, unrow, m_credit_sum, m_insurance_fee_3, m_insurance_fee_2, m_interest_two, m_principal_2, m_principal_3, m_interest_three, m_debt_collection, m_rollover, s_insurance_fee, m_balance_instalment, m_insurance_fee, minstalmet_balance, minstalmet_principal, m_credit, m_debit, m_balance, m_interest, statementResponse.data.data[i]));

                    // data_rollover.push(Object.assign(minstalmet_balance, m_debt_collection, m_credit_sum, m_credit, m_debit, m_balance, m_balance_instalment, m_rollover, m_interest, m_insurance_fee, date, statementResponse.data.data[i]));

                    setLoad(false)
                }
                setStatment(data)
            } else {
                setLoad(false)
                setStatment(data)
            }
        })).catch(error => {
            // alert(JSON.stringify(error.response))
        })
    }, [activeTab, queryString]);// eslint-disable-line react-hooks/exhaustive-deps

    const config = {
        key_column: "tsc",
        length_menu: [20, 50, 100, 200, 500],
        show_filter: false,
        show_pagination: true,
        pagination: 'advance',
        page_size: 20,
        button: {
            csv: true,
            excel: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Filter here..",
            no_data_text: "There was no statement",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }
    //sms columns list


    const columns_sms = [

        {
            key: "row",
            TrOnlyClassName: 'cell',
            text: "#",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_balance",
            TrOnlyClassName: 'cell',
            text: "Debit",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_credit",
            TrOnlyClassName: 'cell',
            text: "Credit",
            className: "tsc",
            align: "left"
        },



        {
            key: "month_installment",
            TrOnlyClassName: 'cell',
            text: "P. Instal",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_interest",
            TrOnlyClassName: 'cell',
            text: "Interest",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_insurance_fee",
            TrOnlyClassName: 'cell',
            text: "Ins. Instal",
            className: "tsc",
            align: "left"
        },


        {
            key: "m_principal_2",
            TrOnlyClassName: 'cell',
            text: "2nd P. Instal",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_interest_two",
            TrOnlyClassName: 'cell',
            text: "2nd Interest",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_insurance_fee_2",
            TrOnlyClassName: 'cell',
            text: "2nd Ins. Instal",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_principal_3",
            TrOnlyClassName: 'cell',
            text: "3rd P. Instal",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_interest_three",
            TrOnlyClassName: 'cell',
            text: "3rd Interest",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_insurance_fee_3",
            TrOnlyClassName: 'cell',
            text: "3rd Ins. Instal",
            className: "tsc",
            align: "left"
        },


        {
            key: "m_balance_instalment",
            TrOnlyClassName: 'cell',
            text: "T. Instal Balance",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_rollover",
            TrOnlyClassName: 'cell',
            text: "Rollover Fee",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_debt_collection",
            TrOnlyClassName: 'cell',
            text: "Debt Collectioon",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_balance",
            TrOnlyClassName: 'cell',
            text: "T. Loan Balance",
            className: "tsc",
            align: "left"
        },



        {
            key: "narrative",
            TrOnlyClassName: 'cell',
            text: "Narrative",
            className: "cell",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'cell',
            text: "Date Created",
            className: "cell",
            align: "left"
        }
    ];



    // handles paginations
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column")
                    + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }


    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >

            <Card>
                <CardBody className="profile__card">
                    <div className="tabs tabs--bordered-top" >
                        <div className="tabs__wrap">

                            {isLoading === false ?

                                <>
                                    <div >
                                        {/* Total Record: <b>{datatotal}</b> */}
                                    </div>

                                    <ReactDatatable
                                        config={config}
                                        records={statement}
                                        columns={columns_sms}
                                        dynamic={true}
                                        id="tsc"
                                        loading={isLoading}
                                        onChange={tableChangeHandler} />

                                </>

                                :
                                <div className="text-center h-50">
                                    <Loader
                                        type="Puff"
                                        color="#00BFFF"
                                        height={50}
                                        width={50} //3 secs
                                    />
                                </div>
                            }


                        </div>
                    </div>
                </CardBody>

            </Card>


        </div>
    );
}

export default InstallmentStatementThird;
