import React, { useState, Fragment, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, CONFIG, formatCurrency, ROLE } from '../../configs/exports';
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "../../_assets/css/csv.css";
import ProductItems from '../../shared/components/catalog/ProductItems';
import * as moment from 'moment';
import {
    Col, Row,
    Card, CardBody, Table, Button, TabContent, TabPane,
} from 'reactstrap';
// import EditApplicant from './EditApplicant';
import { AddCustomer } from '../Loan/addLoan';

import { AddMoreCows } from '../Loan/AddMoreCows'
import { AddDocuments } from './addOtherDocuments';


const FarmerDetails = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("loan_status=2&filter_value=" + location.state.id + "&page_number=1&page_size=20&sort_order=false")

    const [livestock, setLiveStock] = useState([])
    // eslint-disable-next-line
    const [activeTab, setActiveTab] = useState("1")
    const [home, setHome] = useState(false)
    const [livestockDetail, setLivestockDetail] = useState(false)
    const [livestockPhoto_One, setLivestockPhotoOne] = useState(false)
    const [livestockPhoto_Two, setLivestockPhotoTwo] = useState(false)
    const [livestockPhoto_Three, setLivestockPhotoThree] = useState(false)
    const [next_to_cow, setNextToCow] = useState(false)

    const [loanhome, setLoanHome] = useState(false)

    const [goApplication, setLoanApply] = useState(false)
    const [addDocument, setDocuments] = useState(false)

    const [details, setLivestockDetailArray] = useState([]) //define array for livestock details

    const [goloan, setGoHome] = useState([])

    const [addmulti, setAddMulti] = useState(false)

    const [loan_limit, setLoanLimit] = useState(0)


    const [add_document_id, setIDDocument] = useState(0)

    const [loan_applicable, setLoanApplicable] = useState(0)

    const [applicant_fee, setLoanApplicationFee] = useState(0)
    const [cow_value, setCowValue] = useState(0)

    const [mloan_limit_cow, setCowLimit] = useState(0)

    const [applicant_msisdn, setApplicationMsisdn] = useState(0)
    const [applicant_id, setApplicationID] = useState(0)
    const [livestock_id, setLivestockID] = useState(0)

    const [status_paid, setStatusPaid] = useState(0)

    const [livestocksIDs, setLivestockIDs] = useState(0)

    const [insurance, setInsurance] = useState(0)

    const [status_check, setStatusChecker] = useState(6)


    // const [avatar, setAvatar] = useState("") // set profile picture


    useEffect(() => {
        // consuming API based on active tab
        let livestock_url = baseURL + "livestock?" + queryString;



        // tab 1 API call...
        if (activeTab === "1") {
            axios.all([
                axios.get(livestock_url, CONFIG),
            ]).then(axios.spread((livestockResponse) => {
                let data = [];
                console.log("TEST", JSON.stringify(livestockResponse.data))


                // alert(JSON.stringify(livestockResponse.data.data[0].))
                if (livestockResponse.data.data.length > 0) {
                    setLoad(false)
                    let params = new URLSearchParams(queryString)

                    // let id = parseInt(params.get("page_number"))

                    let p = 0;
                    // if (id > 1) {
                    //     p = (parseInt(livestockResponse.data.total) - ((id - 1) * parseInt(params.get("page_size")))) + 1
                    // }

                    // let unrow_loan = { row_loan: "LN " + p }

                    setIDDocument(livestockResponse.data.data.length)

                    // alert(livestockResponse.data.data.length)
                    // eslint-disable-next-line
                    setLoanApplicationFee(1100)
                    setApplicationMsisdn(livestockResponse.data.data[0].msisdn)

                    let valueInsurance = 0
                    let countInsurance = 0



                    let valueInsuranceCow = 0
                    let countInsuranceCow = 0

                    // eslint-disable-next-line
                    var results = new Array();

                    for (let i = 0; i < livestockResponse.data.data.length; i++) {
                       
                        p++;
                        let unrow = { row: p }
    
                        // eslint-disable-next-line
                        if (livestockResponse.data.data[i].complete == "1" && (livestockResponse.data.data[i].loan_status == "0" || livestockResponse.data.data[i].status_paid == "1")) {
                            // countExpense = parseFloat(livestockResponse.data.data[i].value.replaceAll(",", ""))
                            // valueAddedExpenses += countExpense
                            // alert(livestockResponse.data.data[i].value)
                            setLivestockID(livestockResponse.data.data[i].id)

                            setStatusChecker(livestockResponse.data.data[i].lock_status)
                            countInsurance = parseFloat(livestockResponse.data.data[i].value)
                            valueInsurance += countInsurance


                            let insurance_fee
                            if (parseFloat(livestockResponse.data.data[i].value) > 50000) {
                                insurance_fee = 0.04 * parseFloat(livestockResponse.data.data[i].value);
                            } else {
                                insurance_fee = 2000;

                            }

                            countInsuranceCow = insurance_fee

                            valueInsuranceCow += countInsuranceCow

                            let countResults = livestockResponse.data.data[i].id.toString();
                            results.push(countResults);
                        }
                        // format amount
                        let f_amount = { f_amount: formatCurrency(parseFloat(livestockResponse.data.data[i].value).toFixed(2)) };
                        let date = { dates: (moment.utc(livestockResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };
                        

                        data.push(Object.assign(date, unrow, f_amount, livestockResponse.data.data[i]));


                        setLoad(false)
                    }

                    // alert(livestock_ids)
                    setLivestockIDs(results.join(','))
                    setApplicationID(livestockResponse.data.data[0].farmer_id)
                    setCowValue(valueInsurance)
                    setLoanApplicable(valueInsurance * 0.6)
                    setCowLimit(valueInsurance * 0.6)

                    setInsurance(valueInsuranceCow)



                    setStatusPaid(livestockResponse.data.data[0].status_paid)

                    // alert(valueInsurance)
                    // alert(valueAddedExpenses)
                    setLoanLimit(livestockResponse.data.loan_applicable)

                    setDataTotal(livestockResponse.data.total)
                    setLiveStock(data)
                } else {
                    setLoad(false)
                    setLiveStock(data)
                }
            })).catch(error => {
                alert(JSON.stringify(error.response))
            })
        }
    }, [activeTab, queryString]);


    const config = {
        key_column: "tsc",
        length_menu: [1000, 1500, 2000, 3000],
        show_filter: false,
        show_pagination: true,
        pagination: 'advance',
        page_size: 1000,
        button: {
            csv: true,
            excel: true,
            print: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Tag ID..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }


    //livestock list

    const columns_livestock = [
        {
            key: "row",
            TrOnlyClassName: 'cell',
            text: "Breed",
            className: "cell",
            align: "left"
        },

        {
            key: "tag_id",
            TrOnlyClassName: 'cell',
            text: "Tag ID",
            className: "tsc",
            align: "left"
        },

        {
            key: "gender",
            TrOnlyClassName: 'cell',
            text: "Gender",
            className: "tsc",
            align: "left"
        },

        {
            key: "age",
            TrOnlyClassName: 'cell',
            text: "Age",
            className: "tsc",
            align: "left"
        },

        {
            key: "location",
            TrOnlyClassName: 'tsc',
            text: "Location",
            className: "tsc",
            align: "left"
        },

        {
            key: "vet_reg_no",
            TrOnlyClassName: 'cell',
            text: "Vet Reg No.",
            className: "tsc",
            align: "left"
        },


        {
            key: "vet_name",
            TrOnlyClassName: 'cell',
            text: "Vet Name",
            className: "cell",
            align: "left"
        },


        {
            key: "health_ratings",
            TrOnlyClassName: 'cell',
            text: "Health Ratings",
            className: "tsc",
            align: "left"
        },

        {
            key: "f_amount",
            TrOnlyClassName: 'cell',
            text: "Value",
            className: "tsc",
            align: "left"
        },

        {
            key: "action",
            text: "Loan Status",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >
                        {record.status_paid === 1 ?
                            <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                Fully Paid
                            </span>
                            :
                            <>
                                <>
                                    {record.loan_status === 0 ?
                                        <div>
                                            <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                                Not Applied
                                            </span>
                                        </div>
                                        : null}
                                    {record.loan_status === 5 ?
                                        <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Waiting Admin Approval
                                        </span>
                                        : null
                                    }
                                    {record.loan_status === 6 ?
                                        <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Waiting Final Admin Approval
                                        </span>
                                        : null
                                    }
                                    {
                                        record.loan_status === 3 ?
                                            <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                                Waiting Loan Officer Approval
                                            </span>
                                            : null
                                    }
                                    {
                                        record.loan_status === 1 ?
                                            <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                                Waiting Agent Approval
                                            </span>
                                            : null
                                    }
                                    {record.loan_status === 9 ?
                                        <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Waiting Money Disbursement
                                        </span>
                                        : null
                                    }


                                    {
                                        record.loan_status === 2 && record.status === 1 ?
                                            <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                                Approved
                                            </span>
                                            : null
                                    }
                                </>


                            </>
                        }
                    </Fragment >
                );
            }
        },
        {
            key: "dates",
            TrOnlyClassName: 'tsc',
            text: "Date Created",
            className: "cell",
            align: "left"
        },

        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >

                        <button className="btn btn-primary btn-sm"
                            title="View pics Details"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { livestockMap(record) }} >
                            Location
                        </button>
                        <button className="btn btn-primary btn-sm"
                            title="View pics Details"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { livestockDetails(record) }} >
                            View Pictures
                        </button>
                        {record.complete === 1 && (record.loan_status === 1 || record.loan_status === 2 || record.loan_status === 9 || record.loan_status === 3 || record.loan_status === 5 || record.loan_status === 6) ?
                            <>
                                <button className="btn btn-success btn-sm"
                                    title="View profile"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={() => { loanHome(record) }} >
                                    Loan Profile
                                </button>

                                {ROLE === '4' && record.row === add_document_id ?
                                    <>
                                        {record.status_paid === 1 ?
                                            <>
                                                <button className="btn btn-primary btn-sm"
                                                    title="View pics Details"
                                                    style={
                                                        { marginRight: '10px' }}
                                                    onClick={() => { ApplyLoan(record) }} >
                                                    Process Loan
                                                </button>
                                                <button className="btn btn-success btn-sm"
                                                    title="Add Documents"
                                                    style={
                                                        { marginRight: '10px' }}
                                                    onClick={() => { FillDocuments(record) }} >
                                                    Add Documents
                                                </button>

                                            </>
                                            : null}
                                    </>
                                    : null}
                            </>
                            :
                            ROLE === '4' ?
                                record.complete === 1 ?
                                    <button className="btn btn-success btn-sm"
                                        title="View pics Details"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={() => { ApplyLoan(record) }} >
                                        Process Loan
                                    </button>
                                    :
                                    <button className="btn btn-success btn-sm"
                                        title="Add Documents"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={() => { FillDocuments(record) }} >
                                        Add Documents
                                    </button>
                                : null
                        }

                    </Fragment>
                );
            }
        }
    ];






    // handles paginations
    const tableChangeHandler = data => {
        const b = Object.assign({}, data, { filter_value: location.state.id });
        // alert("Something")
        let queryString;
        if (b.filter_value === null || b.filter_value === '') {
            alert(JSON.stringify({ b }))

            queryString = Object.keys(b).map((key) => {
                if (key === "sort_order" && b[key]) {
                    return encodeURIComponent("sort_order") + '=' + encodeURIComponent(b[key].order) + '&' +
                        encodeURIComponent("sort_column") + '=' + encodeURIComponent(b[key].column)
                } else {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(b[key])
                }
            }).join('&');
        }
        else {
            queryString = Object.keys(data).map((key) => {
                if (key === "sort_order" && data[key]) {
                    return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column")
                        + '=' + encodeURIComponent(data[key].column)
                } else {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                }
            }).join('&');
            // delete data.filter_value; // or use => delete test['blue'];
            // alert(JSON.stringify(data))
        }
        setQueryString(queryString)
    }

    const backHome = () => {
        setHome(true)
    }


    const map = () => {
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${location.state.latitude}%2C${location.state.longitude}`, '_blank');
        // setHome(true)
    }



    const ApplyLoan = (record) => {
        setLoanApply(true)
        setDocuments(false)
        setGoHome(record)
    }

    const FillDocuments = (record) => {
        setDocuments(true)
        setGoHome(record)

        // alert(record.msisdn)
    }

    const livestockDetails = (record) => {
        setLivestockDetail(true)

        setLivestockDetailArray(record)
        setLivestockPhotoOne(record.logo_url)
        setLivestockPhotoTwo(record.logo_url_2)
        setLivestockPhotoThree(record.logo_url_3)
        setNextToCow(record.next_to_cow)
    }

    const loanHome = (record) => {
        // alert(JSON.stringify(record))
        setGoHome(record)
        // localStorage.setItem("applicant_name", record.name)
        // localStorage.setItem("applicant_id_no", record.id_no)
        // // if(loa)
        // localStorage.setItem("livestock_id", record.id)
        setNextToCow(record.next_to_cow)

        // alert(record.next_to_cow)

        setLoanHome(true)
    }


    const livestockMap = (record) => {
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${record.latitude}%2C${record.longitude}`, '_blank');
    }

    const backLivestock = () => {
        setLivestockDetail(false)
    }

    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >

            {loanhome === true ?

                navigate('/loan', {
                    state: {
                        livestock_id: goloan.id,
                        applicant_name: goloan.name,
                        applicant_id_no: goloan.id_no,
                        applicant_tag_id: goloan.tag_id,
                        applicant_msisdn: goloan.msisdn,
                        next_to_cow: next_to_cow,
                        id_front_photo: goloan.id_front_photo,
                        applicant_loan_applicable: loan_limit,
                        status_paid: goloan.status_paid,
                        mloan_status: goloan.loan_status,
                        passport_photo: goloan.passport_photo,
                        applicant_ngomnbe_value: goloan.value,


                    }, replace: true
                }) : null}


            {/* const [cow_value, setCowValue] = useState(0)
                    const [applicant_msisdn, setApplicationMsisdn] = useState(0)
                    const [applicant_id, setApplicationID] = useState(0)
                    const [livestock_id, setLivestockID] = useState(0)
                    const [applicant_fee, setLoanApplicationFee] = useState(0)
                    const [loan_applicable, setLoanApplicable] = useState(0)
                    const [mloan_limit_cow, setCowLimit] = useState(0) */}
            {
                addmulti === true ?
                    <AddMoreCows farmerProps={{
                        cow_value: cow_value,
                        applicant_msisdn: applicant_msisdn,
                        applicant_id: applicant_id,
                        livestock_id: livestock_id,
                        applicant_fee: applicant_fee,
                        loan_applicable: loan_applicable,
                        mloan_limit_cow: mloan_limit_cow,
                        insurance: insurance,
                        farmer_name: location.state.name,
                        livestocksIDs: livestocksIDs,
                        status_paid: status_paid


                    }} />
                    :


                    addDocument === true ?

                        <AddDocuments farmerProps={goloan} />
                        :
                        goApplication === true ?

                            <AddCustomer farmerProps={goloan} />

                            :

                            home === true ?
                                <>

                                    <Navigate to="/farmer" />
                                </> :

                                <Card>
                                    <CardBody className="profile__card">
                                        <br />
                                        <div className="row col-12">
                                            <div className="col-10">

                                                <div className="profile__information">
                                                    <div className="profile__avatar">
                                                        <img src={location.state.passport_photo} alt="Passport" />
                                                    </div>
                                                    <div className="profile__data">
                                                        <br />
                                                        <p className="profile__name" style={{ marginBottom: "10px" }}>Farmer Name: {location.state.name}</p>
                                                        <p className="profile__name" style={{ marginBottom: "10px" }}>ID No: {location.state.id_no}</p>
                                                        <p className="profile__name" style={{ marginBottom: "10px" }}><br />
                                                            <Row>
                                                                <Button className="pull- btn-sm" onClick={map} outline color="primary">

                                                                    Find Farmer
                                                                </Button>


                                                                {livestockDetail ?
                                                                    <>
                                                                        <a href={details.insurance_url}>
                                                                            <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                                Insurance Form
                                                                            </Button>
                                                                        </a>&nbsp;&nbsp;

                                                                        <a href={details.vet_valuation_form} >
                                                                            <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                                Vet Valuation Form
                                                                            </Button>
                                                                        </a>&nbsp;&nbsp;

                                                                        <a href={details.vacination_regime_report}>
                                                                            <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                                Vacination Regime
                                                                            </Button>
                                                                        </a>
                                                                    </>
                                                                    : null}
                                                                <Link to={'/farmer_logs'}
                                                                    state={{
                                                                        farmer_name: location.state.name,
                                                                        farmer_id: location.state.id,
                                                                        latitude: location.state.latitude,
                                                                        longitude: location.state.longitude,
                                                                        passport_photo: location.state.passport_photo,
                                                                        id_no: location.state.id_no,
                                                                    }
                                                                    }>
                                                                    <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                        Customer Statements
                                                                    </Button>
                                                                </Link>

                                                                <Link to={'/farmer_deposit'}
                                                                    state={{
                                                                        farmer_name: location.state.name,
                                                                        farmer_id: location.state.id,
                                                                        latitude: location.state.latitude,
                                                                        longitude: location.state.longitude,
                                                                        passport_photo: location.state.passport_photo,
                                                                        id_no: location.state.id_no,
                                                                    }}
                                                                >
                                                                    <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                        Customer Deposits
                                                                    </Button>
                                                                </Link>

                                                                <Link to={'/farmer_withdrawal'}
                                                                    state={{
                                                                        farmer_name: location.state.name,
                                                                        farmer_id: location.state.id,
                                                                        latitude: location.state.latitude,
                                                                        longitude: location.state.longitude,
                                                                        passport_photo: location.state.passport_photo,
                                                                        id_no: location.state.id_no,
                                                                    }
                                                                    }>
                                                                    <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                        Customer Withdrawal
                                                                    </Button>
                                                                </Link>
                                                            </Row>

                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-2">

                                                {!livestockDetail ?
                                                    <>
                                                        <Button className="pull-right btn-sm" onClick={backHome} outline color="primary">
                                                            Back to Farmers
                                                        </Button>
                                                    </>
                                                    : null}
                                            </div>
                                        </div>
                                        <div className="tabs tabs--bordered-top" >
                                            <div className="tabs__wrap">


                                                {isLoading === false ?

                                                    <TabContent activeTab={activeTab}>
                                                        <TabPane tabId="1">
                                                            <Table>

                                                                {livestockDetail ?
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-6">
                                                                                <h4><b>Ng'ombe Photos</b></h4>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <Button className="pull-right btn-sm" onClick={backLivestock} outline color="primary">
                                                                                    Swtich back to livestock
                                                                                </Button>
                                                                            </div>
                                                                        </div>

                                                                        <Row>
                                                                            <Col md={12} lg={12}>
                                                                                <ProductItems items={
                                                                                    [
                                                                                        {
                                                                                            id: 1,
                                                                                            src: `${livestockPhoto_One}`,
                                                                                            title: 'Photo 1',
                                                                                            colors: ['#00b3c6', '#50e3c2', '#fa4a86'],
                                                                                        },
                                                                                        {
                                                                                            id: 2,
                                                                                            src: `${livestockPhoto_Two}`,
                                                                                            title: 'Photo 2',
                                                                                            colors: ['#d4d4d4', '#5ff4d3', '#f7a9c4'],
                                                                                        },
                                                                                        {
                                                                                            id: 3,
                                                                                            src: `${livestockPhoto_Three}`,
                                                                                            title: 'Photo 3',
                                                                                            colors: ['#fa93bd'],
                                                                                        },
                                                                                        // {
                                                                                        //     id: 4,
                                                                                        //     src: `${next_to_cow}`,
                                                                                        //     title: 'Gurantor, Borrower next to cow',
                                                                                        //     colors: ['#fa93bd'],
                                                                                        // }
                                                                                    ]
                                                                                } />
                                                                            </Col>
                                                                        </Row>

                                                                    </>
                                                                    :
                                                                    <>




                                                                        <div >
                                                                            Total Livestocks: <b>{datatotal}</b>
                                                                            <br /><br />
                                                                        </div>

                                                                        {/* {alert(status_check)} */}
                                                                        {ROLE === '4' ?
                                                                            status_check === 1 ?
                                                                                null :
                                                                                <button className="btn btn-success btn-sm"
                                                                                    title="Add loan"
                                                                                    style={
                                                                                        { marginRight: '10px' }}
                                                                                    onClick={() => {
                                                                                        setAddMulti(true)
                                                                                    }} >
                                                                                    Process Loan
                                                                                </button>
                                                                            :
                                                                            null
                                                                        }
                                                                        <br />

                                                                        <br />
                                                                        <ReactDatatable
                                                                            config={config}
                                                                            records={livestock}
                                                                            columns={columns_livestock}
                                                                            dynamic={true}
                                                                            id="tsc"
                                                                            loading={isLoading}
                                                                            total_record={datatotal}
                                                                            onChange={tableChangeHandler} />
                                                                    </>
                                                                }

                                                            </Table>

                                                        </TabPane>

                                                        <TabPane tabId="2">

                                                        </TabPane>
                                                    </TabContent>
                                                    :
                                                    <div className="text-center h-50">
                                                        <Loader
                                                            type="Puff"
                                                            color="#00BFFF"
                                                            height={50}
                                                            width={50} //3 secs
                                                        />
                                                    </div>
                                                }


                                            </div>
                                        </div>
                                    </CardBody>

                                </Card>
            }



        </div >
    );
}

export default FarmerDetails;
