import React, { useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';

import axios from 'axios';
import "../../_assets/css/file.css"

import { Card, CardBody } from 'reactstrap';
import { TOKEN } from '../../configs/exports';
import { baseURL, formatCurrency } from '../../configs/exports';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'


//rollover fuction

const DebtCollection = () => {

    const [instalment, setInstalment] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [queryString, setQueryString] = useState("")
    const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(moment(new Date()))
    const [datevalue, setDateValue] = useState("This Month")

    const [expected_value, setValues] = useState({
        count: 0,
        exp_insurance_fee: 0,
        exp_principal: 0,
        exp_interest: 0,
        exp_rollover: 0,
        exp_depth: 0,
        principal: 0,
        interest: 0,
        insurance_fee: 0,
        rollover_fee: 0,
        debt_collection: 0
    })

    localStorage.setItem("STARTDATE", start)
    localStorage.setItem("ENDDATE", moment(new Date()))

    useEffect(() => {
        setLoad(true)

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': TOKEN
            },
        };
        let url = baseURL + `loan_installment_report?period=4&period_lock=8&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;

        axios.all([
            axios.get(url, config)
        ]).then(axios.spread((applicantResponse) => {
            let data = [];
            console.log("total loans", url)

            if (applicantResponse.data.data.length > 0) {
                setValues(applicantResponse.data.total)
                // setAgent(agentResponse.data.data)
                // alert(  applicantResponse.data.data[0].application_date)
                setLoad(false)
                for (let i = 0; i < applicantResponse.data.data.length; i++) {

                    let mexpected_amount = {
                        mexpected_amount:
                            formatCurrency(parseFloat(applicantResponse.data.data[i].exp_insurance_fee) + parseFloat(applicantResponse.data.data[i].exp_interest) + parseFloat(applicantResponse.data.data[i].exp_principal) + parseFloat(applicantResponse.data.data[i].exp_rollover) + parseFloat(applicantResponse.data.data[i].exp_depth))
                    };

                    let mpaid_amount = {
                        mpaid_amount:
                            formatCurrency(parseFloat(applicantResponse.data.data[i].insurance_fee) + parseFloat(applicantResponse.data.data[i].interest) + parseFloat(applicantResponse.data.data[i].principal) + parseFloat(applicantResponse.data.data[i].rollover_fee + parseFloat(applicantResponse.data.data[i].debt_collection)
                            ))

                    };


                    let mexpected_rollover = {
                        mexpected_rollover: formatCurrency(
                            (parseFloat(applicantResponse.data.data[i].exp_rollover))
                        )
                    };


                    let mpaid_rollover = {
                        mpaid_rollover:
                            formatCurrency(
                                (parseFloat(applicantResponse.data.data[i].rollover_fee))

                            )
                    };

                    let mexpected_debt = {
                        mexpected_debt: formatCurrency(
                            (parseFloat(applicantResponse.data.data[i].exp_depth))
                        )
                    };


                    let mpaid_debt = {
                        mpaid_debt:
                            formatCurrency(
                                (parseFloat(applicantResponse.data.data[i].debt_collection))

                            )
                    };
                    let date = { dates: (moment.utc(applicantResponse.data.data[i].start_date).format('DD/MM/YYYY')) };

                    data.push(Object.assign(date, mpaid_amount, mpaid_rollover, mpaid_debt, mexpected_debt, mexpected_rollover, mexpected_amount, applicantResponse.data.data[i]));
                    setLoad(false)



                }
                setInstalment(data)
            } else {
                setLoad(false)

                setInstalment(data)
            }
        }))

    }, [startDate, queryString, endDate]);

    // alert(TOKEN)
    // const rowClickedHandler = (event, data, rowIndex) => {

    //     // alert(JSON)
    //     setFarmerProps(data);
    //     setApplicantDetails(true)

    // };



    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true,
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here...",
            no_data_text: "There was no data this month",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    const applyCallback = (startDate, endDate) => {
        const Sdate1 = moment(startDate).format('DD MMM, YYYY');
        const Edate2 = moment(endDate).format('DD MMM, YYYY');
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)

        setDateValue(Sdate1 + " " + Edate2)
        setStartDate(startDate)
        setEndDate(endDate)
    }


    const ranges = {
        "Today Only": [moment(startDate), moment(endDate)],
        "Yesterday Only": [
            moment(startDate).subtract(1, "days"),
            moment(endDate).subtract(1, "days")
        ],
        "3 Days": [moment(startDate).subtract(3, "days"), moment(endDate)],
        "5 Days": [moment(startDate).subtract(5, "days"), moment(endDate)],
        "1 Week": [moment(startDate).subtract(7, "days"), moment(endDate)],
        "2 Weeks": [moment(startDate).subtract(14, "days"), moment(endDate)],
        "1 Month": [moment(startDate).subtract(1, "months"), moment(endDate)],
        "1 Year": [moment(startDate).subtract(1, "years"), moment(endDate)]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false
    }

    const columns = [

        {
            key: "name",
            text: "Borrower",
            TrOnlyClassName: 'cell',
            className: "cell",
            align: "left"
        },

        {
            key: "msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone",
            className: "cell",
            align: "left"
        },
        {
            key: "mexpected_amount",
            TrOnlyClassName: 'cell',
            text: "Total Expected AMount",
            className: "cell",
            align: "left"
        },


        {
            key: "exp_interest",
            TrOnlyClassName: 'cell',
            text: "Interest",
            className: "cell",
            align: "left"
        },

        {
            key: "interest",
            TrOnlyClassName: 'cell',
            text: "Unpaid Interest",
            className: "cell",
            align: "left"
        },
        {
            key: "mpaid_amount",
            TrOnlyClassName: 'cell',
            text: "Total UnPaid Amount",
            className: "cell",
            align: "left"
        },

        {
            key: "mexpected_rollover",
            TrOnlyClassName: 'cell',
            text: "Expected Rollover",
            className: "cell",
            align: "left"
        },
        {
            key: "mpaid_rollover",
            TrOnlyClassName: 'cell',
            text: "UnPaid Rollover",
            className: "cell",
            align: "left"
        },

        {
            key: "mexpected_debt",
            TrOnlyClassName: 'cell',
            text: "Expected Debt Collection",
            className: "cell",
            align: "left"
        },
        {
            key: "mpaid_debt",
            TrOnlyClassName: 'cell',
            text: "UnPaid Debt Collection",
            className: "cell",
            align: "left"
        },
        {
            key: "field_officer",
            TrOnlyClassName: 'cell',
            text: "Field Officer",
            className: "cell",
            align: "left"
        },
        {
            key: "branch_name",
            TrOnlyClassName: 'cell',
            text: "Branch",
            className: "cell",
            align: "left"
        },

        {
            key: "start_date",
            TrOnlyClassName: 'cell',
            text: "Loan Start Date",
            className: "cell",
            align: "left"
        },

    ];
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');

        setQueryString(queryString)

        setStartDate(moment(startDate))
        setEndDate(moment(endDate))
    }

    return (
        <div style={{ marginTop: "-20px" }} >
            < >

                < Card >
                    <CardBody >
                        <div className="row">
                            <div className="col-md-6">
                                <h4> <b>Debt Collection Analysis Report</b></h4>
                                <br />
                            </div>
                            <div className="col-6">
                                Refresh <button className="float-" title="refresh" style={{ background: "white", border: "0px" }}
                                    onClick={
                                        () => {
                                            setQueryString(require("randomstring").generate({
                                                length: 1,
                                                charset: 'alphanumeric',
                                                capitalization: 'lowercase'
                                            }))
                                        }} >
                                    <RefreshIcon />
                                </button>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-6">
                                            <DateTimeRangeContainer
                                                ranges={ranges}
                                                start={startDate}
                                                end={endDate}
                                                local={local}
                                                applyCallback={applyCallback}>
                                                <FormControl
                                                    id="formControlsTextB"
                                                    type="text"
                                                    value={datevalue}
                                                    label="Text"
                                                    placeholder="Filter by Date"
                                                />
                                            </DateTimeRangeContainer>
                                        </div>
                                        <div className="col-6 float-left">

                                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {

                                                const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));

                                                setDateValue("This Month")
                                                setStartDate(start)
                                                setEndDate(moment(new Date()))

                                            }} >Clear</button>
                                            {/* <input id="input" type="text" readOnly /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {/* <Link to="reports">
                                    <Button className="pull-right btn-sm" outline color="primary">
                                        Back to Repprts
                                    </Button>
                                </Link> */}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <br />
                                <h4> <b>Total Expected Amount: </b> {formatCurrency(expected_value.exp_insurance_fee + expected_value.exp_interest + expected_value.exp_principal + expected_value.exp_rollover + expected_value.exp_depth)}</h4>
                                <h4> <b>Total Expected Principal: </b> {formatCurrency(expected_value.exp_principal)}</h4>
                                <h4> <b>Total Expected Insurance Fee: </b> {formatCurrency(expected_value.exp_insurance_fee)}</h4>
                                <h4> <b>Total Expected Interest: </b> {formatCurrency(expected_value.exp_interest)}</h4>
                                <h4> <b>Total Expected Rollover: </b> {formatCurrency(expected_value.exp_rollover)}</h4>
                                <h4> <b>Total Expected Debt Collection AMount: </b> {formatCurrency(expected_value.exp_depth)}</h4>


                            </div>
                            <div className="col-6">
                                <br />
                                <h4> <b>Total Unpaid Amount: </b> {formatCurrency(expected_value.insurance_fee + expected_value.interest + expected_value.principal + expected_value.rollover_fee + expected_value.debt_collection)}</h4>
                                <h4> <b>Total Unpaid Principal: </b> {formatCurrency(expected_value.principal)}</h4>
                                <h4> <b>Total Unpaid Insurance: </b> {formatCurrency(expected_value.insurance_fee)}</h4>
                                <h4> <b>Total Unpaid Interest: </b> {formatCurrency(expected_value.interest)}</h4>
                                <h4> <b>Total UnPaid Rollover: </b> {formatCurrency(expected_value.rollover_fee)}</h4>
                                <h4> <b>Total UnPaid Debt Collection: </b> {formatCurrency(expected_value.debt_collection)}</h4>


                            </div>
                        </div>


                        <br />
                        <div className="panel-body" >

                            <ReactDatatable
                                config={config}
                                records={instalment}
                                columns={columns}
                                dynamic={true}
                                id="tsc"
                                loading={isLoading}
                            
                                total_record={expected_value.count}
                                onChange={tableChangeHandler} />
                        </div>
                    </CardBody>


                </Card>
            </>
        </div>
    )
}
export default DebtCollection;
