import React, { useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';

import axios from 'axios';
import "../../_assets/css/file.css"

import { Card, CardBody } from 'reactstrap';
import { TOKEN } from '../../configs/exports';
import { baseURL, formatCurrency } from '../../configs/exports';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';


//rollover fuction

const Commission = () => {

    const [instalment, setInstalment] = useState([])
    const [total, setValues] = useState("")
    const [isLoading, setLoad] = useState(true)
    const [queryString, setQueryString] = useState("")
    
    useEffect(() => {
        setLoad(true)

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': TOKEN
            },
        };
        let url = baseURL + `commission?${queryString}`;

        axios.all([
            axios.get(url, config)
        ]).then(axios.spread((applicantResponse) => {
            let data = [];

            if (applicantResponse.data.data.length > 0) {
                setValues(applicantResponse.data.total)
                // setAgent(agentResponse.data.data)
                // alert(  applicantResponse.data.data[0].application_date)
                setLoad(false)
                for (let i = 0; i < applicantResponse.data.data.length; i++) {

                    let mpaid_debt = {
                        mpaid_debt:
                            formatCurrency(
                                (parseFloat(applicantResponse.data.data[i].amount))

                            )
                    };
                    let date = { dates: (moment.utc(applicantResponse.data.data[i].created_on).format('DD/MM/YYYY')) };

                    data.push(Object.assign(date, mpaid_debt, applicantResponse.data.data[i]));
                    setLoad(false)



                }
                setInstalment(data)
            } else {
                setLoad(false)

                setInstalment(data)
            }
        }))

    }, [queryString]);

    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true,
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here...",
            no_data_text: "There was no data this month",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

   const columns = [

        {
            key: "name",
            text: "Staff",
            TrOnlyClassName: 'cell',
            className: "cell",
            align: "left"
        },

        {
            key: "mpaid_debt",
            TrOnlyClassName: 'cell',
            text: "Amount",
            className: "cell",
            align: "left"
        },


        {
            key: "transaction_id",
            TrOnlyClassName: 'cell',
            text: "Transaction ID",
            className: "cell",
            align: "left"
        },

        {
            key: "reference",
            TrOnlyClassName: 'cell',
            text: "Reference",
            className: "cell",
            align: "left"
        },

        {
            key: "period",
            TrOnlyClassName: 'cell',
            text: "Period",
            className: "cell",
            align: "left"
        },
        {
            key: "branch_name",
            TrOnlyClassName: 'cell',
            text: "Branch",
            className: "cell",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'cell',
            text: "Date",
            className: "cell",
            align: "left"
        },

    ];
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');

        setQueryString(queryString)
    }

    return (
        <div style={{ marginTop: "-20px" }} >
            < >

                < Card >
                    <CardBody >
                        <div className="row">
                            <div className="col-md-6">
                                <h4> <b>Commissions</b></h4>
                                <br />
                            </div>
                            <div className="col-6">
                                Refresh <button className="float-" title="refresh" style={{ background: "white", border: "0px" }}
                                    onClick={
                                        () => {
                                            setQueryString(require("randomstring").generate({
                                                length: 1,
                                                charset: 'alphanumeric',
                                                capitalization: 'lowercase'
                                            }))
                                        }} >
                                    <RefreshIcon />
                                </button>
                            </div>
                        </div>
                        <br />
                        <div className="panel-body" >

                            <ReactDatatable
                                config={config}
                                records={instalment}
                                columns={columns}
                                dynamic={true}
                                id="tsc"
                                loading={isLoading}
                            
                                total_record={total}
                                onChange={tableChangeHandler} />
                        </div>
                    </CardBody>


                </Card>
            </>
        </div>
    )
}
export default Commission;
