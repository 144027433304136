import React, { useState, Fragment, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { Card, Row, Button, Col, CardBody, Table } from 'reactstrap';
import { baseURL, CONFIG, formatCurrency, ROLE } from '../../configs/exports';
import * as moment from 'moment';
import ProductItems from '../../shared/components/catalog/ProductItems';
import Alert from '../../shared/components/Alert';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const Loan = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loan, setLoan] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [showFiles, setShowFIles] = useState(false)
    const [queryString, setQueryString] = useState("")
    const [oneloan, setOneLoan] = useState([])
    const [showerror, isShowError] = useState(false)
    const [success, isSuccess] = useState(false)
    const [statusMessage, setStatusMessage] = useState()
    const [showShowStatement, setShowStatement] = useState(false)
    const [loanhome, setLoanHome] = useState(false)
    const [gocharge, setCharges] = useState(false)
    const [goloan, setGoHome] = useState([])
    // const [auction, setAuction] = useState(false)
    const [showstatement, setShowStatementDetails] = useState([])

    useEffect(() => {
        setLoad(true)
        // eslint-disable-next-line 
        let url = baseURL + "loan?filter_value=" + location.state.livestock_id;
        // alert(url)
        axios.all([
            axios.get(url, CONFIG)
        ]).then(axios.spread((loanResponse) => {
            let data = [];
            if (loanResponse.data.data.length > 0) {
                console.log(loanResponse.data.data)



                for (let i = 0; i < loanResponse.data.data.length; i++) {
                    const diffInDays = moment(loanResponse.data.data[i].due_date).diff(moment(Date.now()), 'days');
                    let applicant_name = { applicant_name: location.state.applicant_name };
                    let mloan_balance = {
                        loan_balance:
                            (parseFloat(loanResponse.data.data[i].monthly_instalment)
                                + parseFloat(loanResponse.data.data[i].monthly_instalment_third)
                                + parseFloat(loanResponse.data.data[i].monthly_instalment_second)
                                + parseFloat(loanResponse.data.data[i].insurance_installment)
                                + parseFloat(loanResponse.data.data[i].insurance_instalment_second)
                                + parseFloat(loanResponse.data.data[i].insurance_instalment_third)
                                + parseFloat(loanResponse.data.data[i].rollover_fee)
                                + parseFloat(loanResponse.data.data[i].debt_collection)
                                + parseFloat(loanResponse.data.data[i].interest)
                                + parseFloat(loanResponse.data.data[i].interest_third)
                                + parseFloat(loanResponse.data.data[i].interest_second)
                            )
                    };
                    let f_amo;

                    // if (parseFloat(loanResponse.data.data[i].total_amount) === ((parseFloat(loanResponse.data.data[i].gross_income) * 0.30) - parseFloat(loanResponse.data.data[i].applicant_fee))) {

                    //     f_amo = { f_mo: formatCurrency(parseFloat(loanResponse.data.data[i].total_amount - 33).toFixed(2)) };

                    // } else {

                    f_amo = { f_mo: formatCurrency(parseFloat(loanResponse.data.data[i].amount_disbursed).toFixed(2)) };  // loan amount amount sent

                    // }

                    let period = { period: Number(diffInDays) };


                    let passport_photo = { passport_photo: location.state.passport_photo };

                    // eslint-disable-next-line 
                    let applicant_id_no = { applicant_id_no: location.state.applicant_id_no };

                    let mpesa_charges = { mpesa_charges: formatCurrency(33) };

                    let applicant_msisdn = { applicant_msisdn: location.state.applicant_msisdn };

                    let mgross_income = { mgross_income: formatCurrency(parseFloat(loanResponse.data.data[i].gross_income).toFixed(2)) };

                    let mloan_approved = { mloan_approved: formatCurrency(parseFloat(loanResponse.data.data[i].amount_disbursed) + 1133) };


                    let mloan_limit

                    if ((parseFloat(loanResponse.data.data[i].gross_income) * 0.30) < ((parseFloat(location.state.applicant_ngomnbe_value) * 0.6))) {

                        mloan_limit = { mloan_limit: formatCurrency(parseFloat(loanResponse.data.data[i].gross_income) * 0.30) };

                    } else {
                        mloan_limit = { mloan_limit: formatCurrency(((parseFloat(location.state.applicant_ngomnbe_value) * 0.6))) };

                    }



                    // eslint-disable-next-line 
                    let applicant_ngomnbe_value = { applicant_ngomnbe_value: formatCurrency(parseFloat(location.state.applicant_ngomnbe_value).toFixed(2)) };

                    let fpaid_amount = { fpaid_amount: formatCurrency(parseFloat(loanResponse.data.data[i].paid_amount).toFixed(2)) };

                    let f_amount = { f_amount: formatCurrency(parseFloat(loanResponse.data.data[i].amount_disbursed).toFixed(2)) };

                    let a_amount = { a_amount: formatCurrency(parseFloat(loanResponse.data.data[i].applicant_fee).toFixed(2)) };

                    let m_insurance_fee = { m_insurance_fee: formatCurrency(parseFloat(loanResponse.data.data[i].insurance_fee).toFixed(2)) };

                    let date = { dates: (moment.utc(loanResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };

                    let date_due = { dates_due: (moment.utc(loanResponse.data.data[i].due_date).format('DD/MM/YYYY HH:mm:ss')) };

                    data.push(Object.assign(applicant_name, mloan_balance, mloan_approved, passport_photo, mgross_income, mloan_limit,
                        period, fpaid_amount, f_amo, m_insurance_fee, applicant_ngomnbe_value, date_due, applicant_msisdn,
                        date, f_amount, a_amount, applicant_id_no, mpesa_charges, loanResponse.data.data[i]));
                }
                setLoad(false)
                setLoan(data)
            } else {
                setLoad(false)
                setLoan(data)
            }
        }))


    }, [queryString]); // eslint-disable-line react-hooks/exhaustive-deps


    const loanHome = (record) => {
        // alert(JSON.stringify(record))
        setLoanHome(true)
        setGoHome(record)

    }

    // const loanAuction = (record) => {
    //     // alert(JSON.stringify(record))
    //     setAuction(true)
    //     setGoHome(record)

    // }


    const columns = [


        {
            key: "applicant_ngomnbe_value",
            TrOnlyClassName: 'cell',
            text: "Cow Value",
            className: "tsc",
            align: "left"
        },
        {
            key: "mgross_income",
            TrOnlyClassName: 'cell',
            text: "Gross Income",
            className: "tsc",
            align: "left"
        },

        {
            key: "mloan_limit",
            TrOnlyClassName: 'cell',
            text: "Loan Limit",
            className: "tsc",
            align: "left"
        },
        {
            key: "mloan_approved",
            TrOnlyClassName: 'cell',
            text: "Approved Amount",
            className: "tsc",
            align: "left"
        },

        {
            key: "f_mo",
            TrOnlyClassName: 'cell',
            text: "Amount Sent",
            className: "tsc",
            align: "left"
        },


        {
            key: "mpesa_charges",
            TrOnlyClassName: 'cell',
            text: "Mpesa Charges",
            className: "tsc",
            align: "left"
        },

        {
            key: "a_amount",
            TrOnlyClassName: 'cell',
            text: "Application Fee",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_insurance_fee",
            TrOnlyClassName: 'cell',
            text: "Insurance Fee",
            className: "tsc",
            align: "left"
        },

        {
            key: "reference",
            TrOnlyClassName: 'cell',
            text: "Ref#",
            className: "tsc",
            align: "left"
        },

        {
            key: "action",
            text: "Period",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >
                        {record.status_paid === 0 ?
                            <>
                                {record.period > 151 ?
                                    <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                        Overdue {record.period} days
                                    </span>
                                    :
                                    <>
                                        {record.period > 121 ?
                                            <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                                Overdue {record.period} days
                                            </span>
                                            :
                                            <>
                                                {
                                                    record.period > 91 ?
                                                        <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                                            Overdue  {record.period} days
                                                        </span>
                                                        :
                                                        <>
                                                            {record.period > 60 ?
                                                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                                                    Due in {record.period} days
                                                                </span>
                                                                :
                                                                <>
                                                                    {record.period > 30 ?
                                                                        <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                                                            Due in {record.period} days
                                                                        </span>
                                                                        :
                                                                        <>
                                                                            {record.period >= 1 ?
                                                                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                                                                    Due in  {record.period} day
                                                                                </span>
                                                                                :
                                                                                record.period === 0 ?
                                                                                    <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                                                                        Due in  {record.period} day
                                                                                    </span>
                                                                                    :
                                                                                    <>
                                                                                        {record.period <= -1 ?
                                                                                            <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                                                                                Overdue by   {Math.abs(record.period)} day
                                                                                            </span>
                                                                                            :
                                                                                            <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                                                                                Pending
                                                                                            </span>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                }

                                            </>
                                        }

                                    </>
                                }
                            </>
                            : record.status_paid === 1 ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Paid
                                </span>
                                : record.status_paid === 3 ?
                                    <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                        Defaulted
                                    </span>
                                    : null


                        }


                    </Fragment >
                );
            }
        },

        // {
        //     key: "action",
        //     text: "Status",
        //     TrOnlyClassName: 'cell',
        //     className: "cell",
        //     width: 250,
        //     sortable: false,
        //     cell: record => {
        //         return (
        //             <Fragment className="center" >

        //                 {record.loan_status === "Accepted" ?
        //                     <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
        //                         {record.loan_status}
        //                     </span>
        //                     : null
        //                 }
        //                 {record.loan_status === "Pending" ?
        //                     <span class="badge-warning" style={{ borderRadius: "5px", padding: "2px" }}>
        //                         {record.loan_status}
        //                     </span>
        //                     : null
        //                 }
        //                 {record.loan_status === "Rejected" ?
        //                     <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
        //                         {record.loan_status}
        //                     </span>
        //                     : null
        //                 }
        //             </Fragment >
        //         );
        //     }
        // },


        {
            key: "dates",
            TrOnlyClassName: 'cell',
            text: "Date Applied",
            className: "cell",
        },


        {
            key: "dates_due",
            TrOnlyClassName: 'cell',
            text: "Due Date",
            className: "cell",
        },
        {
            key: "action",
            text: "Actions",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >



                        <button className="btn btn-primary btn-sm"
                            title="View pics Details"
                            style={
                                { marginRight: '10px' }}

                            onClick={() => { loanFiles(record) }}>
                            View Files
                        </button>
                        {record.status === 1 ?
                            ROLE === "1" ?
                                <button className="btn btn-danger btn-sm"
                                    title="Add Charges"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={() => { loanCharges(record) }}>
                                    Add Charges
                                </button>
                                : null
                            :
                            ROLE === "1" || ROLE === "2" ?
                                <>

                                    <button className="btn btn-danger btn-sm"
                                        title="Delete Loan"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={() => { if (window.confirm(`Are you sure you want to archvie this loans?`)) deleteUnwantedLoans(record) }} >
                                        Archive
                                    </button>
                                </>
                                : null
                        }


                        {record.status === 1 ?

                            <button className="btn btn-success btn-sm"
                                title="View pics Details"
                                style={
                                    { marginRight: '10px' }}

                                onClick={() => { loanStatement(record) }}>
                                View Statement
                            </button>
                            :
                            <>
                                {record.loan_status === "Accepted" ?
                                    <>  {/* loan officer approval */}
                                        {location.state.mloan_status === 9 ?
                                            ROLE === "1" ?
                                                <>
                                                    {record.loan_status === "Accepted" ?
                                                        <button button className="btn btn-success btn-sm"
                                                            title="Disbursement"
                                                            style={
                                                                { marginRight: '10px' }}
                                                            onClick={
                                                                () => { if (window.confirm(`Are you sure you want to make disbursement of ${formatCurrency(record.amount_disbursed)} ?`)) approveLoan(record) }} >
                                                            Disburse
                                                        </button>
                                                        : null}
                                                </>
                                                : null
                                            : null}
                                    </>
                                    :
                                    null

                                }
                                {/* admin approval */}

                            </>

                        }


                    </Fragment >
                );
            }
        },

    ];


    const loanCharges = (record) => {
        setCharges(true)
        setGoHome(record)
        setOneLoan(record)
    }




    const deleteUnwantedLoans = (record) => {  // delete unwanted loans en tries function


        let formData = {
            "loan_id": record.id,
            "farmer_id": record.farmer_id,
            "reference": record.reference,
            "livestock_id": location.state.livestock_id,
            "msisdn": record.applicant_msisdn
        }

        setLoad(true)

        axios.post(baseURL + 'deleteunwanted_loan', formData, CONFIG).then((response) => {

            console.log("SUPPORT", response.data)
            if (response.data.status) {

                setStatusMessage(response.data.message)
                isShowError(false)
                isSuccess(true)
                setLoad(false)
                window.scrollTo(0, 0)
                window.setTimeout(() => {
                    isSuccess(false)
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                }, 2000);
            } else {
                setStatusMessage(response.data.message)
                isShowError(true)
                isSuccess(false)
                setLoad(false)
                window.setTimeout(() => {
                    isSuccess(false)
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                }, 2000);

            }
        })
            .catch((error) => {
                console.log('bayoo', error)
                setStatusMessage(error.response.data.message)
                isShowError(true)
                isSuccess(false)
                setLoad(false)
                window.setTimeout(() => {
                    isSuccess(false)
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                }, 2000);

            })

    }

    const loanFiles = (record) => {
        setShowFIles(true)
        // alert(record.next_to_cow)
        setOneLoan(record)
    }


    const approveLoan = (record) => {

        // application_fee

        let formData = {
            "wallet_balance": record.amount_disbursed,
            "id_no": record.applicant_id_no,
            "debit": parseFloat(record.amount_disbursed) + parseFloat(record.application_fee) + 33.00,
            "loan_id": record.id,
            "farmer_id": record.farmer_id,
            "insurance_fee": record.insurance_fee,
            "reference": record.reference,
            "guarantor_msisdn": record.guarantor_msisdn,
            "guarantor_name": record.guarantor_name,
            "application_fee": record.applicant_fee,
            "ngombe_value": record.applicant_ngomnbe_value,
            "applicant_name": record.applicant_name,
            "loan_status": 2,
            "livestock_id": location.state.livestock_id,
            "msisdn": record.applicant_msisdn
        }

        setLoad(true)

        axios.post(baseURL + 'updatewallet', formData, CONFIG).then((response) => {

            console.log("SUPPORT", response.data)
            if (response.data.status) {

                setStatusMessage(response.data.message)
                isShowError(false)
                isSuccess(true)
                setLoad(false)
                window.scrollTo(0, 0)
                window.setTimeout(() => {
                    isSuccess(false)
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                }, 2000);
            } else {
                setStatusMessage(response.data.message)
                isShowError(true)
                isSuccess(false)
                setLoad(false)
                window.setTimeout(() => {
                    isSuccess(false)
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                }, 2000);

            }
        })
            .catch((error) => {
                console.log('bayoo', error)
                setStatusMessage(error.response.data.message)
                isShowError(true)
                isSuccess(false)
                setLoad(false)
                window.setTimeout(() => {
                    isSuccess(false)
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                }, 2000);

            })
    }

    const loanBack = () => {
        setShowFIles(false)
    }

    // statement
    const loanStatement = (record) => {
        setShowStatement(true)
        setShowStatementDetails(record)
        // localStorage.setItem("loan_statement_id", record.id)s
        // localStorage.setItem("loan_statement_reference", record.reference)
        // localStorage.setItem("due_date", record.due_date)
    }

    return (
        <div style={{ marginTop: "-20px" }} >
            < >
                {loanhome === true ?
                    navigate('/loan_status', {
                        state: {
                            loan_reference: goloan.reference,
                            farmer_id: goloan.farmer_id,
                            amount_disbursed: goloan.amount_disbursed,
                            livestock_id: location.state.livestock_id,
                            mloan_status: location.state.mloan_status
                        }, replace: true
                    })
                    : null}

                {gocharge === true ?
                    navigate('/add_charge', {
                        state: {
                            loan_reference: goloan.reference,
                            applicant_msisdn: location.state.applicant_msisdn,
                            loan_balance: goloan.loan_balance,
                            applicant_name: location.state.applicant_name
                        }, replace: true
                    })
                    : null}



                {/* {loanhome === true ? <FarmerDetails farm={location.state.array_2}/> : null} */}

                {/* {auction === true ? <Navigate to={{
                    pathname: '/actioning',
                    state: {
                        loan_reference: goloan.reference,
                        livestock_id: location.state.livestock_id,
                        loan_stage: location.state.mloan_status
                    }
                }} /> : null} */}

                {showShowStatement === true ?

                    navigate('/statement', {
                        state: {
                            loan_statement_id: showstatement.id,
                            loan_statement_reference: showstatement.reference,
                            insurance_fee: showstatement.insurance_fee,
                            created_on: showstatement.created_on,
                            loan_amount: showstatement.amount_disbursed,
                            due_date: showstatement.due_date
                        }, replace: true
                    }) : null}
                < Card >
                    <CardBody >
                        <div className="panel-body" >
                            {showFiles ?
                                <>
                                    <div className="row">
                                        <div className="col-6">

                                            <h4 >Loan #<b>Loan #{oneloan.reference}</b></h4>
                                        </div>
                                        <div className="col-6">
                                            <Button className="pull-right btn-sm" onClick={loanBack} outline color="primary">
                                                Switch back
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="profile__information">
                                        <div className="profile__avatar">
                                            <img src={oneloan.passport_photo} alt="Passport" />
                                        </div>
                                        <div className="profile__data">
                                            <br />
                                            <p className="profile__name" style={{ marginBottom: "10px" }}>Borrower: {oneloan.applicant_name}</p>
                                            <p className="profile__name" style={{ marginBottom: "10px" }}>ID No: {oneloan.applicant_id_no}</p>
                                            <p className="profile__name" style={{ marginBottom: "10px" }}>Mobipesa Representative: {oneloan.mobipesa_name}</p>
                                            <p className="profile__name" style={{ marginBottom: "10px" }}>Designation: {oneloan.mobipesa_designation}</p>

                                            <Row>

                                                <Col md={12} lg={12}>
                                                    <Row>

                                                        <>
                                                            <a href={oneloan.applicantion_form}>
                                                                <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                    Application Form
                                                                </Button>
                                                            </a>&nbsp;&nbsp;

                                                            <a href={oneloan.statements} >
                                                                <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                    Personal/Business Mpesa Statement
                                                                </Button>
                                                            </a>&nbsp;&nbsp;

                                                            <a href={oneloan.kra_certificate}>
                                                                <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                    KRA Certificate
                                                                </Button>
                                                            </a>
                                                            <a href={oneloan.business_permit}>
                                                                <Button className="pull-left btn-sm btn btn-primary" style={{ color: "white" }}>
                                                                    Business Permit
                                                                </Button>
                                                            </a>
                                                        </>

                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    {/* 
                                                title: 'Mobipesa Representative',
                                                        id_no: `Name: ${oneloan.mobipesa_name}`,
                                                        msisdn: `Designation: ${oneloan.mobipesa_designation}`,
                                                     */}

                                    <ProductItems items={
                                        [
                                            {
                                                id: 2,
                                                src: `${location.state.id_front_photo}`,
                                                title: 'Borrower ID Front Page',
                                                colors: [],
                                            },
                                            // let id_front_photo = { :  };
                                            {
                                                id: 4,
                                                src: `${oneloan.next_to_cow === null ? location.state.next_to_cow : oneloan.next_to_cow}`,
                                                title: `Borrow's and Gurantor next to cow`,
                                                colors: [],
                                            },
                                            {
                                                id: 4,
                                                src: `${oneloan.guarantor_id_photo}`,
                                                title: `Gurantor: ${oneloan.guarantor_name}`,
                                                id_no: `ID No: ${oneloan.g_id_no}`,
                                                msisdn: `Phone: ${oneloan.guarantor_msisdn}`,
                                                colors: [],
                                            },
                                            {
                                                id: 5,
                                                src: `${oneloan.guarantor_id_front_page}`,
                                                title: 'Gurantor ID Front Page',
                                                colors: [],
                                            },


                                            {
                                                id: 5,
                                                src: `${oneloan.guarantor_id_2nd_photo}`,
                                                title: `2nd Gurantor: ${oneloan.guarantor_name_2}`,
                                                id_no: `ID No: ${oneloan.g_id_no_2}`,
                                                msisdn: `Phone: ${oneloan.guarantor_msisdn_2}`,

                                                colors: [],
                                            },
                                            {
                                                id: 6,
                                                src: `${oneloan.guarantor_id_2nd_front_page}`,
                                                title: '2nd Gurantor ID Front Page',
                                                colors: [],
                                            },


                                            {
                                                id: 8,
                                                src: `${oneloan.guarantor_id_3rd_photo}`,
                                                title: `3rd Gurantor: ${oneloan.guarantor_name_3}`,
                                                id_no: `ID No: ${oneloan.g_id_no_3}`,
                                                msisdn: `Phone: ${oneloan.guarantor_msisdn_3}`,
                                                colors: [],
                                            },
                                            {
                                                id: 9,
                                                src: `${oneloan.guarantor_id_3rd_front_page}`,
                                                title: ' 3rd Gurantor ID Front Page',
                                                colors: [],
                                            },

                                            {
                                                id: 11,
                                                src: `${oneloan.witness_id_photo}`,
                                                title: `Witness 1 Family Member/Neighbour`,

                                                msisdn: `Name: ${oneloan.witness_name}`,
                                                id_no: `ID No: ${oneloan.w_id_no}`,

                                                colors: [],
                                            },
                                            {
                                                id: 12,
                                                src: `${oneloan.witness_id_front_page}`,
                                                title: 'Witness ID Front Page',
                                                colors: [],
                                            },

                                        ]


                                    } />


                                    {location.state.mloan_status === 1 ?
                                        ROLE === "4" ?
                                            <button className="btn btn-success btn-sm pull-right"
                                                title="Approve loan"
                                                style={
                                                    { marginRight: '10px' }}

                                                onClick={
                                                    () => { if (window.confirm('Are you sure you want to approve/reject this loan?')) loanHome(oneloan) }} >

                                                NEXT
                                            </button>
                                            : null
                                        : null
                                    }

                                    {oneloan.loan_status === "Accepted" ?
                                        <>

                                            {location.state.mloan_status === 3 ?
                                                ROLE === "3" ?
                                                    <button className="btn btn-success btn-sm pull-right"
                                                        title="Approve loan"
                                                        style={
                                                            { marginRight: '10px' }}

                                                        onClick={
                                                            () => { if (window.confirm('Are you sure you want to approve/reject this loan?')) loanHome(oneloan) }} >

                                                        NEXT
                                                    </button>
                                                    : null
                                                :
                                                <>
                                                    {location.state.mloan_status === 5 ?

                                                        ROLE === "2" ?
                                                            <button className="btn btn-success btn-sm pull-right"
                                                                title="Approve loan"
                                                                style={
                                                                    { marginRight: '10px' }}

                                                                onClick={
                                                                    () => { if (window.confirm('Are you sure you want to approve/reject this loan?')) loanHome(oneloan) }} >

                                                                NEXT
                                                            </button>
                                                            : null
                                                        : null}
                                                    {location.state.mloan_status === 6 ?

                                                        ROLE === "1" ?
                                                            <>
                                                                <button className="btn btn-success btn-sm pull-right"
                                                                    title="Approve loan"
                                                                    style={
                                                                        { marginRight: '10px' }}

                                                                    onClick={
                                                                        () => { if (window.confirm('Are you sure you want to approve/reject this loan?')) loanHome(oneloan) }} >

                                                                    NEXT
                                                                </button>

                                                            </>
                                                            : null
                                                        : null}

                                                </>
                                            }

                                        </>
                                        :
                                        <h2 style={{ color: "" }}>v</h2>
                                    }



                                </>
                                :
                                <>

                                    <div className="row">
                                        <div className="col-6">
                                            <h4><b>Loan Profiles </b></h4>
                                        </div>

                                        <div className="col-6">
                                            <Link to="/find_farmer">
                                                <Button className="pull- btn-sm" outline color="primary">
                                                    Swtich back
                                                </Button>
                                            </Link>
                                        </div>
                                        {showerror ? <div >
                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{statusMessage}</span>
                                                </p>
                                            </Alert>
                                        </div> : null}
                                        {success ? (
                                            <div>
                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{statusMessage}</span>
                                                    </p>
                                                </Alert>
                                            </div>

                                        ) : null}
                                        <Table responsive hover>


                                            <ReactDatatable
                                                records={loan}
                                                columns={columns}
                                                dynamic={false}
                                                id="tsc"
                                                loading={isLoading} />
                                        </Table>
                                    </div>

                                </>
                            }

                        </div>
                    </CardBody >


                </Card >
            </>
        </div >
    )

}
export default Loan;
