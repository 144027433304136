import React, { useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, CONFIG, formatCurrency } from '../../configs/exports';
import Loader from "react-loader-spinner";
import "../../_assets/css/csv.css";
import * as moment from 'moment';
import {
    Card, CardBody
} from 'reactstrap';
// import EditApplicant from './EditApplicant';



const LoanAuction = (props) => {
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [smsLog, setSMSLog] = useState([])



    // const [avatar, setAvatar] = useState("") // set profile picture

    const [queryString, setQueryString] = useState(props.loan_ref == null ? "" : "filter_value=" + props.loan_ref + "&page_number=1&page_size=20&sort_order=false")

    useEffect(() => {
        // consuming API based on active tab
        let sms_url = baseURL + "loan_auction?" + queryString;
        // setLoad(true)
        // tab 1 API call...
        axios.all([
            axios.get(sms_url, CONFIG),
        ]).then(axios.spread((auctionResponse) => {
            let data = [];
            if (auctionResponse.data.data.length > 0) {
                setLoad(false)
                for (let i = 0; i < auctionResponse.data.data.length; i++) {

                    // format amount
                    let amount = { m_amount: formatCurrency(auctionResponse.data.data[i].amount) };
                    let date = { dates: (moment.utc(auctionResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(date, amount, auctionResponse.data.data[i]));
                    setLoad(false)
                }
                setDataTotal(auctionResponse.data.total)
                setSMSLog(data)
            } else {
                setLoad(false)
                setSMSLog(data)
            }
        })).catch(error => {
            // alert(JSON.stringify(error.response))
        })
    }, [queryString]);

    const config = {
        key_column: "tsc",
        length_menu: [20, 50, 100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 20,
        button: {
            csv: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Filter here..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }


    //sms columns list

    const columns_sms = [
        {
            key: "loan_reference",
            TrOnlyClassName: 'cell',
            text: "Loan No#",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_amount",
            TrOnlyClassName: 'cell',
            text: "Amount",
            className: "tsc",
            align: "left"
        },
        {
            key: "description",
            TrOnlyClassName: 'cell',
            text: "Description",
            className: "tsc",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'cell', text: "Date Created",
            className: "tsc",
            align: "left"
        }
    ];




    // handles paginations
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column")
                    + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }


    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >

            <Card>
                <CardBody className="">
                    <br />
                    <div className="tabs tabs--bordered-top" >
                        <div className="tabs__wrap">

                            {isLoading === false ?

<>

                                    <div >
                                        Total Auctions: <b>{datatotal}</b>
                                        <br /><br />
                                    </div>

                                    <ReactDatatable
                                        config={config}
                                        records={smsLog}
                                        columns={columns_sms}
                                        dynamic={true}
                                        id="tsc"
                                        loading={isLoading}
                                        total_record={datatotal}
                                        onChange={tableChangeHandler} />

                                </>

                                :
                                <div className="text-center h-50">
                                    <Loader
                                        type="Puff"
                                        color="#00BFFF"
                                        height={50}
                                        width={50} //3 secs
                                    />
                                </div>
                            }


                        </div>
                    </div>
                </CardBody>

            </Card>


        </div>
    );
}

export default LoanAuction;
