import React, { useState, useEffect } from 'react';

import ReactDatatable from '@mkikets/react-datatable';

import axios from 'axios';
import "../../_assets/css/file.css"

import { Card, CardBody } from 'reactstrap';
import { TOKEN } from '../../configs/exports';
import { baseURL, formatCurrency } from '../../configs/exports';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'


//rollover fuction

const VetReports = () => {

    const [instalment, setInstalment] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [queryString, setQueryString] = useState("")
    const start = moment(new Date("2020-02-01"));


    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(moment(new Date()))
    const [datevalue, setDateValue] = useState("Latest")

    const [expected_value, setValues] = useState({
        count: 0,
        total: 0,
        vet: 0,
        admin_fee: 0,
        legal_fee: 0,
        mpesa_charges: 0,
        animal_identification_fee: 0,
        amount_disbursed: 0,
        outstandings: 0,
        interest: 0,
        rollover_fee: 0,
        debt_collection: 0,
        insurance: 0
    })

    localStorage.setItem("STARTDATE", start)
    localStorage.setItem("ENDDATE", moment(new Date()))

    useEffect(() => {
        setLoad(true)

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': TOKEN
            },
        };
        let url = baseURL + `loan_issued?startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;

        axios.all([
            axios.get(url, config)
        ]).then(axios.spread((applicantResponse) => {
            let data = [];

            if (applicantResponse.data.data.length > 0) {
                setValues({
                    count: applicantResponse.data.total.count,
                    total: applicantResponse.data.total.total_amount,
                    vet: applicantResponse.data.total.vet_fee,
                    admin_fee: applicantResponse.data.total.admin_fee,
                    legal_fee: applicantResponse.data.total.legal_fee,
                    mpesa_charges: applicantResponse.data.total.mpesa_charges,
                    animal_identification_fee: applicantResponse.data.total.animal_identification_fee,
                    amount_disbursed: applicantResponse.data.total.amount_disbursed,
                    outstandings: applicantResponse.data.total.outstandings,
                    insurance: applicantResponse.data.total.insurance,
                    interest: applicantResponse.data.total.interest,
                    rollover_fee: applicantResponse.data.total.rollover_fee,
                    debt_collection: applicantResponse.data.total.debt_collection

                })

                // setAgent(agentResponse.data.data)
                // alert(  applicantResponse.data.data[0].application_date)
                setLoad(false)
                for (let i = 0; i < applicantResponse.data.data.length; i++) {

                    let mvet = {
                        mvet: formatCurrency(300)
                    };

                    let mlegal_fee = {
                        mlegal_fee: formatCurrency(200)

                    };

                    let mpesa_charges = {
                        mpesa_charges: formatCurrency(33)

                    };
                    let madmin_fee = {
                        madmin_fee: formatCurrency(300)

                    };
                    let manimal_identification_fee = {
                        manimal_identification_fee: formatCurrency(300)

                    };


                    let mloan_issued = {
                        mloan_issued: formatCurrency(applicantResponse.data.data[i].amount_disbursed)

                    };


                    let mtotal_amount = {
                        mtotal_amount: formatCurrency(applicantResponse.data.data[i].total_amount)

                    };


                    let mtotal_outstaning = {
                        mtotal_outstaning: formatCurrency(applicantResponse.data.data[i].outstandings)

                    };

                    let minterest = {
                        minterest: formatCurrency(applicantResponse.data.data[i].interest)

                    };

                    let mrollover_fee = {
                        mrollover_fee: formatCurrency(applicantResponse.data.data[i].rollover_fee)

                    };

                    let mdebt_collection = {
                        mdebt_collection: formatCurrency(applicantResponse.data.data[i].debt_collection)

                    };


                    let minsurance = {
                        minsurance: formatCurrency(applicantResponse.data.data[i].insurance)

                    };
                    let date = { dates: (moment.utc(applicantResponse.data.data[i].start_date).format('DD/MM/YYYY')) };

                    data.push(Object.assign(date,
                        mvet,
                        mloan_issued,
                        mtotal_amount,
                        mpesa_charges,
                        madmin_fee,
                        manimal_identification_fee,
                        mlegal_fee,
                        mtotal_outstaning,
                        mrollover_fee,
                        minterest,
                        mdebt_collection,
                        minsurance,
                        applicantResponse.data.data[i]));
                    setLoad(false)
                }
                setInstalment(data)
            } else {
                setLoad(false)

                setInstalment(data)
            }
        }))

    }, [startDate, queryString, endDate]);




    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true,
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here...",
            no_data_text: "There was no data this month",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    const applyCallback = (startDate, endDate) => {
        const Sdate1 = moment(startDate).format('DD MMM, YYYY');
        const Edate2 = moment(endDate).format('DD MMM, YYYY');
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)

        setDateValue(Sdate1 + " " + Edate2)
        setStartDate(startDate)
        setEndDate(endDate)
    }


    const ranges = {
        "Today Only": [moment(startDate), moment(endDate)],
        "Yesterday Only": [
            moment(startDate).subtract(1, "days"),
            moment(endDate).subtract(1, "days")
        ],
        "3 Days": [moment(startDate).subtract(3, "days"), moment(endDate)],
        "5 Days": [moment(startDate).subtract(5, "days"), moment(endDate)],
        "1 Week": [moment(startDate).subtract(7, "days"), moment(endDate)],
        "2 Weeks": [moment(startDate).subtract(14, "days"), moment(endDate)],
        "1 Month": [moment(startDate).subtract(1, "months"), moment(endDate)],
        "1 Year": [moment(startDate).subtract(1, "years"), moment(endDate)]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false
    }

    const columns = [

        {
            key: "name",
            text: "Borrower",
            TrOnlyClassName: 'cell',
            className: "cell",
            align: "left"
        },

        {
            key: "mloan_issued",
            TrOnlyClassName: 'cell',
            text: "Loan Issued",
            className: "cell",
            align: "left"
        },
        {
            key: "mtotal_amount",
            TrOnlyClassName: 'cell',
            text: "Total Amount",
            className: "cell",
            align: "left"
        },

        {
            key: "mtotal_outstaning",
            TrOnlyClassName: 'cell',
            text: "Principal",
            className: "cell",
            align: "left"
        },


        {
            key: "minsurance",
            TrOnlyClassName: 'cell',
            text: "INsurance",
            className: "cell",
            align: "left"
        },


        {
            key: "mrollover_fee",
            TrOnlyClassName: 'cell',
            text: "Rollover Fee",
            className: "cell",
            align: "left"
        },

        {
            key: "mdebt_collection",
            TrOnlyClassName: 'cell',
            text: "Debt Collection",
            className: "cell",
            align: "left"
        },




        {
            key: "mvet",
            TrOnlyClassName: 'cell',
            text: "Vet Fees",
            className: "cell",
            align: "left"
        },

        {
            key: "madmin_fee",
            TrOnlyClassName: 'cell',
            text: "Admin Fees",
            className: "cell",
            align: "left"
        },

        {
            key: "manimal_identification_fee",
            TrOnlyClassName: 'cell',
            text: "Animal fees",
            className: "cell",
            align: "left"
        },

        {
            key: "mlegal_fee",
            TrOnlyClassName: 'cell',
            text: "Legal Fee",
            className: "cell",
            align: "left"
        },

        {
            key: "mpesa_charges",
            TrOnlyClassName: 'cell',
            text: "Mpesa charges",
            className: "cell",
        },
        {
            key: "branch_name",
            TrOnlyClassName: 'cell',
            text: "Branch",
            className: "cell",
            align: "left"
        },

        {
            key: "start_date",
            TrOnlyClassName: 'cell',
            text: "Loan Start Date",
            className: "cell",
            align: "left"
        },

    ];
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');

        setQueryString(queryString)

        setStartDate(moment(startDate))
        setEndDate(moment(endDate))
    }

    return (
        <div style={{ marginTop: "-20px" }} >
            < >

                < Card >
                    <CardBody >
                        <div className="row">
                            <div className="col-md-6">
                                <h4> <b>Loan Profile</b></h4>
                                <br />
                            </div>
                            <div className="col-6">
                                Refresh <button className="float-" title="refresh" style={{ background: "white", border: "0px" }}
                                    onClick={
                                        () => {
                                            setQueryString(require("randomstring").generate({
                                                length: 1,
                                                charset: 'alphanumeric',
                                                capitalization: 'lowercase'
                                            }))
                                        }} >
                                    <RefreshIcon />
                                </button>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-6">
                                            <DateTimeRangeContainer
                                                ranges={ranges}
                                                start={startDate}
                                                end={endDate}
                                                local={local}
                                                applyCallback={applyCallback}>
                                                <FormControl
                                                    id="formControlsTextB"
                                                    type="text"
                                                    value={datevalue}
                                                    label="Text"
                                                    placeholder="Filter by Date"
                                                />
                                            </DateTimeRangeContainer>
                                        </div>
                                        <div className="col-6 float-left">

                                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {

                                                const start = moment(new Date("2020-02-01"));

                                                setDateValue("This Month")
                                                setStartDate(start)
                                                setEndDate(moment(new Date()))

                                            }} >Clear</button>
                                            {/* <input id="input" type="text" readOnly /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {/* <Link to="reports">
                                    <Button className="pull-right btn-sm" outline color="primary">
                                        Back to Repprts
                                    </Button>
                                </Link> */}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <br />
                                <h4> <b>Total Loan Approved: </b> {formatCurrency(expected_value.total)}</h4>
                                <h4> <b>Total Loan Issued: </b> {formatCurrency(expected_value.amount_disbursed)}</h4>
                                <h4> <b>Total Principal: </b> {formatCurrency(expected_value.outstandings)}</h4>
                                <h4> <b>Total Interest: </b> {formatCurrency(expected_value.interest)}</h4>
                                <h4> <b>Total Insurance : </b> {formatCurrency(expected_value.insurance)}</h4>
                                <h4> <b>Total Rollover: </b> {formatCurrency(expected_value.rollover_fee)}</h4>
                                <h4> <b>Total Debt Collection: </b> {formatCurrency(expected_value.debt_collection)}</h4>


                            </div>
                            <div className="col-6">
                                <br />

                                <h4> <b>Total Admin Fee: </b> {formatCurrency(expected_value.admin_fee)}</h4>


                                <h4> <b>Total Animal Identification Fee: </b> {formatCurrency(expected_value.animal_identification_fee)}</h4>
                                <h4> <b>Total Mpesa Charges: </b> {formatCurrency(expected_value.mpesa_charges)}</h4>



                            </div>
                        
                            <h4> <b>Total Loans: </b> {expected_value.count}</h4>

                        </div>
                        

                        


                        <br />
                        <div className="panel-body" >

                            <ReactDatatable
                                config={config}
                                records={instalment}
                                columns={columns}
                                dynamic={true}
                                id="tsc"
                                loading={isLoading}
                                total_record={expected_value.count}
                                onChange={tableChangeHandler} />
                        </div>
                    </CardBody>


                </Card>
            </>
        </div>
    )

}
export default VetReports;
