import * as React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
// import $ from 'jquery'
import Select from "react-select";
import { baseURL, NAME, MSISDN, ROLE, successToast, errorToast, ToastTable, CONFIG } from '../../configs/exports';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// import withRouter from '../App/Router/withRouter';
// window.$ = window.jQuery = require('jquery')
// require("http://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js")
require('../../_assets/jss/sms_counter.js')
// const history = useHistory();



const SubmitLoanStatus = () => {

    const location = useLocation();
    const [isLoading, setIsLoading] = React.useState(false);
    // eslint-disable-next-line
    const [terms_channel, setChannelTerms] = React.useState([{
        label: 'Accepted',
        value: 'Accepted',
    },
    {
        label: 'Rejected',
        value: "Rejected"
    },
    {
        label: 'Variete',
        value: "Variete"
    }
    ]);

    const [succesStatus, setSuccessStatus] = React.useState(false);

    const [channel, setChannel] = React.useState('');
    const [amount_disbursed, setAmountDisbursed] = React.useState(location.state.amount_disbursed + 1133);

    const [status_amount, setStatusAmount] = React.useState(0);

    const [message, setMessage] = React.useState('');


    const handleChangeChannel = value => {

        if (value != null) {

            setChannel(value.value);
        } else {
            setChannel('');
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        let final_status;
        let narative;

        if (location.state.mloan_status === 1) {
            final_status = 3;
            narative = "Field Officer Feedback";
        }
        if (location.state.mloan_status === 3) {
            final_status = 5;
            narative = "Loan Officer Feedback";
        }
        if (location.state.mloan_status === 5) {
            final_status = 6;
            narative = "Admin Feedback";
        }
        if (location.state.mloan_status === 6) {
            final_status = 11;
            narative = "Final Admin Feedback";
        }

        let formData = {
            "loan_reference": location.state.loan_reference,
            "amount_disbursed": amount_disbursed - 1133,
            "created_by": NAME,
            "loan_status": final_status,
            "status": channel,
            "farmer_id": location.state.farmer_id,
            "msisdn": MSISDN,
            "name": NAME,
            "status_amount": status_amount,
            "livestock_id": location.state.livestock_id,
            "narative": narative,
            "description": message.replaceAll("’", "'"),
        }

        setIsLoading(true);
        setSuccessStatus(false);

        axios.post(baseURL + 'loan_stage_feedback', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }).then((response) => {
            if (status_amount === 1) {
                axios.post(baseURL + 'loan_value_change', formData, CONFIG).then((response) => {
                    successToast("Feedback Send Successfully")
                    setTimeout(() => setSuccessStatus(true), 2000);
                }, error => {
                    errorToast("ERROR!")
                });
            } else {


                successToast(response.data.message)
                setTimeout(() => setSuccessStatus(true), 2000);
            }
        }, error => {
            errorToast("error")
        });
    }


    const handleChange = (e) => {
        setMessage(e.target.value);
    }

    const handleChangeAmount = (e) => {

        setAmountDisbursed(e.target.value);
        setStatusAmount(1);
    }


    React.useEffect(() => {
        // $('#message').countSms('#sms-counter');
    })


    const navigate = useNavigate();
    return (

        <div className="pcoded-main-container" >

            <ToastTable />
            {
                succesStatus === true ?
                    <Navigate to="/livestock" />
                    :
                    <>

                        <Col className="col-md-10 offset-md-1" >

                            <Card >
                                <CardBody >
                                    <br />
                                    <div className="row">
                                        <div className="col-6">location
                                            <h4 className="form-label" > Submit Loan Status Ref# <b>{location.state.loan_reference}</b> </h4>
                                        </div>
                                        <div className="col-6">

                                            <Button className="pull-right btn-sm" outline onClick={() => navigate(-1)} color="primary">
                                                Go back
                                            </Button>
                                        </div>
                                    </div>

                                    <form onSubmit={onSubmit} >

                                        <br />

                                        <br />
                                        <div className="col-md-8" >

                                            <Select isClearable options={terms_channel}
                                                // onInputChange={this.loadOptions}
                                                placeholder="Select Your Status"
                                                autosize={true}
                                                onChange={handleChangeChannel}
                                                // isLoading={this.state.isLoading}
                                                // noOptionsMessage={() => 'nothing found'}
                                                // loadingMessage={() => 'searching...'}   //minor type-O here
                                                className="selected"
                                                menuPortalTarget={document.body}
                                                name="namffe" />
                                            <br />
                                        </div>
                                        {(ROLE === '2') && channel === 'Variete' ?
                                            <div className="form__form-group col-8">
                                                <br></br>
                                                <span className="form__form-group-label">Approved Amount</span>
                                                <div className="form__form-group-field">
                                                    <input
                                                        autoFocus
                                                        type="number"
                                                        name="amount_disbursed"
                                                        className="form-control"
                                                        style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                                                        placeholder="Enter Amount"
                                                        value={amount_disbursed}
                                                        onChange={handleChangeAmount}
                                                    />
                                                </div>

                                            </div>
                                            :
                                            null}

                                        <br />
                                        <span className="form__form-group-label">Reason</span>
                                        <br />

                                        <div className="form-group" >
                                            <div className="row" >
                                                <div className="col-md-8" >
                                                    <textarea name="message"
                                                        style={
                                                            { paddingTop: '20px' }}
                                                        onChange={handleChange}
                                                        className="col-md-12"
                                                        placeholder="type your reason here"
                                                        id="message"
                                                        cols="col-md-1"
                                                        rows="20" >
                                                    </textarea>
                                                </div>
                                                <div className="col-md-4" >

                                                    <ul id="sms-counter" >
                                                        <li > Length: <span class="length" > </span></li >
                                                        <li > Remaining: < span class="remaining" > </span></li >
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="col-md-12" >
                                            <Button type="submit"
                                                color="primary"
                                                outline className="btn-paypal btn-lg pull-right" > {isLoading ?
                                                    "Please Wait..." : "Submit "}
                                                <i className="fa fa-refresh" > </i></Button> &nbsp; &nbsp; &nbsp;
                                        </div>
                                    </form>

                                </CardBody>
                            </Card>
                        </Col>
                    </>
            }

        </div>
    )
}

export default SubmitLoanStatus;

