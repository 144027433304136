import * as React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
// import $ from 'jquery'
import { baseURL, successToast, errorToast, ToastTable, CONFIG } from '../../configs/exports';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// import withRouter from '../App/Router/withRouter';
// window.$ = window.jQuery = require('jquery')
// require("http://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js")
require('../../_assets/jss/sms_counter.js')
// const history = useHistory();



const AddCharges = () => {

    const location = useLocation();
    const [isLoading, setIsLoading] = React.useState(false);

    const [succesStatus, setSuccessStatus] = React.useState(false);
    const [status_amount, setStatusAmount] = React.useState(0);

    const [purpose, setPurpose] = React.useState('');

    const onSubmit = (e) => {
        e.preventDefault();

        let formData = {
            "application_fee": status_amount,
            "narrative": purpose,
            "reference": location.state.loan_reference,
            "loan_balance": location.state.loan_balance,
            "msisdn": location.state.applicant_msisdn,
            "name": location.state.applicant_name
        }


        setIsLoading(true);
        axios.post(baseURL + 'create_charge', formData, CONFIG).then((response) => {
            successToast("Success!")
            setIsLoading(false)
            setTimeout(() => setSuccessStatus(true), 2000);
        }, error => {
            errorToast("ERROR!")
            setIsLoading(false)
        });
    }
    const navigate = useNavigate();
    return (

        <div className="pcoded-main-container" >

            <ToastTable />
            {
                succesStatus === true ?
                    <Navigate to="/livestock" />
                    :
                    <>
                        <Col className="col-md-10 offset-md-1" >
                            <Card >
                                <CardBody >
                                    <br />
                                    <div className="row">
                                        <div className="col-6">
                                            <h4 className="form-label" > Add Charge Ref# <b> {location.state.applicant_name} {location.state.loan_reference}</b> </h4>
                                        </div>
                                        <div className="col-6">
                                            <Button className="pull-right btn-sm" outline onClick={() => navigate(-1)} color="primary">
                                                Go back
                                            </Button>
                                        </div>
                                    </div>

                                    <form onSubmit={onSubmit} >
                                        <br />
                                        <div className="form__form-group col-8">
                                            <span className="form__form-group-label">Amount</span>
                                            <div className="form__form-group-field">
                                                <input
                                                    autoFocus
                                                    type="number"
                                                    name="status_amount"
                                                    className="form-control"
                                                    style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                                                    placeholder="Enter Amount"
                                                    value={status_amount}
                                                    onChange={e => {
                                                        setStatusAmount(e.target.value)
                                                    }}
                                                />
                                            </div>

                                            <br></br>
                                            <span className="form__form-group-label">Charge Type</span>
                                            <div className="form__form-group-field">
                                                <input
                                                    autoFocus
                                                    type="text"
                                                    name="purpose"
                                                    className="form-control"
                                                    style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                                                    placeholder="Enter Purpose"
                                                    value={purpose}
                                                    onChange={e => {
                                                        setPurpose(e.target.value)
                                                    }}
                                                />
                                            </div>

                                        </div>

                                        <br />

                                        <div className="col-md-12" >
                                            <Button type="submit"
                                                color="primary"
                                                outline className="btn-paypal btn-lg pull-right" > {isLoading ?
                                                    "Please Wait..." : "Submit "}
                                                <i className="fa fa-refresh" > </i></Button> &nbsp; &nbsp; &nbsp;
                                        </div>
                                    </form>

                                </CardBody>
                            </Card>
                        </Col>
                    </>
            }

        </div>
    )
}

export default AddCharges;

