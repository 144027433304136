import React, { useState, useEffect } from 'react';

import ReactDatatable from '@mkikets/react-datatable';

import axios from 'axios';
import "../../_assets/css/file.css"

import { Card, CardBody } from 'reactstrap';
import { TOKEN } from '../../configs/exports';
import { baseURL, formatCurrency } from '../../configs/exports';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'


//rollover fuction

const PredictionDue = () => {

    const [instalment, setInstalment] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [queryString, setQueryString] = useState("")
    const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(moment(new Date()))
    const [datevalue, setDateValue] = useState("This Month")

    localStorage.setItem("STARTDATE", start)
    localStorage.setItem("ENDDATE", moment(new Date()))

    useEffect(() => {
        setLoad(true)

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': TOKEN
            },
        };
        let url = baseURL + `net_report?startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;

        axios.all([
            axios.get(url, config)
        ]).then(axios.spread((applicantResponse) => {
            let data = [];
            console.log("total loans", url)

            if (applicantResponse.data.data.length > 0) {
                // setAgent(agentResponse.data.data)
                // alert(  applicantResponse.data.data[0].application_date)
                setLoad(false)
                if (applicantResponse.data.data.length > 0) {
                    for (let i = 0; i < applicantResponse.data.data.length; i++) {

                        let date = { dates: (moment.utc(applicantResponse.data.data[i].due_date).format('DD/MM/YYYY')) };

                        let t_net;

                        let a_net;
                        let f_name;

                        let f_b_name;
                        let f_msisdn;

                        if (i === (applicantResponse.data.data.length - 1)) {
                            f_name = { f_name: "TOTAL" };
                            f_b_name = { f_b_name: "_" };
                            f_msisdn = { f_msisdn: "_" };
                        } else {
                            f_name = { f_name: (applicantResponse.data.data[i].name) };
                            f_b_name = { f_b_name: (applicantResponse.data.data[i].branch_name) };

                            f_msisdn = { f_msisdn: (applicantResponse.data.data[i].msisdn) };
                        }

                        t_net = { t_net: (formatCurrency(applicantResponse.data.data[i].total_net)) };
                        a_net = { a_net: (formatCurrency(applicantResponse.data.data[i].actual_net)) };


                        let a_collect = { a_collect: (formatCurrency(applicantResponse.data.data[i].actual_collection)) };

                        let e_collect = { e_collect: (formatCurrency(applicantResponse.data.data[i].expected_collection)) };
                        let l_issued = { a_net: (formatCurrency(applicantResponse.data.data[i].loan_issued)) };


                        let e_principal = { e_principal: (formatCurrency(applicantResponse.data.data[i].expected_principal)) };
                        let a_principal = { a_principal: (formatCurrency(applicantResponse.data.data[i].principal_balance)) };


                        let e_int = { e_int: (formatCurrency(applicantResponse.data.data[i].expected_interest)) };
                        let a_int = { a_int: (formatCurrency(applicantResponse.data.data[i].balance_interest)) };


                        let t_charges = { t_charges: (formatCurrency(applicantResponse.data.data[i].expected_total_charges)) };
                        let a_charges = { a_charges: (formatCurrency(applicantResponse.data.data[i].actual_caharges)) };


                        let t_insurance = { t_insurance: (formatCurrency(applicantResponse.data.data[i].expected_insurance)) };
                        let a_insurance = { a_insurance: (formatCurrency(applicantResponse.data.data[i].actual_insurance)) };


                        let t_rollover = { t_rollover: (formatCurrency(applicantResponse.data.data[i].expected_rollover)) };
                        let a_rollover = { a_rollover: (formatCurrency(applicantResponse.data.data[i].actual_rollover)) };

                        let t_depth_collction = { t_depth_collction: (formatCurrency(applicantResponse.data.data[i].expected_depth_collection)) };
                        let a_depth_collection = { a_depth_collection: (formatCurrency(applicantResponse.data.data[i].actual_depth_collection)) };




                        data.push(Object.assign(date, e_collect, t_insurance, a_insurance, t_rollover, a_rollover, t_depth_collction, a_depth_collection, a_collect, a_charges, e_principal, a_principal, e_int, a_int, t_charges, l_issued, f_name, f_msisdn, f_b_name, t_net, a_net, applicantResponse.data.data[i]));

                        setLoad(false)
                    }
                }
                setInstalment(data)
            } else {
                setLoad(false)

                setInstalment(data)
            }
        }))

    }, [startDate, queryString, endDate]);




    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true,
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "SearcH BRANCH here...",
            no_data_text: "There was no data this month",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    const applyCallback = (startDate, endDate) => {
        const Sdate1 = moment(startDate).format('DD MMM, YYYY');
        const Edate2 = moment(endDate).format('DD MMM, YYYY');
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)

        setDateValue(Sdate1 + " " + Edate2)
        setStartDate(startDate)
        setEndDate(endDate)
    }


    const ranges = {
        "Today Only": [moment(startDate), moment(endDate)],
        "Yesterday Only": [
            moment(startDate).subtract(1, "days"),
            moment(endDate).subtract(1, "days")
        ],
        "3 Days": [moment(startDate).subtract(3, "days"), moment(endDate)],
        "5 Days": [moment(startDate).subtract(5, "days"), moment(endDate)],
        "1 Week": [moment(startDate).subtract(7, "days"), moment(endDate)],
        "2 Weeks": [moment(startDate).subtract(14, "days"), moment(endDate)],
        "1 Month": [moment(startDate).subtract(1, "months"), moment(endDate)],
        "1 Year": [moment(startDate).subtract(1, "years"), moment(endDate)]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false
    }

    const columns = [

        {
            key: "f_name",
            text: "Borrower",
            TrOnlyClassName: 'cell',
            className: "cell",
            align: "left"
        },

        {
            key: "f_msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone",
            className: "cell",
            align: "left"
        },
        {
            key: "f_b_name",
            TrOnlyClassName: 'cell',
            text: "Branch Name",
            className: "cell",
            align: "left"
        },
        {
            key: "e_collect",
            TrOnlyClassName: 'cell',
            text: "Expected Collection",
            className: "cell",
            align: "left"
        },
        {
            key: "a_collect",
            TrOnlyClassName: 'cell',
            text: "OutStanding Balances",
            className: "cell",
            align: "left"
        },
        {
            key: "e_principal",
            TrOnlyClassName: 'cell',
            text: "Expected Principal",
            className: "cell",
            align: "left"
        },
        {
            key: "a_principal",
            TrOnlyClassName: 'cell',
            text: "Actual Principal",
            className: "cell",
            align: "left"
        },
        {
            key: "e_int",
            TrOnlyClassName: 'cell',
            text: "Expected Interest",
            className: "cell",
            align: "left"
        },
        {
            key: "a_int",
            TrOnlyClassName: 'cell',
            text: "Actual Interest",
            className: "cell",
            align: "left"
        },
        {
            key: "t_insurance",
            TrOnlyClassName: 'cell',
            text: "Total Insurance",
            className: "cell",
            align: "left"
        },

        
        
        {
            key: "a_rollover",
            TrOnlyClassName: 'cell',
            text: "Actual Rollover",
            className: "cell",
            align: "left"
        },
        {
            key: "t_rollover",
            TrOnlyClassName: 'cell',
            text: "Total Rollover",
            className: "cell",
            align: "left"
        },

        {
            key: "a_rollover",
            TrOnlyClassName: 'cell',
            text: "Actual Rollover",
            className: "cell",
            align: "left"
        },
        {
            key: "t_depth_collction",
            TrOnlyClassName: 'cell',
            text: "Total Debt Collection",
            className: "cell",
            align: "left"
        },
 
        
       
        {
            key: "a_depth_collection",
            TrOnlyClassName: 'cell',
            text: "Actual Debt Collection",
            className: "cell",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'cell',
            text: "Loan Start Date",
            className: "cell",
            align: "left"
        },

    ];
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');

        setQueryString(queryString)

        setStartDate(moment(startDate))
        setEndDate(moment(endDate))
    }

    return (
        <div style={{ marginTop: "-20px" }} >
            < >

                < Card >
                    <CardBody >
                        <div className="row">
                            <div className="col-md-6">
                                <h4> <b>Due Predictions</b></h4>
                                <br />
                            </div>
                            <div className="col-6">
                                Refresh <button className="float-" title="refresh" style={{ background: "white", border: "0px" }}
                                    onClick={
                                        () => {
                                            setQueryString(require("randomstring").generate({
                                                length: 1,
                                                charset: 'alphanumeric',
                                                capitalization: 'lowercase'
                                            }))
                                        }} >
                                    <RefreshIcon />
                                </button>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-6">
                                            <DateTimeRangeContainer
                                                ranges={ranges}
                                                start={startDate}
                                                end={endDate}
                                                local={local}
                                                applyCallback={applyCallback}>
                                                <FormControl
                                                    id="formControlsTextB"
                                                    type="text"
                                                    value={datevalue}
                                                    label="Text"
                                                    placeholder="Filter by Date"
                                                />
                                            </DateTimeRangeContainer>
                                        </div>
                                        <div className="col-6 float-left">

                                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {

                                                const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));

                                                setDateValue("This Month")
                                                setStartDate(start)
                                                setEndDate(moment(new Date()))

                                            }} >Clear</button>
                                            {/* <input id="input" type="text" readOnly /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {/* <Link to="reports">
                                    <Button className="pull-right btn-sm" outline color="primary">
                                        Back to Repprts
                                    </Button>
                                </Link> */}

                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="panel-body" >

                            <ReactDatatable
                                config={config}
                                records={instalment}
                                columns={columns}
                                dynamic={true}
                                id="tsc"
                                loading={isLoading}
                                onChange={tableChangeHandler} />
                        </div>
                    </CardBody>


                </Card>
            </>
        </div>
    )

}
export default PredictionDue;
