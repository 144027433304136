import React, { useState, Fragment, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, BRANCH_NAME, CONFIG, formatCurrency, ROLE, USER_ID } from '../../configs/exports';
import Loader from "react-loader-spinner";
import "../../_assets/css/csv.css";
import * as moment from 'moment';
import {
    Card, CardBody,
    Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import Detail_Loan_Report from './DetailLoanReport';

// import EditApplicant from './EditApplicant';



const LoanReport = () => {

    const [instalment, setInstalment] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")
    // const [applicsntdetails, setApplicantDetails] = useState(false)
    const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(moment(new Date()))
    const [datevalue, setDateValue] = useState("This Month")

    const [home, setLoanHome] = useState(false)

    const [expected_amount, setExpectedAmount] = useState(0);
    const [paid_amount, setPaidAmount] = useState(0);
    const [balance_amount, setBalanceAmount] = useState(0);



    const [m_percentage_collected, setTotalCollected] = useState(0)
    // const [total_uncollected, setUncollected] = useState(0)
    // // const [expected, setExpected] = useState(0)

    const [farmerProps, setFarmerProps] = useState([])

    localStorage.setItem("STARTDATE", start)
    localStorage.setItem("ENDDATE", moment(new Date()))

    // eslint-disable-next-line
    const [activeTab, setActiveTab] = useState("1")

    // const [avatar, setAvatar] = useState("") // set profile picture
    useEffect(() => {
        let statment_url;
        if (activeTab === "1") {
            statment_url = baseURL + `loan_report?period=2&lock_period=2&days=60&user_id=${USER_ID}&&role_id=${ROLE}&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;
        }
        // 30 days
        if (activeTab === "2") {
            statment_url = baseURL + `loan_report?period=4&lock_period=4&days=30&user_id=${USER_ID}&&role_id=${ROLE}&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;
        }
        else if (activeTab === "3") {
            statment_url = baseURL + `loan_report?period=6&lock_period=8&days=0&user_id=${USER_ID}&&role_id=${ROLE}&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;
        }

        // if (activeTab === "4") {
        //     statment_url = baseURL + `loan_report?period=1&lock_period=0&days=90&user_id=${USER_ID}&&role_id=${ROLE}&startDate="${moment(startDate).format('YYYY-MM-DD')}"&endDate="${moment(endDate).format('YYYY-MM-DD')}"&${queryString}`;
        // }



        axios.all([
            axios.get(statment_url, CONFIG),
        ]).then(axios.spread((unmaturedResponse) => {
            let data = [];
            // alert(JSON.stringify(unmaturedResponse.data))
            let summary_report;
            if (ROLE === '1' || ROLE === '2') {
                summary_report = unmaturedResponse.data.data
            } else {
                summary_report = unmaturedResponse.data.data.filter(person => person.branch_name === BRANCH_NAME)
            }


            if (summary_report.length > 0) {
                setLoad(false)

                /**
                * @description If list is
                */
                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))

                let p = 0;
                if (id > 1) {
                    p = (((id - 1) * parseInt(params.get("page_size"))))
                }


                let valueExpected = 0
                let countExpected = 0


                let valueCollected = 0
                let countCollected = 0

                let valueBalance = 0
                let countBalance = 0


                for (let i = 0; i < unmaturedResponse.data.data.length; i++) {
                    // alert(unmaturedResponse.data.data.length)
                    p++;
                    let unrow = { row: p }

                    let date;


                    let m_expected, m_collected, m_uncollected,
                        minstalment_balance, m_issued;

                    let mdays, mperiod, mlock_period, m_percentage, mdates_next;


                    if (activeTab === "1") {
                        mdays = { mdays: 60 };

                        mperiod = { mperiod: 2 };

                        mlock_period = { mlock_period: 2 };
                        date = { dates: (moment.utc(summary_report[i].due_date).subtract(60, 'days').format('DD/MM/YYYY HH:mm:ss')) };

                        mdates_next = { mdates_next: (moment.utc(summary_report[i].due_date).subtract(60, 'days')) };

                        countExpected = (summary_report[i].first_month)
                        valueExpected += countExpected //expected

                        countCollected = (summary_report[i].collected)
                        valueCollected += countCollected //collected


                        countBalance = (summary_report[i].first_month - summary_report[i].collected)
                        valueBalance += countBalance //collected

                        m_issued = { m_issued: formatCurrency(summary_report[i].amount_disbursed) }

                        m_expected = { m_expected: formatCurrency(summary_report[i].first_month) }
                        m_collected = { m_collected: formatCurrency(summary_report[i].collected) }
                        m_uncollected = { m_uncollected: formatCurrency(summary_report[i].first_month - summary_report[i].collected) }

                        minstalment_balance = { minstalment_balance: formatCurrency((summary_report[i].first_month) - (summary_report[i].collected)) }

                        m_percentage = { m_percentage: ((summary_report[i].collected / summary_report[i].first_month) * 100).toFixed(2) }
                        minstalment_balance = { minstalment_balance: formatCurrency(summary_report[i]["1_inst"]) }

                    }
                    else if (activeTab === "2") {

                        date = { dates: (moment.utc(summary_report[i].due_date).subtract(30, 'days').format('DD/MM/YYYY HH:mm:ss')) };
                        mdates_next = { mdates_next: (moment.utc(summary_report[i].due_date).subtract(30, 'days')) };

                        mdays = { mdays: 30 };

                        mperiod = { mperiod: 4 };

                        mlock_period = { mlock_period: 4 };

                        m_issued = { m_issued: formatCurrency(summary_report[i].amount_disbursed) }

                        countExpected = (summary_report[i].second_month)
                        valueExpected += countExpected //expected

                        countCollected = (summary_report[i].collected)
                        valueCollected += countCollected //collected


                        countBalance = (summary_report[i].second_month - summary_report[i].collected)
                        valueBalance += countBalance //collected


                        m_expected = { m_expected: formatCurrency(summary_report[i].second_month) }
                        m_collected = { m_collected: formatCurrency(summary_report[i].collected) }
                        m_uncollected = { m_uncollected: formatCurrency(summary_report[i].second_month - summary_report[i].collected) }
                        m_percentage = { m_percentage: ((summary_report[i].collected / summary_report[i].second_month) * 100).toFixed(2) }


                        // m_rollover_fee = { m_uncollected: formatCurrency(summary_report[i].due_collected) }
                        // m_debt_collection = { m_uncollected: formatCurrency(summary_report[i].due_collected) }

                        minstalment_balance = { minstalment_balance: formatCurrency((summary_report[i].second_month) - (summary_report[i].collected)) }

                    }

                    else if (activeTab === "3") {
                        mdays = { mdays: 0 };

                        m_issued = { m_issued: formatCurrency(summary_report[i].amount_disbursed) }

                        //  m_issued= { m_issued: formatCurrency(summary_report[i].expected - summary_report[i]["1_inst"] + summary_report[i]["2_inst"] +summary_report[i]["3_inst"] +summary_report[i].debt_collection+summary_report[i].rollover_fee) }

                        mdates_next = { mdates_next: (moment.utc(summary_report[i].due_date).subtract(60, 'days')) };

                        mperiod = { mperiod: 6 };

                        mlock_period = { mlock_period: 8 };

                        date = { dates: (moment.utc(summary_report[i].due_date).subtract(0, 'days').format('DD/MM/YYYY HH:mm:ss')) };

                        countExpected = (summary_report[i].expected)
                        valueExpected += countExpected //expected

                        countCollected = (summary_report[i].collected)
                        valueCollected += countCollected //collected


                        countBalance = (summary_report[i].due_collected)
                        valueBalance += countBalance //collected


                        m_expected = { m_expected: formatCurrency(summary_report[i].expected) }
                        m_collected = { m_collected: formatCurrency(summary_report[i].collected) }
                        m_uncollected = { m_uncollected: formatCurrency(summary_report[i].due_collected) }

                        minstalment_balance = { minstalment_balance: formatCurrency(summary_report[i].due_collected) }

                        m_percentage = { m_percentage: ((summary_report[i].collected / summary_report[i].expected) * 100).toFixed(2) }

                        // minstalment_balance = { minstalment_balance: formatCurrency((summary_report[i].first_month) + (summary_report[i].collected)) }



                    }


                    let date_start = { dates_start: (moment.utc(summary_report[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };


                    data.push(Object.assign(date,
                        date,
                        m_expected,
                        m_collected,
                        m_uncollected,
                        mdays,
                        mperiod,
                        date_start,
                        m_issued,
                        m_percentage,
                        mdates_next,
                        mlock_period,
                        minstalment_balance,
                        unrow,
                        summary_report[i]));
                    setLoad(false)
                }

                // eslint-disable-next-line


                console.log("Check null", data);

                setInstalment(data);
                setDataTotal(unmaturedResponse.data.total);

                setExpectedAmount(valueExpected)
                setPaidAmount(valueCollected)

                setTotalCollected(((valueCollected / valueExpected) * 100).toFixed(2))

                setBalanceAmount(valueBalance)

            } else {
                setLoad(false)
                setInstalment(data)
            }
        })).catch(error => {
            // alert(JSON.stringify(error.response))
        })

    }, [startDate, queryString, endDate, activeTab]);

    // const locale = 'en-US';
    // const formatCurrency = amount => {
    //     return new Intl.NumberFormat(locale, {
    //         style: 'currency',
    //         currency: "ABS",
    //         minimumFractionDigits: 2,
    //         maximumFractionDigits: 2
    //     }).format(amount).replaceAll('ABS', "");
    // };




    // go home
    const loanHome = (record) => {
        // alert(JSON.stringify(record))
        setLoanHome(true)
        setFarmerProps(record);

    }
    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true,
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here",
            no_data_text: "There was no data this month",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    const applyCallback = (startDate, endDate) => {
        const Sdate1 = moment(startDate).format('DD MMM, YYYY');
        const Edate2 = moment(endDate).format('DD MMM, YYYY');
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)
        setDateValue(Sdate1 + " " + Edate2)
        setStartDate(startDate)
        setEndDate(endDate)
    }


    const ranges = {
        "Today Only": [moment(startDate), moment(endDate)],
        "Yesterday Only": [
            moment(startDate).subtract(1, "days"),
            moment(endDate).subtract(1, "days")
        ],
        "3 Days": [moment(startDate).subtract(3, "days"), moment(endDate)],
        "5 Days": [moment(startDate).subtract(5, "days"), moment(endDate)],
        "1 Week": [moment(startDate).subtract(7, "days"), moment(endDate)],
        "2 Weeks": [moment(startDate).subtract(14, "days"), moment(endDate)],
        "1 Month": [moment(startDate).subtract(1, "months"), moment(endDate)],
        "1 Year": [moment(startDate).subtract(1, "years"), moment(endDate)]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false
    }

    const columns = [
        // {
        //     key: "row",
        //     TrOnlyClassName: 'cell',
        //     text: "#",
        //     className: "cell",
        //     align: "left"
        // },
        {
            key: "branch_name",
            TrOnlyClassName: 'cell',
            text: "Branch",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_expected",
            TrOnlyClassName: 'cell',
            text: "Total Expected",
            className: "tsc",
            align: "left"
        },

        // {
        //     key: "m_issued",
        //     TrOnlyClassName: 'cell',
        //     text: "Total Issued",
        //     className: "tsc",
        //     align: "left"
        // },
        {
            key: "m_collected",
            TrOnlyClassName: 'cell',
            text: "Total Collected",
            className: "tsc",
            align: "left"
        },

        {
            key: "minstalment_balance",
            TrOnlyClassName: 'cell',
            text: "Cuurent Instalment Balance",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_uncollected",
            TrOnlyClassName: 'cell',
            text: "Total Due Collected",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_percentage",
            TrOnlyClassName: 'cell',
            text: "% Collected vs Expected",
            className: "tsc",
            align: "left"
        },
        {
            key: "dates_start",
            TrOnlyClassName: 'cell',
            text: "Loan Date",
            className: "cell",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'cell',
            text: "Due Date",
            className: "cell",
            align: "left"
        },
        {
            key: "action",
            text: "Actions",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >



                        <button className="btn btn-primary btn-sm"
                            title="View pics Details"
                            style={
                                { marginRight: '10px' }}

                            onClick={() => { loanHome(record) }}>
                            More <i className='fa fa-plus'></i>
                        </button>

                    </Fragment >
                );
            }
        },

    ];
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');

        setQueryString(queryString)

        setStartDate(moment(startDate))
        setEndDate(moment(endDate))
    }

    const toggle = (activeTab) => {

        setActiveTab(activeTab)

    }


    // const rowClickedHandler = (data) => {

    //     // alert(JSON)
    //     setFarmerProps(data);
    //     setApplicantDetails(true)

    // };

    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >

            {home === true ?

                // eslint-disable-next-line
                <Detail_Loan_Report farmer={farmerProps} />
                :
                < Card >
                    <CardBody >
                        <div className="tabs tabs--bordered-top" >
                            <div className="tabs__wrap">
                                <h4> <b>Collected, Uncollected, Due for collection</b></h4>
                                <br />

                                {isLoading === false ?
                                    <>
                                        <div>
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '1' })}
                                                        onClick={() => {
                                                            toggle('1');
                                                        }}
                                                    >
                                                        1st Instalment
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '2' })}
                                                        onClick={() => {
                                                            toggle('2');
                                                        }}
                                                    >
                                                        2nd Instalment
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '3' })}
                                                        onClick={() => {
                                                            toggle('3');
                                                        }}
                                                    >
                                                        Passed 90 days
                                                    </NavLink>
                                                </NavItem>
                                                {/* <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '4' })}
                                                    onClick={() => {
                                                        toggle('4');
                                                    }}
                                                >
                                                    Passed 90 days
                                                </NavLink>
                                            </NavItem> */}

                                            </Nav>

                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="col-md-8">

                                                        <br />
                                                        <div className="row">

                                                            <div className="col-6">

                                                                <DateTimeRangeContainer
                                                                    ranges={ranges}
                                                                    start={startDate}
                                                                    end={endDate}
                                                                    local={local}
                                                                    applyCallback={applyCallback}>
                                                                    <FormControl
                                                                        id="formControlsTextB"
                                                                        type="text"
                                                                        value={datevalue}
                                                                        label="Text"
                                                                        placeholder="Filter by Date"
                                                                    />
                                                                </DateTimeRangeContainer>
                                                            </div>
                                                            <div className="col-6 float-left">

                                                                <button className=" float-left btn btn-primary btn-sm" onClick={e => {

                                                                    const start = moment(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));

                                                                    setDateValue("This Month")
                                                                    setStartDate(start)
                                                                    setEndDate(moment(new Date()))

                                                                }} >Clear</button>
                                                                {/* <input id="input" type="text" readOnly /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    {/* <Link to="reports">
                                    <Button className="pull-right btn-sm" outline color="primary">
                                        Back to Repprts
                                    </Button>
                                </Link> */}

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <br />

                                                    <h4> <b>Total Expected  Amount: </b> {formatCurrency(expected_amount)}</h4>
                                                    <h4>Total Collected Amount: <b style={{ color: "green" }}>{formatCurrency(Math.abs(paid_amount))}</b></h4>

                                                    <h4>Total Uncollected Amount: <b style={{ color: "red" }}>{formatCurrency(Math.abs(balance_amount))}</b></h4>

                                                    <h4>% Collected vs Expected: <b style={{ color: "green" }}>{(m_percentage_collected)} %</b></h4>

                                                </div>
                                                <div className="col-6">
                                                    <br />


                                                </div>
                                            </div>

                                            <br />



                                            <ReactDatatable
                                                config={config}
                                                records={instalment}
                                                columns={columns}
                                                dynamic={true}
                                                id="tsc"
                                                loading={isLoading}
                                                total_record={datatotal}
                                                onChange={tableChangeHandler} />
                                        </div>

                                    </>

                                    :
                                    <div className="text-center h-50">
                                        <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50} //3 secs
                                        />
                                    </div>
                                }


                            </div>
                        </div>
                    </CardBody>

                </Card>

            }
        </div >
    );
}

export default LoanReport;
