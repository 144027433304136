import React, { useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, BRANCH_NAME, CONFIG, formatCurrency, ROLE, useStyles } from '../../configs/exports';
import "../../_assets/css/csv.css";
import {
    Card, CardBody, Table
} from 'reactstrap';
// import EditApplicant from './EditApplicant';
import { Autorenew } from "@material-ui/icons";
import clsx from "clsx";



const SummaryReport = () => {
    const [isLoading, setLoad] = useState(true)
    const [summary_report, setSummaryReport] = useState([])

    const [queryString, setQueryString] = useState()

    const [total_collected, setTotalCollected] = useState(0)
    const [total_uncollected, setUncollected] = useState(0)
    const [expected, setExpected] = useState(0)

    // eslint-disable-next-line
    const [total_issued, setIssuedLoan] = useState(0)

    const [spin, setSpin] = useState(false);
    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };
    const classes = useStyles();

    useEffect(() => {
        // consuming API based on active tab
        let sms_url = baseURL + "loan_summary";
        // setLoad(true)
        // tab 1 API call...

        // alert(BRANCH_NAME)

 
        // alert(ROLE)
        axios.all([

            axios.get(sms_url, CONFIG),
        ]).then(axios.spread((repprtResponse) => {
            // alert(JSON.stringify(repprtResponse.data.data.filter(person => person.branch_name === BRANCH_NAME)))
            // alert(BRANCH_NAME)
            let summary_report;
            if (ROLE === '1' || ROLE === '2') {
                summary_report = repprtResponse.data.data
            } else {
                summary_report = repprtResponse.data.data.filter(person => person.branch_name === BRANCH_NAME)
            }


            let data = [];
            if (summary_report.length > 0) {


                setLoad(false)

                let valueExpected = 0
                let countExpected = 0


                let valueCollected = 0
                let countCollected = 0

                let valueBalance = 0
                let countBalance = 0

                let valueIssued = 0
                let countIssued = 0


                for (let i = 0; i < summary_report.length; i++) {


                    countExpected = (summary_report[i].expected)
                    valueExpected += countExpected //expected

                    countCollected = (summary_report[i].collected)
                    valueCollected += countCollected //collected


                    countBalance = (summary_report[i].due_collected)
                    valueBalance += countBalance //collected

                    countIssued = (summary_report[i].amount_disbursed)
                    valueIssued += countIssued //Issued

                    let m_expected = { m_expected: formatCurrency(summary_report[i].expected) }
                    let m_collected = { m_collected: formatCurrency(summary_report[i].collected) }
                    let m_uncollected = { m_uncollected: formatCurrency(summary_report[i].due_collected) }


                    let m_issued = { m_issued: formatCurrency(summary_report[i].amount_disbursed) }


                    let m_col_vs_exp = { m_col_vs_exp: (summary_report[i].col_vs_exp).toFixed(3) }
                    let m_uncol_vs_exp = { m_uncol_vs_exp: (summary_report[i].uncol_vs_exp).toFixed(3) }

                    data.push(Object.assign(m_collected, m_issued, m_expected, m_uncollected, m_uncol_vs_exp, m_col_vs_exp, summary_report[i]));
                    setLoad(false)
                }


                setSummaryReport(data)


                setTotalCollected(formatCurrency(valueCollected))
                setUncollected(formatCurrency(valueBalance))
                setExpected(formatCurrency(valueExpected))
                setIssuedLoan(formatCurrency(valueIssued))

            } else {
                setLoad(false)
                setSummaryReport(data)
            }
        })).catch(error => {
            // alert(JSON.stringify(error.response))
        })
    }, [queryString]);

    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500, 1000],
        show_filter: false,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        button: {
            csv: true,
            excel: true,
            print: true,
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Filter here..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }


    //sms columns list

    const columns_sms = [
        {
            key: "branch_name",
            TrOnlyClassName: 'cell',
            text: "Branch",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_expected",
            TrOnlyClassName: 'cell',
            text: "Total Expected",
            className: "tsc",
            align: "left"
        },

        // {
        //     key: "m_issued",
        //     TrOnlyClassName: 'cell',
        //     text: "Total Issued",
        //     className: "tsc",
        //     align: "left"
        // },


        {
            key: "m_collected",
            TrOnlyClassName: 'cell',
            text: "Total Collected",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_uncollected",
            TrOnlyClassName: 'cell',
            text: "Total Due Collected",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_col_vs_exp",
            TrOnlyClassName: 'cell',
            text: "% Collected vs Expected",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_uncol_vs_exp",
            TrOnlyClassName: 'cell',
            text: "% UnCollected vs Expected",
            className: "tsc",
            align: "left"
        }
    ];




    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >

            <Card>
                <CardBody>
                    <br />
                    <h4><b>Summary Report</b></h4>
                    <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}

                        onClick={
                            () => {
                                refreshCanvas()
                                setQueryString(require("randomstring").generate({
                                    length: 1,
                                    charset: 'alphanumeric',
                                    capitalization: 'lowercase'
                                }))
                            }}
                    >
                        Refresh
                        <Autorenew
                            className={clsx({
                                [classes.refresh]: true,
                                spin: spin
                            })}
                            onClick={
                                () => {
                                    refreshCanvas()
                                    setQueryString(require("randomstring").generate({
                                        length: 1,
                                        charset: 'alphanumeric',
                                        capitalization: 'lowercase'
                                    }))
                                }}
                            spin={360}
                        />

                    </button>

                    <div className="tabs tabs--bordered-top" >
                        <div className="tabs__wrap">
                            <Table>

                                <div >
                                    <h4>Expected to be collected: <b>{expected}</b></h4>
                                    {/* <h4>Total Issued: <b>{total_issued}</b></h4> */}
                                  
                                    <h4>Total Collected: <b>{total_collected}</b></h4>
                                    <h4>Total Uncollected: <b>{total_uncollected}</b></h4>

                                    <br /><br />
                                </div>

                                <ReactDatatable
                                    config={config}
                                    records={summary_report}
                                    columns={columns_sms}
                                    id="tsc"
                                    loading={isLoading} />

                            </Table>

                        </div>
                    </div>
                </CardBody>

            </Card>


        </div>
    );
}

export default SummaryReport;
