import React, { useState, Fragment, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { Card, CardBody, Table } from 'reactstrap';
import { baseURL, CONFIG, formatCurrency, ROLE, USER_ID } from '../../configs/exports';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
// import FarmerDetails from './FarmerDetails';
import { useNavigate } from 'react-router-dom';


//farmer hooks

const Farmers = () => {

    const navigate = useNavigate();

    const [farmer, setFarmer] = useState([])
    const [farmerProps, setFarmerProps] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")
    const [farmerDetails, setFarmerDetails] = useState(false)

    useEffect(() => {
        setLoad(true)

        let url = baseURL + `farmer?user_id=${USER_ID}&&role_id=${ROLE}&&` + queryString;
        // alert(url)
        axios.all([
            axios.get(url, CONFIG)
        ]).then(axios.spread((farmerResponse) => {
            let data = [];
            if (farmerResponse.data.data.length > 0) {
                setDataTotal(farmerResponse.data.data.length)
                setLoad(false)

                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))


                let p = farmerResponse.data.total + 1;
                if (id > 1) {
                    p = (parseInt(farmerResponse.data.total) - ((id - 1) * parseInt(params.get("page_size")))) + 1
                }
                /**
                * @description If list is
                */
                // let p = 0;
                // if (id > 1) {
                //      p = (((id-1) * parseInt(params.get("page_size"))))
                // }

                for (let i = 0; i < farmerResponse.data.data.length; i++) {
                    let f_amount = { f_amount: formatCurrency(parseFloat(farmerResponse.data.data[i].wallet_balance).toFixed(2)) };
                    p--;

                    let unrow = { row: p }

                    let date = { dates: (moment.utc(farmerResponse.data.data[i].created_on).format('DD/MM/YYYY')) };
                    data.push(Object.assign(date, unrow, f_amount, farmerResponse.data.data[i]));
                    setLoad(false)
                }
                setDataTotal(farmerResponse.data.total)
                setFarmer(data)
            } else {
                setLoad(false)
                setFarmer(data)
            }
        }))

    }, [queryString]);

   

    const config = {
        key_column: "tsc",
        length_menu: [20, 50, 100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 20,
        button: {
            csv: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }
    const columns = [
        {
            key: "row",
            TrOnlyClassName: 'cell',
            text: "#",
            className: "tsc",
            align: "left"
        },
        {
            key: "name",
            TrOnlyClassName: 'cell',
            text: "Name",
            className: "tsc",
            align: "left"
        },

        {
            key: "msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone#",
            className: "tsc",
            align: "left"
        },

        {
            key: "f_amount",
            TrOnlyClassName: 'cell',
            text: "Wallet#",
            className: "tsc",
            align: "left"
        },

        {
            key: "id_no",
            TrOnlyClassName: 'cell',
            text: "ID No.",
            className: "tsc",
            align: "left"
        },
        {
            key: "gender",
            TrOnlyClassName: 'cell',
            text: "Gender",
            className: "tsc",
            align: "left"
        },
        {
            key: "age",
            TrOnlyClassName: 'cell',
            text: "DoB",
            className: "tsc",
            align: "left",

        },
        {
            key: "location",
            TrOnlyClassName: 'cell',
            text: "Location",
            className: "tsc",
            align: "left",

        },

        {
            key: "branch_name",
            TrOnlyClassName: 'cell',
            text: "Branch Name",
            className: "tsc",
            align: "left"
        },


        {
            key: "dates",
            TrOnlyClassName: 'cell',
            text: "Date",
            className: "tsc",
            align: "left"
        },
        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "tsc",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >
                        {/* <Link to="/farmer_details" > */}

                        <button className="btn btn-success btn-sm"
                            title="View More"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { rowClickedHandler(record) }} >

                            More <i className="fa fa-plus"></i>
                        </button>
                        {/* </Link> */}
                    </Fragment>
                )
            }
        }
    ];

    // row clickerd
    const rowClickedHandler = (data) => {
        setFarmerProps(data);
        setFarmerDetails(true);
    };

    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }


    // return UI
    return (
        <div style={{ marginTop: "-20px" }} >
            < >
                {farmerDetails === true ?
                    <>
                        {navigate('/farmer_details', { state: farmerProps, replace: true })}
                    </> :
                    null}
                < Card >
                    <CardBody >
                        <Table responsive hover>
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4> <b>Farmers</b></h4>
                                    </div>
                                    <div className="col-6">
                                        <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}
                                            onClick={
                                                () => {
                                                    setQueryString(require("randomstring").generate({
                                                        length: 1,
                                                        charset: 'alphanumeric',
                                                        capitalization: 'lowercase'
                                                    }))
                                                }} >
                                            Refresh <RefreshIcon />
                                        </button>
                                    </div>
                                </div>
                                <br />
                                <div >
                                    Total Farmers: <b>{datatotal}</b>
                                    <br /><br />
                                </div>
                                <ReactDatatable
                                    config={config}
                                    records={farmer}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={datatotal}
                                    onChange={tableChangeHandler} />
                            </div>
                        </Table>
                    </CardBody>


                </Card>

            </>
        </div>
    )

}
export default Farmers;
