import React, { useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { Card, CardBody, Table } from 'reactstrap';
import { baseURL, CONFIG, errorToast, formatCurrency, ROLE, successToast, ToastTable, useStyles } from '../../configs/exports';
import * as moment from 'moment';
import { Autorenew } from "@material-ui/icons";
import clsx from "clsx";
import Modal from 'react-modal';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { MDBCloseIcon } from "mdbreact";
import 'react-toastify/dist/ReactToastify.css';
import { UpdateLoan } from '../Loan/updateLoan';



//farmer hooks

const RejectedLoans = () => {

    const [farmer, setFarmer] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")

    // const [open, isOpen] = React.useState(false);

    const [spin, setSpin] = useState(false);

    const [go_amend, setGoAMend] = useState(false);

    const [reason, setReason] = useState("")
    const [id, setID] = useState("")


    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };
    const classes = useStyles();

    useEffect(() => {
        setLoad(true)

        let url = baseURL + `rejected_loans?` + queryString;
        // alert(url)
        axios.all([
            axios.get(url, CONFIG)
        ]).then(axios.spread((farmerResponse) => {
            let data = [];
            if (farmerResponse.data.data.length > 0) {
                setDataTotal(farmerResponse.data.data.length)
                setLoad(false)
                for (let i = 0; i < farmerResponse.data.data.length; i++) {

                    let fpaid_amount = { fpaid_amount: formatCurrency(parseFloat(farmerResponse.data.data[i].total_amount).toFixed(2)) };

                    
                    let date = { dates: (moment.utc(farmerResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(date, fpaid_amount, farmerResponse.data.data[i]));
                    setLoad(false)
                }
                setDataTotal(farmerResponse.data.total)
                setFarmer(data)
            } else {
                setLoad(false)
                setFarmer(data)
            }
        }))

    }, [queryString]);


    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 20,
        button: {
            csv: true,
            excel: true,
            print: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }



    const columns = [
        {
            key: "name",
            TrOnlyClassName: 'cell',
            text: "Name",
            className: "tsc",
            align: "left"
        },

        {
            key: "msisdn",
            TrOnlyClassName: 'cell',
            text: "Phone NO.",
            className: "tsc",
            align: "left"
        },

        {
            key: "status",
            TrOnlyClassName: 'cell',
            text: "Status",
            className: "tsc",
            align: "left"
        },

        {
            key: "loan_reference",
            TrOnlyClassName: 'cell',
            text: "Loan Reference",
            className: "tsc",
            align: "left"
        },
        {
            key: "fpaid_amount",
            TrOnlyClassName: 'cell',
            text: "Applied Amount",
            className: "tsc",
            align: "left"
        },
        {
            key: "reported_by",
            TrOnlyClassName: 'cell',
            text: "Reported By",
            className: "tsc",
            align: "left"
        },
        {
            key: "description",
            TrOnlyClassName: 'cell',
            text: "Description",
            className: "tsc",
            align: "left"
        },

        {
            key: "narative",
            TrOnlyClassName: 'cell',
            text: "Narrative",
            className: "tsc",
            align: "left"
        },
        {
            key: "dates",
            TrOnlyClassName: 'cell', text: "Date",
            className: "tsc",
            align: "left"
        }
        ,
        {
            key: "action",
            text: "Actions",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <React.Fragment className="center" >
                        {ROLE === '3' && record.status === 'Rejected' ? <button className="btn btn-primary btn-sm"
                            title="Amend this loan"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { openModal(record) }}
                        >
                            Amend
                        </button>
                            : null
                        }
                        {ROLE === '3' && record.status === 'Variete' ?
                            <button className="btn btn-primary btn-sm"
                                title="Confirm this loan"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { if (window.confirm(`Are you sure ?`)) onSubmit(record) }} >
                                Confirm
                            </button>
                            : null
                        }

                    </React.Fragment >
                );
            }
        },
    ];

    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }


    const closeModal = e => {
        // isOpen(false);
    };
    const openModal = e => {
        // isOpen(true)
        setGoAMend(true)
        setReason(e)

        setID(e.id)
    };



    // handle id no change
    const handleChange = event => {
        setReason(event.target.value);
    };


    // submit reconciliation
    const onSubmit = e => {
        setLoad(true);
        let formData =
        {
            "id": e.id,
            "loan_reference": e.loan_reference,
            "description": id,
        }

        axios.post(baseURL + 'updated_rejected_variete', formData, CONFIG).then((response) => {
            setQueryString(require("randomstring").generate({
                length: 1,
                charset: 'alphanumeric',
                capitalization: 'lowercase'
            }))
            successToast("Successful")
            setLoad(false)
            // isOpen(false);



        }).catch(error => {
            setLoad(false)
            errorToast("Error")
            setQueryString(require("randomstring").generate({
                length: 1,
                charset: 'alphanumeric',
                capitalization: 'lowercase'
            }))

        });
    }



    return (
        <div style={{ marginTop: "-20px" }} >
            < >
                < Card >

                    {go_amend ?
                        <UpdateLoan farmerProps={reason} />
                        :
                        <>
                            {ToastTable()}

                            <Modal
                                isOpen={false}
                                onRequestClose={e => {
                                    closeModal(e);
                                }}
                                contentLabel="My dialog"
                                className="mymodal"
                                onAfterOpen={() => {
                                    document.body.style.overflow = 'hidden';
                                }}
                                onAfterClose={() => {
                                    document.body.removeAttribute('style');
                                }}
                                overlayClassName="myoverlay"
                                closeTimeoutMS={500}
                            >
                                <MDBCloseIcon onClick={closeModal} />
                                <h4><b>Reason for amendment</b></h4>
                                <>
                                    <Form className="form login-form" onSubmit={onSubmit}>
                                        {/*n  <h5><b>Get Agent Number</b></h5> */}
                                        <div className="form__form-group">
                                            <br></br>

                                            <span className="form__form-group-label">Reason</span>
                                            <div className="form__form-group-field">
                                                <Form.Control
                                                    autoFocus
                                                    type="text"
                                                    name="reason"
                                                    style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                                                    placeholder="Enter Reason"
                                                    className="input-without-border-radius"
                                                    value={reason}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <br />
                                        </div>
                                        <div className="account__btns col-8 offset-2">
                                            <Button className="account__btn" type='submit' color="success"> {
                                                isLoading ? "Please wait..." : "Proceed"
                                            }</Button>
                                        </div>

                                    </Form>
                                </>
                            </Modal>

                            <CardBody >
                                <Table responsive hover>
                                    <div className="panel-body" >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4> <b>Rejected Loans</b></h4>
                                            </div>
                                            <div className="col-6">
                                                <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}

                                                    onClick={
                                                        () => {
                                                            refreshCanvas()
                                                            setQueryString(require("randomstring").generate({
                                                                length: 1,
                                                                charset: 'alphanumeric',
                                                                capitalization: 'lowercase'
                                                            }))
                                                        }}
                                                >
                                                    Refresh
                                                    <Autorenew
                                                        className={clsx({
                                                            [classes.refresh]: true,
                                                            spin: spin
                                                        })}
                                                        onClick={
                                                            () => {
                                                                refreshCanvas()
                                                                setQueryString(require("randomstring").generate({
                                                                    length: 1,
                                                                    charset: 'alphanumeric',
                                                                    capitalization: 'lowercase'
                                                                }))
                                                            }}
                                                        spin={360}
                                                    />

                                                </button>
                                            </div>
                                        </div>
                                        <br />
                                        <div >
                                            Rejected Loans: <b>{datatotal}</b>
                                            <br /><br />
                                        </div>
                                        <ReactDatatable
                                            config={config}
                                            records={farmer}
                                            columns={columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={isLoading}
                                            total_record={datatotal}
                                            onChange={tableChangeHandler} />
                                    </div>
                                </Table>
                            </CardBody>
                        </>
                    }

                </Card>

            </>
        </div>
    )

}
export default RejectedLoans;