import React, { useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { baseURL, CONFIG, formatCurrency } from '../../configs/exports';
import Loader from "react-loader-spinner";
import "../../_assets/css/csv.css";
import * as moment from 'moment';
import {
    Card, CardBody, Button, TabContent, TabPane,
    Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';





import { Link, useNavigate, useLocation } from 'react-router-dom';
import LoanAuction from './LoanAuctioning';
import ChargesStatement from './ChargesStatement';
import SummaryStatement from './SummaryStatement';
import InstallmentStatement from './InstallmentStatement';
import InstallmentStatementSecond from './InstallmentStatementSecond';
import InstallmentStatementThird from './InstallmentStatementThird';
// import EditApplicant from './EditApplicant';



const Statement = (state) => {


    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setLoad] = useState(true)
    const [queryString, setQueryString] = useState("")
    // eslint-disable-next-line
    const [activeTab, setActiveTab] = useState("1") // tab layout

    const [statement, setStatment] = useState([])

    const [loanhome, setLoanHome] = useState(false)



    // const [avatar, setAvatar] = useState("") // set profile picture


    useEffect(() => {
        // consuming API based on active tab


        let statment_url;
        if (activeTab === "1") {
            statment_url = `${baseURL}statement?filter_value=${location.state.loan_statement_id}&&period=0`;
        }
        // 30 days
        if (activeTab === "31") {
            statment_url = `${baseURL}statement?filter_value=${location.state.loan_statement_id}&&period=1`;
        }
        else if (activeTab === "61") {
            statment_url = `${baseURL}statement?filter_value=${location.state.loan_statement_id}&&period=2`;
            // alert("cjf")
        }
        else if (activeTab === "91") {
            statment_url = `${baseURL}statement?filter_value=${location.state.loan_statement_id}&&period=3`;
        }

        else if (activeTab === "121") {
            statment_url = `${baseURL}statement?filter_value=${location.state.loan_statement_id}&&period=4`;
        }
        else if (activeTab === "151") {
            statment_url = `${baseURL}statement?filter_value=${location.state.loan_statement_id}&&period=5`;
        }
        // setLoad(true)
        // alert(statment_url)
        // tab 1 API call...
        axios.all([
            axios.get(statment_url, CONFIG),
        ]).then(axios.spread((statementResponse) => {
            let data = [];


            if (statementResponse.data.data.length > 0) {
                setLoad(false)

                // let insurance_fee_original = location.state.insurance_fee;

                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))

                let p = 0;
                if (id > 1) {
                    p = (((id - 1) * parseInt(params.get("page_size"))))
                }


                for (let i = 0; i < statementResponse.data.data.length; i++) {

                    // format amount
                    let m_credit = { m_credit: formatCurrency(parseFloat(statementResponse.data.data[i].credit).toFixed(2)) };

                    let m_credit_sum = { m_credit_sum: formatCurrency(parseFloat(statementResponse.data.credit_sum).toFixed(2)) };

                    let s_insurance_fee = {
                        s_insurance_fee: formatCurrency(
                            parseFloat(statementResponse.data.data[i].insurance_fee)
                            + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                            + parseFloat(statementResponse.data.data[i].insurance_instalment_third)
                        )
                    };

                    let minstalmet_balance;
                    let m_insurance_fee;

                    let m_balance_instalment;

                    let m_interest;
                    let m_interest_two;
                    let m_interest_three;
                    let m_rollover;
                    let m_debt_collection;

                    let m_principal_2
                    let m_principal_3;

                    let m_insurance_fee_2;
                    let m_insurance_fee_3;



                    // let minsurance_feee;

                    let minstalmet_principal;
                    // alert("text")
                    let unrow;
                    if (activeTab === "31") {
                        // alert(mloan_amount)
                        p++;
                        unrow = { row: p }

                        minstalmet_balance = { month_installment: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment))) }
                        minstalmet_principal = { month_principal: formatCurrency((parseFloat(statementResponse.data.data[i].debit) / 3)) }
                        m_insurance_fee = { m_insurance_fee: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_fee).toFixed(2)) };

                        m_insurance_fee_2 = { m_insurance_fee_2: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_second).toFixed(2)) };

                        m_insurance_fee_3 = { m_insurance_fee_3: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_third).toFixed(2)) };

                        m_interest = { m_interest: formatCurrency(parseFloat(statementResponse.data.data[i].interest).toFixed(2)) };
                        m_interest_two = { m_interest_two: formatCurrency(parseFloat(statementResponse.data.data[i].interest_second).toFixed(2)) };

                        m_interest_three = { m_interest_three: formatCurrency(parseFloat(statementResponse.data.data[i].interest_third).toFixed(2)) };

                        m_debt_collection = { m_debt_collection: formatCurrency(parseFloat(statementResponse.data.data[i].debt_collection).toFixed(2)) };


                        m_rollover = { m_rollover: formatCurrency(parseFloat(statementResponse.data.data[i].rollover_fee).toFixed(2)) };


                        m_principal_2 = { m_principal_2: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_second))) }

                        m_principal_3 = { m_principal_3: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_third))) }

                        m_balance_instalment = {
                            m_balance_instalment: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                                + parseFloat(statementResponse.data.data[i].interest)
                                + parseFloat(statementResponse.data.data[i].insurance_fee))
                        };


                    }
                    if (activeTab === "61") {
                        p++;
                        unrow = { row: p }

                        minstalmet_balance = { month_installment: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment))) }
                        m_insurance_fee = { m_insurance_fee: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_fee).toFixed(2)) };
                        m_interest = { m_interest: formatCurrency(parseFloat(statementResponse.data.data[i].interest).toFixed(2)) };
                        m_interest_two = { m_interest_two: formatCurrency(parseFloat(statementResponse.data.data[i].interest_second).toFixed(2)) };

                        m_insurance_fee_2 = { m_insurance_fee_2: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_second).toFixed(2)) };

                        m_insurance_fee_3 = { m_insurance_fee_3: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_third).toFixed(2)) };

                        m_interest_three = { m_interest_three: formatCurrency(parseFloat(statementResponse.data.data[i].interest_third).toFixed(2)) };
                        m_debt_collection = { m_debt_collection: formatCurrency(parseFloat(statementResponse.data.data[i].debt_collection).toFixed(2)) };
                        m_rollover = { m_rollover: formatCurrency(parseFloat(statementResponse.data.data[i].rollover_fee).toFixed(2)) };
                        m_principal_2 = { m_principal_2: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_second))) }

                        m_principal_3 = { m_principal_3: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_third))) }


                        m_balance_instalment = {
                            m_balance_instalment: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                                + parseFloat(statementResponse.data.data[i].interest)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_second)
                                + parseFloat(statementResponse.data.data[i].interest_second)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                                + parseFloat(statementResponse.data.data[i].insurance_fee))
                        };

                        // alert("cjf")
                    }
                    if (activeTab === "91") {
                        p++;
                        unrow = { row: p }

                        minstalmet_balance = { month_installment: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment))) }
                        m_insurance_fee = { m_insurance_fee: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_fee).toFixed(2)) };
                        m_interest = { m_interest: formatCurrency(parseFloat(statementResponse.data.data[i].interest).toFixed(2)) };
                        m_interest_two = { m_interest_two: formatCurrency(parseFloat(statementResponse.data.data[i].interest_second).toFixed(2)) };

                        m_insurance_fee_2 = { m_insurance_fee_2: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_second).toFixed(2)) };

                        m_insurance_fee_3 = { m_insurance_fee_3: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_instalment_third).toFixed(2)) };

                        m_interest_three = { m_interest_three: formatCurrency(parseFloat(statementResponse.data.data[i].interest_third).toFixed(2)) };
                        m_debt_collection = { m_debt_collection: formatCurrency(parseFloat(statementResponse.data.data[i].debt_collection).toFixed(2)) };
                        m_rollover = { m_rollover: formatCurrency(parseFloat(statementResponse.data.data[i].rollover_fee).toFixed(2)) };
                        m_principal_2 = { m_principal_2: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_second))) }

                        m_principal_3 = { m_principal_3: formatCurrency((parseFloat(statementResponse.data.data[i].monthly_instalment_third))) }


                        m_balance_instalment = {
                            m_balance_instalment: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_second)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_third)
                                + parseFloat(statementResponse.data.data[i].interest)
                                + parseFloat(statementResponse.data.data[i].interest_second)
                                + parseFloat(statementResponse.data.data[i].interest_third)
                                + parseFloat(statementResponse.data.data[i].insurance_fee)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_third))
                        };
                    }

                    if (activeTab === "121") {
                        p++;
                        unrow = { row: p }

                        minstalmet_balance = {
                            month_installment: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_second)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_third))
                        }//principal
                        m_interest = {
                            m_interest: formatCurrency(parseFloat(statementResponse.data.data[i].interest)
                                + parseFloat(statementResponse.data.data[i].interest_second)
                                + parseFloat(statementResponse.data.data[i].interest_third))
                        }; // interest

                        m_insurance_fee = {
                            m_insurance_fee: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_fee)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_third))
                        }; // insurance fee

                        m_rollover = {
                            m_rollover: formatCurrency(parseFloat(statementResponse.data.data[i].rollover_fee))
                        }; // rollover fee

                        m_debt_collection = {
                            m_debt_collection: formatCurrency(parseFloat(statementResponse.data.data[i].debt_collection))
                        }; // rollover fee


                        m_balance_instalment = {
                            m_balance_instalment: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_second)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_third)
                                + parseFloat(statementResponse.data.data[i].interest)
                                + parseFloat(statementResponse.data.data[i].interest_second)
                                + parseFloat(statementResponse.data.data[i].interest_third)
                                + parseFloat(statementResponse.data.data[i].insurance_fee)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_third)
                                + (parseFloat(statementResponse.data.data[i].rollover_fee)))
                        };


                    }
                    if (activeTab === "151") {
                        p++;
                        unrow = { row: p }

                        minstalmet_balance = {
                            month_installment: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_second)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_third))
                        }//principal
                        m_interest = {
                            m_interest: formatCurrency(parseFloat(statementResponse.data.data[i].interest)
                                + parseFloat(statementResponse.data.data[i].interest_second)
                                + parseFloat(statementResponse.data.data[i].interest_third))
                        }; // interest

                        m_insurance_fee = {
                            m_insurance_fee: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_fee)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_third))
                        }; // insurance fee

                        m_rollover = {
                            m_rollover: formatCurrency(parseFloat(statementResponse.data.data[i].rollover_fee))
                        }; // rollover fee

                        m_debt_collection = {
                            m_debt_collection: formatCurrency(parseFloat(statementResponse.data.data[i].debt_collection))
                        }; // rollover fee

                        m_balance_instalment = {
                            m_balance_instalment: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_second)
                                + parseFloat(statementResponse.data.data[i].monthly_instalment_third)
                                + parseFloat(statementResponse.data.data[i].interest)
                                + parseFloat(statementResponse.data.data[i].interest_second)
                                + parseFloat(statementResponse.data.data[i].interest_third)
                                + parseFloat(statementResponse.data.data[i].insurance_fee)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_third)
                                + (parseFloat(statementResponse.data.data[i].rollover_fee))
                                + parseFloat(statementResponse.data.data[i].debt_collection))
                        };

                    }
                    if (activeTab === '1') {
                        p++;
                        unrow = { row: p }

                        m_interest = {
                            m_interest: formatCurrency(parseFloat(statementResponse.data.data[i].interest)
                                + parseFloat(statementResponse.data.data[i].interest_second)
                                + parseFloat(statementResponse.data.data[i].interest_third))
                        }; // interest

                        m_rollover = {
                            m_rollover: formatCurrency(parseFloat(statementResponse.data.data[i].rollover_fee))
                        }; // rollover fee

                        m_debt_collection = {
                            m_debt_collection: formatCurrency(parseFloat(statementResponse.data.data[i].debt_collection))
                        }; // rollover fee

                        m_insurance_fee = {
                            m_insurance_fee: formatCurrency(parseFloat(statementResponse.data.data[i].insurance_fee)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                                + parseFloat(statementResponse.data.data[i].insurance_instalment_third))
                        }; // insurance fee


                    }

                    // alert(JSON.stringify(statementResponse.data.data))
                    let m_debit = { m_debit: formatCurrency((parseFloat(statementResponse.data.data[i].debit))) };

                    let m_balance = {
                        m_balance: formatCurrency(parseFloat(statementResponse.data.data[i].monthly_instalment)
                            + parseFloat(statementResponse.data.data[i].monthly_instalment_second)
                            + parseFloat(statementResponse.data.data[i].monthly_instalment_third)
                            + parseFloat(statementResponse.data.data[i].interest)
                            + parseFloat(statementResponse.data.data[i].interest_second)
                            + parseFloat(statementResponse.data.data[i].interest_third)
                            + parseFloat(statementResponse.data.data[i].insurance_fee)
                            + parseFloat(statementResponse.data.data[i].insurance_instalment_second)
                            + parseFloat(statementResponse.data.data[i].insurance_instalment_third)
                            + (parseFloat(statementResponse.data.data[i].rollover_fee)
                                + parseFloat(statementResponse.data.data[i].debt_collection)))
                    };

                    // alert("url")
                    let date = { dates: (moment(statementResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(date, unrow, m_credit_sum, m_insurance_fee_3, m_insurance_fee_2, m_interest_two, m_principal_2, m_principal_3, m_interest_three, m_debt_collection, m_rollover, s_insurance_fee, m_balance_instalment, m_insurance_fee, minstalmet_balance, minstalmet_principal, m_credit, m_debit, m_balance, m_interest, statementResponse.data.data[i]));

                    // data_rollover.push(Object.assign(minstalmet_balance, m_debt_collection, m_credit_sum, m_credit, m_debit, m_balance, m_balance_instalment, m_rollover, m_interest, m_insurance_fee, date, statementResponse.data.data[i]));

                    setLoad(false)
                }
                setStatment(data)
            } else {
                setLoad(false)
                setStatment(data)
            }
        })).catch(error => {
            // alert(JSON.stringify(error.response))
        })
    }, [activeTab, queryString]);// eslint-disable-line react-hooks/exhaustive-deps

    const config = {
        key_column: "tsc",
        length_menu: [20, 50, 100, 200, 500],
        show_filter: false,
        show_pagination: true,
        pagination: 'advance',
        page_size: 20,
        button: {
            csv: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Filter here..",
            no_data_text: "There was no statement",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }

    // go home
    const loanHome = (record) => {
        // alert(JSON.stringify(record))
        setLoanHome(true)

    }

    //sms columns list



    const columns_rollover = [
        {
            key: "m_credit",
            TrOnlyClassName: 'cell',
            text: "Credit",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_balance",
            TrOnlyClassName: 'cell',
            text: "Debit",
            className: "tsc",
            align: "left"
        },


        {
            key: "month_installment",
            TrOnlyClassName: 'cell',
            text: "Principal Balance",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_interest",
            TrOnlyClassName: 'cell',
            text: "Interest Balance",
            className: "tsc",
            align: "left"
        },
        {
            key: "m_insurance_fee",
            TrOnlyClassName: 'cell',
            text: "Insurance Balance",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_rollover",
            TrOnlyClassName: 'cell',
            text: "Rollover Fee",
            className: "tsc",
            align: "left"
        },

        {
            key: "m_debt_collection",
            TrOnlyClassName: 'cell',
            text: "Debt Collectioon",
            className: "tsc",
            align: "left"
        },


        {
            key: "m_balance",
            TrOnlyClassName: 'cell',
            text: "T. Loan Balance",
            className: "tsc",
            align: "left"
        },



        {
            key: "narrative",
            TrOnlyClassName: 'cell',
            text: "Narrative",
            className: "cell",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'cell',
            text: "Date Created",
            className: "cell",
            align: "left"
        }
    ];





    // handles paginations
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column")
                    + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }


    const toggle = (activeTab) => {

        setActiveTab(activeTab)

    }


    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >

            {loanhome === true ?

                navigate("/loan_status", {
                    state: {
                        loan_reference: location.state.loan_statement_reference,
                    }
                })
                : null}

            <Card>
                <CardBody >
                    <br />
                    <div className="tabs tabs--bordered-top" >
                        <div className="row">
                            <div className="col-6">
                                Statement: Loan Ref# <b>{location.state.loan_statement_reference}</b>
                            </div>
                            <div className="col-6">
                                <Link to="/livestock">
                                    <Button className="pull-right btn-sm" outline color="primary">
                                        Swtich back
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div className="tabs__wrap">

                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => {
                                            toggle('1');
                                        }}
                                    >
                                        Summary
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '4' })}
                                        onClick={() => {
                                            toggle('4');
                                        }}
                                    >
                                        Application Fee
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '31' })}
                                        onClick={() => {
                                            toggle('31');
                                        }}
                                    >
                                        1st Month
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '61' })}
                                        onClick={() => {
                                            toggle('61');
                                        }}
                                    >
                                        2nd Month
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '91' })}
                                        onClick={() => {
                                            toggle('91');
                                        }}
                                    >
                                        3rd Month
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '121' })}
                                        onClick={() => {
                                            toggle('121');
                                        }}
                                    >
                                        1st Defaulted Month
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '151' })}
                                        onClick={() => {
                                            toggle('151');
                                        }}
                                    >
                                        2nd Defaulted Month
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => {
                                            toggle('3');
                                        }}
                                    >
                                        Auction
                                    </NavLink>
                                </NavItem>

                            </Nav>

                            {isLoading === false ?

                                <>




                                    <div >
                                        <br />
                                    </div>

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">


                                        </TabPane>
                                    </TabContent>

                                    {activeTab === '1' ?
                                        <SummaryStatement loan_ref={location.state.loan_statement_id} />
                                        :
                                        activeTab === '3' ?

                                            <>
                                                <Button className="pull-right btn-sm" onClick={loanHome} outline color="primary">
                                                    Add Auction
                                                </Button>

                                                <LoanAuction style={{ marginTop: '-600px' }} loan_ref={location.state.loan_statement_reference} />
                                            </> 
                                            :
                                            activeTab === '4' ?

                                                <ChargesStatement style={{ marginTop: '-600px' }} loan_ref={location.state.loan_statement_reference} />

                                                :
                                                activeTab === '121' || activeTab === '151' ?

                                                    <ReactDatatable
                                                        config={config}
                                                        records={statement}
                                                        columns={columns_rollover}
                                                        id="tsc"
                                                        loading={isLoading}
                                                        onChange={tableChangeHandler} />
                                                    :
                                                    activeTab === '31' ?
                                                        <InstallmentStatement loan_ref={
                                                            {
                                                                id: location.state.loan_statement_id,
                                                                activeTab: activeTab
                                                            }} />
                                                        : activeTab === '61' ?
                                                            <InstallmentStatementSecond loan_ref={
                                                                {
                                                                    id: location.state.loan_statement_id,
                                                                    activeTab: activeTab
                                                                }} />
                                                            : activeTab === '91' ?
                                                                <InstallmentStatementThird loan_ref={
                                                                    {
                                                                        id: location.state.loan_statement_id,
                                                                        activeTab: activeTab
                                                                    }} />
                                                                : null
                                    }

                                </>

                                :
                                <div className="text-center h-50">
                                    <Loader
                                        type="Puff"
                                        color="#00BFFF"
                                        height={50}
                                        width={50} //3 secs
                                    />
                                </div>
                            }


                        </div>
                    </div>
                </CardBody>

            </Card>


        </div >
    );
}

export default Statement;
