import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import NotFound404 from '../../DefaultPage/404/index';
import LogIn from '../../Account/LogIn/index';
import WrappedRoutes from './WrappedRoutes';

const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/404" element={<NotFound404 />} />
        <Route exact path="/" element={<LogIn />} />
        <Route path="/*" element={<WrappedRoutes />} />

      </Routes>
    </main>
  </MainWrapper>
);
export default Router;

