import React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Button } from 'reactstrap';
import { baseURL, formatCurrency, MOBIPESA_NAME, NAME } from '../../configs/exports';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from '../../shared/components/Alert';
// import * as moment from 'moment';
import Modal from 'react-modal';
import { Form } from 'react-bootstrap';
import { MDBCloseIcon } from "mdbreact";
import CardIcon from 'mdi-react/CardIcon';
import './style12.css'
import Loader from "react-loader-spinner";


export class AddMoreCows extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    // constructor
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.otpgo = this.otpgo.bind(this);

        this.state = {
            applicant_details: this.props.farmerProps,
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            total_amount: 0,
            applicant_fee: 0,
            isOpen: false,
            loan_applicable: 0,
            showError: false,
        }

        // if (this.props.location.state !== undefined) {
        //     localStorage.setItem('props', JSON.stringify(this.props.location.state));



        // }

        // alert(JSON.stringify(this.props))
    }




    async componentDidMount() {
        // window.location.reload(false);
        // this.setState({applicant_tag_id: JSON.parse(localStorage.getItem("props"))})

        let details = this.props.farmerProps;
        this.setState({
            isLoading: false,
            loan_applicable: details.loan_applicable,
            applicant_fee: details.applicant_fee,
            cow_value: details.cow_value,
            mloan_limit_cow: details.mloan_limit_cow,
            applicant_msisdn: details.applicant_msisdn,
            applicant_id: details.applicant_id,
            livestock_id: details.livestock_id,
            livestock_id_ids: details.livestocksIDs,
            insurance: details.insurance,
            livestockResponse: details,
        })

        // alert(JSON.stringify({
        //     isLoading: false,
        //     loan_applicable: details.loan_applicable,
        //     applicant_fee: details.applicant_fee,
        //     cow_value: details.cow_value,
        //     mloan_limit_cow: details.mloan_limit_cow,
        //     applicant_msisdn: details.applicant_msisdn,
        //     applicant_id: details.applicant_id,
        //     livestock_id: details.livestock_id,
        //     livestock_id_ids: details.livestocksIDs,
        //     insurance: details.insurance,
        //     livestockResponse: details,
        // }))
    }


    // dialogue
    closeModal1 = e => {
        this.setState({
            isOpen: false
        });
    };
    otpgo = e => {
        e.preventDefault()

        // // alert("fjhfh")
        // alert(this.props.location.state.applicant_tag_id)
        this.setState({
            isOpen: true
        });

    };






    OTPRegenerate = e => {
        e.preventDefault();
        this.setState({ sent: true });
        if (parseFloat(this.state.loan_amount_set) > (parseFloat(this.state.total_amount) + (parseFloat(this.state.applicant_fee) + 33))) {

            this.setState({ sent: false });
            alert("Please enter lower amount")
        } else {
            this.setState({ sent: false });
            this.setState({
                total_amount: parseFloat(this.state.loan_amount_set) - (parseFloat(this.state.applicant_fee) + 33)
            })
            this.onSubmit(e)


        }
    };

    onSubmit(e) {
        e.preventDefault();



        this.setState({ loading: true, isOpen: false });


        var data = new FormData();
        data.append("applicantion_form", this.state.imagefile1);
        data.append("kra_certificate", this.state.krafile);
        data.append("statements", this.state.mpesafile);
        data.append("oustanding_loan", this.state.outstanding_amount);
        data.append("loan_counts", this.state.count_loans);
        
        data.append("livestock_id_ids", this.state.livestock_id_ids);

        
        data.append("defaulted_loan", this.state.defaulted_loans);

        data.append("laon_repayment", this.state.total_repayment);
        data.append("gross_income", this.state.gross_income);
        data.append("total_amount", parseFloat(this.state.loan_amount_set) - (parseFloat(this.state.applicant_fee) + 33));  // total amount
        data.append("farmer_id", this.state.applicant_id);  // farmer id
        data.append("msisdn", this.state.applicant_msisdn); // farmer msisdn 
        data.append("applicant_fee", this.state.applicant_fee); // application fee
        data.append("cow_value", this.state.cow_value);  // cow value
        data.append("livestock_id", this.state.livestock_id); // livestock id
        data.append("loan_purpose", this.state.purpose_of_loan); // loan purpose
        // guarantors
        data.append("guarantor_id_photo", this.state.g_passport);
        data.append("guarantor_id_front_page", this.state.g_idfile);
        data.append("guarantor_id_photo_2", this.state.g_passport_2);
        data.append("guarantor_id_front_page_2", this.state.g_idfile_2);
        data.append("guarantor_id_photo_3", this.state.g_passport_3);
        data.append("guarantor_id_front_page_3", this.state.g_idfile_3);

        data.append("insurance", this.state.insurance);

        //witness
        data.append("witness_id_photo", this.state.w_passport_file);
        data.append("witness_id_front_page", this.state.w_national_id_page);
        data.append("guarantor_name", this.state.first_guarantor_name);
        data.append("guarantor_id_no", this.state.national_id);
        data.append("guarantor_msisdn", this.state.msisdn);
        data.append("guarantor_name_2", this.state.second_guarantor_name);
        data.append("guarantor_id_no_2", this.state.second_national_id);
        data.append("guarantor_msisdn_2", this.state.second_msisdn);
        data.append("guarantor_name_3", this.state.third_guarantor_name);
        data.append("guarantor_msisdn_3", this.state.rd_msisdn);
        data.append("guarantor_id_no_3", this.state.third_guarantor_id);
        data.append("witness_name", this.state.witness_name);
        data.append("witness_id_no", this.state.witness_national_id);
        data.append("created_by", MOBIPESA_NAME);
        data.append("farmer_name", this.props.farmerProps.farmer_name.toString());
        data.append("applicant_fee", this.state.applicant_fee);
        data.append("total_amount", parseFloat(this.state.loan_amount_set) - (parseFloat(this.state.applicant_fee) + 33));  // total amount
        data.append("msisdn", this.state.applicant_msisdn);
        data.append("livestock_id", this.state.livestock_id);

        data.append("status_paid", this.props.farmerProps.status_paid);

        data.append("mobipesa_name", MOBIPESA_NAME);
        data.append("mobipesa_designation", NAME);
        // data.append("guarantor_msisdn", this.state.g_passport_3);

        this.setState({ isLoading: true });

        // alert(baseURL + 'loan')
        axios.post(baseURL + 'loan_multi', data, {
            headers: {
                'Content-Type': 'multipart/form-data;',
                'Access-Control-Allow-Origin': '*',
                'Authorization': localStorage.getItem('token')
            },
        }).then((response) => {

            let loan_reference = response.data.loan_reference;
            data.append("loan_reference", loan_reference);
            axios.post(baseURL + 'guarantor', data, {
                headers: {
                    'Content-Type': 'multipart/form-data;',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': localStorage.getItem('token')
                },
            }).then((response) => {
                axios.post(baseURL + 'witness', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data;',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': localStorage.getItem('token')
                    },
                }).then((response) => {
                    if (response.data.status) {
                        this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });

                        window.setTimeout(() => {
                            window.location.reload(false)
                        }, 2000);

                    } else {
                        this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                        window.setTimeout(() => {
                            this.setState({ showError: false })
                        }, 2000);
                    }
                }).catch((error) => {
                    console.log('bayoo', error)
                    this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
                })
            }).catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })
        }).catch((error) => {
            console.log('bayoo', error)
            this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
        })
    }



    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }



    handleChangeGrossIncome = (e) => {


        let mloan_limit = 0.30 * parseFloat(e.target.value)

        let mtotal_amount;

        mtotal_amount = parseFloat(this.state.loan_applicable) - (parseFloat(this.state.applicant_fee) + 33)
        console.log('mloan_limit', parseFloat(this.state.loan_applicable))


        if (mloan_limit < parseFloat(this.state.loan_applicable)) {

            mtotal_amount = mloan_limit - (parseFloat(this.state.applicant_fee) + 33)
        }

        this.setState({
            gross_income: e.target.value,
            total_amount: mtotal_amount
        });

        // alert(JSON.stringify(mtotal_amount))
    }

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true

        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }


    // borrower
    handleProfile1 = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            imagefile1: event.target.files[0]
        });
    };
    handleMpesa = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            mpesafile: event.target.files[0]
        });
    };

    handleKRA = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            krafile: event.target.files[0]
        });
    };
    handlePassport = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            passport: event.target.files[0]
        });
    };

    handleNationalID = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            borrowerid: event.target.files[0]
        });
    };

    handleNexttoCow = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            next_cow: event.target.files[0]
        });
    };

    // guarantor

    //1st guarantor

    handlePassport_1st = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            g_passport: event.target.files[0]
        });
    };

    handlePage_id = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            g_idfile: event.target.files[0]
        });
    };

    handlePassport_2nd = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            g_passport_2: event.target.files[0]
        });
    };


    handlePage_2ndid = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            g_idfile_2: event.target.files[0]
        });
    };

    handlePassport_3rd = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            g_passport_3: event.target.files[0]
        });
    };
    handlePage_3rdid = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            g_idfile_3: event.target.files[0]
        });
    };


    // witness

    handlePassport_Witness = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            w_passport_file: event.target.files[0]
        });
    };
    handlePage_WItness = event => {
        console.log("FETCHER", event.target.files);
        this.setState({
            w_national_id_page: event.target.files[0]
        });
    };
    render() {
        // const { showComponent } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >


                <Card>
                    <Modal
                        isOpen={this.state.isOpen}
                        onRequestClose={e => {
                            this.closeModal1(e);
                        }}
                        contentLabel="My dialog"
                        className="mymodal2"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}>
                        <MDBCloseIcon onClick={this.closeModal1} />
                        <h4><b>Confirm Loan Amount</b></h4>
                        <br></br>
                        <>
                            <Form className="form login-form" onSubmit={this.OTPRegenerate}>
                                <h5>You are qualified to get a loan amount of <b> {formatCurrency(parseFloat(this.state.total_amount) + (parseFloat(this.state.applicant_fee) + 33))}</b></h5>
                                <div className="form__form-group">
                                    <br></br>
                                    {this.state.isShowError ? (
                                        <Alert color="success" className="alert--colored" >
                                            <span>
                                                {this.state.statusMessage}
                                            </span>
                                        </Alert>
                                    ) : null}
                                    {this.state.errorShow && (
                                        <div>
                                            <div
                                                color="red"
                                                style={{ fontSize: "13px", color: "red" }}>
                                                {this.state.error}
                                            </div>
                                        </div>
                                    )}
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <CardIcon />
                                        </div>
                                        <Form.Control
                                            autoFocus
                                            type="number"
                                            required
                                            name="loan_amount_set"
                                            maxLength="6"
                                            placeholder="Enter Preferred Amount "
                                            className="input-without-border-radius"
                                            value={this.state.loan_amount_set}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <br />
                                </div>

                                <div className="account__btns col-6 offset-3">
                                    <Button className="account__btn" type='submit' color="primary"> {
                                        this.state.isLoading ? "Please wait..." : "Submit"
                                    }</Button>
                                </div>
                                <div className="account__have-account">
                                    {
                                        this.state.sent === true ?
                                            <p>Sending....</p>
                                            :
                                            <p style={{ color: 'red' }}><b>Note: Amount should not exceed loan limit</b></p>
                                    }
                                </div>
                                {/* <div className="account__have-account">
                        <p>Back to Login? <a href="#" onClick={this.changetologin}>Login</a></p>
                      </div> */}
                            </Form>
                        </>

                    </Modal>

                    <CardBody>
                        <div className="row">
                            <div className="col-md-6">
                                <h4><b>Add Loan</b> (More cows)</h4>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>

                        {this.state.showError ? <div >

                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>


                        </div> : null}<br></br>

                        {this.state.isShowError ? (
                            <div>

                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>

                            </div>

                        ) : null}<br></br>
                        <form className="form" onSubmit={this.otpgo}>
                            <br /><br />

                            {this.state.isLoading ?
                                <>
                                    <div className="text-center h-50 col-6 offset-3">
                                        <h4><b>Submitting</b></h4>
                                        <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50} //3 secs
                                        />
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-md-10 offset-1">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Application Form (pdf)</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input
                                                    // className="csv-input"
                                                    type="file"
                                                    ref={input => {
                                                        this.filesInput = input;
                                                    }}
                                                    name="file"
                                                    required
                                                    customHeight
                                                    placeholder={null}
                                                    onChange={this.handleProfile1}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-10 offset-1">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">KRA Certificate Copy (pdf)</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input
                                                    // className="csv-input"
                                                    type="file"
                                                    ref={input => {
                                                        this.filesInput = input;
                                                    }}
                                                    name="file"
                                                    required
                                                    customHeight
                                                    placeholder={null}
                                                    onChange={this.handleKRA}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-10 offset-1">
                                        <br />
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">3 months MPESA/Bank/Payslip statement (pdf)</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input
                                                    // className="csv-input"
                                                    type="file"
                                                    ref={input => {
                                                        this.filesInput = input;
                                                    }}
                                                    name="file"
                                                    required
                                                    customHeight
                                                    placeholder={null}
                                                    onChange={this.handleMpesa}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-10 offset-1">


                                        <div className="form-group">
                                            <br />
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Purpose of Taking Loan</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="purpose_of_loan"
                                                        required placeholder="Purpose of taking loan"
                                                        value={this.state.purpose_of_loan}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Outstanding Loan Amount (2 years)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="number" className="form-control" name="outstanding_amount"
                                                        required placeholder="Outstanding Loan Amount (2 years)"
                                                        value={this.state.outstanding_amount}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Count of Loans (Paased 2 years)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="number" className="form-control" name="count_loans"
                                                        required placeholder="Count of Loans (Paased 2 years)"
                                                        value={this.state.count_loans}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Total Defaulted Loans</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="number" className="form-control" name="defaulted_loans"
                                                        required placeholder="Total Defaulted Loans (Paased 2 years)"
                                                        value={this.state.defaulted_loans}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Total Loans Repayments</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="number" className="form-control" name="total_repayment"
                                                        required placeholder="Total Loans Repayments (Paased 2 years)"
                                                        value={this.state.total_repayment}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Total Gross Income (Last 3 months)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="number" className="form-control" name="gross_income"
                                                        required placeholder="Total Gross income  for Last 3 months"
                                                        value={this.state.gross_income}
                                                        onChange={this.handleChangeGrossIncome} />
                                                </div>
                                                <br />
                                                <h5>You are qualified to get a loan amount of <b> {formatCurrency(parseFloat(this.state.total_amount) + (parseFloat(this.state.applicant_fee) + 33))}</b></h5>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-10 offset-1">

                                        <br />
                                        <h3><b>Guarantor Details</b></h3>

                                        <div className="col-md-10 offset-1">
                                            <br />
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">1st Guarantor's Passport Photo (jpg/png)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input
                                                        // className="csv-input"
                                                        type="file"
                                                        ref={input => {
                                                            this.filesInput = input;
                                                        }}
                                                        name="file"
                                                        required
                                                        customHeight
                                                        placeholder={null}
                                                        onChange={this.handlePassport_1st}
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-10 offset-1">
                                            <br />
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">1st Guarantor ID Pages (jpg/png)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input
                                                        // className="csv-input"
                                                        type="file"
                                                        ref={input => {
                                                            this.filesInput = input;
                                                        }}
                                                        name="file"
                                                        required
                                                        customHeight
                                                        placeholder={null}
                                                        onChange={this.handlePage_id}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <br />
                                    </div>


                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">1st Guarantor Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="first_guarantor_name"
                                                        required placeholder="1st Guarantor Name"
                                                        value={this.state.first_guarantor_name}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">National ID</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="number" className="form-control" name="national_id"
                                                        required placeholder="National ID"
                                                        value={this.state.national_id}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">1st Guarantor Phone No.</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="msisdn"
                                                        required placeholder="Enter Phone (2547XXXXXXX)"
                                                        value={this.state.msisdn}
                                                        maxLength={12}
                                                        minLength={12}

                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                    <div className="col-md-10 offset-1">

                                        <div className="col-md-10 offset-1">
                                            <br />
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">2nd Guarantor's Passport Photo (jpg/png)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input
                                                        // className="csv-input"
                                                        type="file"
                                                        ref={input => {
                                                            this.filesInput = input;
                                                        }}
                                                        name="file"
                                                        required
                                                        customHeight
                                                        placeholder={null}
                                                        onChange={this.handlePassport_2nd}
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-10 offset-1">
                                            <br />
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">2nd Guarantor ID Pages (jpg/png)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input
                                                        // className="csv-input"
                                                        type="file"
                                                        ref={input => {
                                                            this.filesInput = input;
                                                        }}
                                                        name="file"
                                                        required
                                                        customHeight
                                                        placeholder={null}
                                                        onChange={this.handlePage_2ndid}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>





                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <br />
                                                <div className="col-md-2">
                                                    <label className="form-label">2nd Guarantor Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="second_guarantor_name"
                                                        required placeholder="2nd Guarantor Name"
                                                        value={this.state.second_guarantor_name}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">2nd Guarantor National ID</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="number" className="form-control" name="second_national_id"
                                                        required placeholder="2nd Guarantor National ID"
                                                        value={this.state.second_national_id}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">2nd Gurantor Phone No.</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="second_msisdn"
                                                        required placeholder="Enter Guarantor 2nd Phone (2547xxxxxx)"
                                                        value={this.state.second_msisdn}
                                                        maxLength={12}
                                                        minLength={12}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-10 offset-1">
                                        <br />
                                        <div className="col-md-10 offset-1">

                                            <div className="col-md-10 offset-1">
                                                <br />
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">3rd Guarantor's Passport Photo (jpg/png)</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input
                                                            // className="csv-input"
                                                            type="file"
                                                            ref={input => {
                                                                this.filesInput = input;
                                                            }}
                                                            name="file"
                                                            required
                                                            customHeight
                                                            placeholder={null}
                                                            onChange={this.handlePassport_3rd}
                                                        />
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-10 offset-1">
                                                <br />
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">3rd Guarantor ID Pages (jpg/png)</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input
                                                            // className="csv-input"
                                                            type="file"
                                                            ref={input => {
                                                                this.filesInput = input;
                                                            }}
                                                            name="file"
                                                            required
                                                            customHeight
                                                            placeholder={null}
                                                            onChange={this.handlePage_3rdid}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>

                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">3rd Guarantor Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="third_guarantor_name"
                                                        required placeholder="3rd Guarantor Name"
                                                        value={this.state.third_guarantor_name}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">3rd Guarantor National ID</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="number" className="form-control" name="third_guarantor_id"
                                                        required placeholder="3rd Guarantor National ID"
                                                        value={this.state.third_guarantor_id}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">3rd Guarantor Phone No.</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="rd_msisdn"
                                                        required placeholder="Enter Phone Guarantor (2547XXXXXX)"
                                                        value={this.state.rd_msisdn}
                                                        maxLength={12}
                                                        minLength={12}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>








                                    <div className="col-md-10 offset-1">
                                        <br />
                                        <br />
                                        <h3><b>Witness Details</b></h3>

                                        <div className="col-md-10 offset-1">

                                            <div className="col-md-10 offset-1">
                                                <br />
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">WItness's Passport Photo (jpg/png)</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input
                                                            // className="csv-input"
                                                            type="file"
                                                            ref={input => {
                                                                this.filesInput = input;
                                                            }}
                                                            name="file"
                                                            required
                                                            customHeight
                                                            placeholder={null}
                                                            onChange={this.handlePassport_Witness}
                                                        />
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-10 offset-1">
                                                <br />
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Witness ID Pages (jpg/png)</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input
                                                            // className="csv-input"
                                                            type="file"
                                                            ref={input => {
                                                                this.filesInput = input;
                                                            }}
                                                            name="file"
                                                            required
                                                            customHeight
                                                            placeholder={null}
                                                            onChange={this.handlePage_WItness}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>





                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Witness Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="witness_name"
                                                        required placeholder="Witness Name"
                                                        value={this.state.witness_name}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-10 offset-1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Witness National ID</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="number" className="form-control" name="witness_national_id"
                                                        required placeholder="Witness National ID"
                                                        value={this.state.witness_national_id}
                                                        maxLength={12}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }


                            <div className="col-md-10 offset-1">
                                <br />
                                <br />
                                <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                    {this.state.isLoading ? "Please Wait..." : "Submit"}
                                </Button> &nbsp;&nbsp;&nbsp;
                            </div>


                        </form>
                    </CardBody>
                </Card>
            </div >
        )
    }
}
// export default withTranslation('common')(AddCustomer);
export default withTranslation('common')(AddMoreCows);
